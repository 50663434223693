import { Component, OnInit } from '@angular/core';
import { BaseFormMobileComponent } from '../../../base-components';
import { PasswordIconEnum } from '../form-input-password.utils';

@Component({
  selector: 'ui-mobile-form-input-password',
  templateUrl: './form-input-password.component.html',
  styleUrls: ['./form-input-password.component.scss'],
})
export class FormInputPasswordComponent extends BaseFormMobileComponent<string> implements OnInit {
  public icon: PasswordIconEnum = PasswordIconEnum.Hide;
  public type: 'password' | 'text' = 'password';

  public togglePassword() {
    this.type = this.type === 'password' ? 'text' : 'password';
    this.icon = this.type === 'password' ? PasswordIconEnum.Show : PasswordIconEnum.Hide;
  }
}
