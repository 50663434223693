import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../pipes-module';
import { ExtendedTooltipDirective } from './attach.directive';
import { DecimalFormatterDirective } from './decimal-formatter.directive';
import { DinamicallyComponentsDirective } from './dinamically-components.directive';
import { MinMaxRestrictionDirective } from './min-max-restriction.directive';
import { ResponsiveTableDirective } from './responsive-table.directive';

const DIRECTIVES = [DecimalFormatterDirective, ExtendedTooltipDirective, ResponsiveTableDirective, DinamicallyComponentsDirective, MinMaxRestrictionDirective];
@NgModule({
  declarations: DIRECTIVES,
  imports: [CommonModule, PipesModule],
  exports: DIRECTIVES,
})
export class DirectivesModule {}
