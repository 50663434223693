import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseeReportCustomizeFileModel } from '@foxeet/domain';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class LicenseeReportCustomizeService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'LicenseeReportCustomize', cache);
  }

  public getFiles(): Observable<LicenseeReportCustomizeFileModel[]> {
    return this.get(`Files`);
  }

  public deleteFile(fileType: string) {
    return this.http.delete(`${this.apiBranch}/Files/${fileType}`, { observe: 'response' });
  }

  public uploadFile(file: Blob, fileType: string) {
    const formData = new FormData();
    formData.append('ImageFile', file);
    formData.append('FileType', fileType);
    return this.postMultipartFile(`Files`, formData);
  }
}
