import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIBaseService } from '@foxeet/utils/services/API/api-base.service';
import { XPlatform } from '@foxeet/domain';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';

@Injectable({ providedIn: 'root' })
export class VersionInterceptor implements HttpInterceptor {
  constructor(private _apiBaseService: APIBaseService, private _dynamicConfigLoaderService: DynamicConfigLoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this._addVersion(request));
  }

  private _addVersion(request: HttpRequest<any>): HttpRequest<any> {
    const { environment } = this._dynamicConfigLoaderService.Options;
    return this._apiBaseService
      ? request.clone({ headers: request.headers.set(this._apiBaseService.config.platform === XPlatform.MOBILE ? 'x-mobileVersion' : 'x-webVersion', `${environment.version}`) })
      : request;
  }
}
