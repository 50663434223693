import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanContainerComponent } from './plan-container.component';
import { PlanBadgeDirective } from '../directives/plan-badge.directive';
import { AuthModule } from '@foxeet/feature/auth';

@NgModule({
  declarations: [PlanContainerComponent, PlanBadgeDirective],
  imports: [CommonModule, AuthModule],
  exports: [PlanContainerComponent, PlanBadgeDirective],
})
export class PlanContainerModule {}
