import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlanContainerComponent } from '../web/plan-container.component';

/**
 * Para poder acceder al componente al que se le aplica la directiva y poder por ejemplo acceder a control.disabled()
 * es necesario crear un token de injección como muestra el ejemplo, esto habría que aplicarlo a todos los inputs
 * https://stackblitz.com/edit/angular-ivy-nvseae?file=src%2Fapp%2Fc1%2Fc1.component.ts
 */
@Directive({ selector: '[uiPlanBadge]' })
export class PlanBadgeDirective implements OnInit {
  private wrapperContainer!: ComponentRef<PlanContainerComponent>;

  @Input() uiPlanBadge!: { authorityRoot: string; label: string };

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    if (this.uiPlanBadge.authorityRoot) {
      const containerFactory = this.componentFactoryResolver.resolveComponentFactory<PlanContainerComponent>(PlanContainerComponent);
      this.wrapperContainer = this.viewContainerRef.createComponent(containerFactory);
      this.wrapperContainer.instance.template = this.templateRef;
      this.wrapperContainer.instance.authorityRoute = this.uiPlanBadge.authorityRoot;
      this.wrapperContainer.instance.label = this.uiPlanBadge.label;
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
