<ion-content>
  <ion-grid>
    <ion-row class="form-wrapper ion-justify-content-center">
      <ion-col size="12" class="logo-wrapper">
        <ion-img class="logo" src="/assets/img/logo.png"></ion-img>
      </ion-col>

      <ion-col size-xs="12" size-sm="8" size-md="6" size-lg="5">
        <ion-card>
          <ion-card-content>
            <ion-row>
              <ion-col size="12">
                <p class="small">{{ 'CHANGE_TEMPORAL_PASSWORD_recoverMessage' | translate }}</p>
              </ion-col>
            </ion-row>

            <form [formGroup]="form" class="form">
              <ion-row>
                <ion-col size="12">
                  <ui-mobile-form-input-password [label]="'LOGIN_password' | translate" [form]="form" [controlName]="'password'"></ui-mobile-form-input-password>
                </ion-col>

                <ion-col size="12">
                  <ui-mobile-form-input-password [label]="'LOGIN_confirmPassword' | translate" [form]="form" [controlName]="'confirmPassword'"></ui-mobile-form-input-password>
                </ion-col>
                <ion-col size="12" *ngIf="form.invalid">
                  <p>{{ 'FORM_VALIDATION_NOT_SAME' | translate | capitalize }}</p>
                </ion-col>
              </ion-row>

              <ion-row class="ion-justify-content-center">
                <ion-col size-xs="8" size-sm="6" size-md="5" size-lg="5">
                  <ion-button [disabled]="form.invalid" (click)="submit()">{{ 'LOGIN_change' | translate | uppercase }}</ion-button>
                </ion-col>
              </ion-row>
            </form>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
