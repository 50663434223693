import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME } from '../domain/chests-culture.config';
import { CultureState } from './culture.state';

/**
 * MAIN CULTURE STATE SELECTOR
 */
export const MainSelector = createFeatureSelector<CultureState>(FEATURE_NAME);
/**
 * FIRST LEVEL CULTURE STATE SELECTORS
 */
export const CultureInUse = createSelector(MainSelector, (state) => state.inUse);
export const CulturesLoaded = createSelector(MainSelector, (state) => state.loaded);
export const CultureSetted = createSelector(MainSelector, (state) => state.setted);
export const CultureCurrencies = createSelector(MainSelector, (state) => state.currencies);
export const CultureLanguages = createSelector(MainSelector, (state) => state.languages);
export const CultureReportLanguages = createSelector(MainSelector, (state) => state.reportLanguages);

/**
 * SECOND LEVEL CULTURE STATE SELECTORS
 */
export const CultureCode = createSelector(CultureSetted, (state) => state.cultureCodeCurrency);
export const LanguageCode = createSelector(CultureSetted, (state) => state.cultureCodeLanguage);
export const SurfaceUnitSetted = createSelector(CultureSetted, (state) => state.surfaceUnit);
export const UnitLengthSetted = createSelector(CultureSetted, (state) => state.unitLength);
export const CurrencySetted = createSelector(CultureSetted, (state) => state.currency);
export const CurrencyPerSurfaceUnitSetted = createSelector(CultureSetted, (state) => state.currencyPerSurfaceUnit);
// TODO: revisar esto para report
export const ReportCultureCode = createSelector(CultureSetted, (state) => state.reportCultureCode ?? '');
export const ReportCurrencySetted = createSelector(CultureSetted, (state) => state.reportCurrency ?? state.currency);
export const ReportCurrencyPerSurfaceUnitSetted = createSelector(CultureSetted, (state) => state.reportCurrencyPerSurfaceUnit ?? state.currencyPerSurfaceUnit);

/**
 * value: c.value,
 label: c.translateKey,
 */
