<form id="password-change" class="example-container" [formGroup]="requirePasswordChangeForm" (ngSubmit)="requirePasswordChange()">
  <a class="logo-container" [routerLink]="['/']">
    <img src="/assets/images/logo.png" />
  </a>
  <h1>{{ 'REQUIRE_PASSWORD_CHANGE_restorePassword' | translate }}</h1>
  <span [ngStyle]="{ width: !mobileQuery.matches ? '20%' : '98%' }"> {{ 'REQUIRE_PASSWORD_CHANGE_inputEmail' | translate }}</span>
  <div class="login-input-group">
    <input id="AT_user_email" type="email" class="login-input" placeholder="Email" formControlName="email" />
    <span *ngIf="requirePasswordChangeForm?.controls?.email?.touched && requirePasswordChangeForm?.controls?.email?.invalid" class="span-error">
      {{ getErrorMessage(requirePasswordChangeForm?.controls?.email) }}
    </span>
  </div>
  <div class="button-container">
    <ui-async-button
      class="mr-3"
      [rounded]="true"
      [asyncButton]="false"
      [buttonClassByType]="'secondary'"
      [size]="'lg'"
      componentId="AT_require_password_change_cancel_btn"
      [label]="'cancel' | translate"
      (clickEvent)="cancel()"
    ></ui-async-button>
    <ui-async-button
      [inputState]="requirePasswordChangeButtonState"
      [rounded]="true"
      [type]="'submit'"
      [asyncButton]="true"
      [buttonClassByType]="'primary'"
      [size]="'lg'"
      componentId="AT_require_password_change_send_btn"
      [label]="'send' | translate"
      [loadingLabel]="'send' | translate"
      [disabled]="requirePasswordChangeForm.invalid"
      (clickEvent)="requirePasswordChange()"
    ></ui-async-button>
  </div>
</form>
