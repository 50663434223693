<ui-mobile-form-dropdown
  [placeholder]="'LANG_selectLanguage' | translate | capitalize"
  controlName="language"
  [form]="form"
  [options]="languageList"
  [optionValueAttr]="'lang'"
  [optionLabelAttr]="'label'"
  [defaultValue]="langValue"
  [emitChanges]="true"
  (changes)="setLanguage($event)"
></ui-mobile-form-dropdown>
