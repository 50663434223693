import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'normalizeCurrency' })
export class NormalizeNumberPipe implements PipeTransform {
  transform(value: string): number {
    if (!isNaN(+value)) {
      return parseFloat(value);
    }
    const currencyRemoved = value.split(' ')[0];
    const separatorChar = Array.from(currencyRemoved)
      .reverse()
      .find((el) => isNaN(+el));

    if (separatorChar === ',') {
      // split('.').join('') === replace(/\./g, '')
      const valueWithoutPoints = value.replace(/\./g, '');
      const finalValue = parseFloat(valueWithoutPoints.replace(',', '.'));
      return isNaN(finalValue) ? 0 : finalValue;
    } else {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      return isNaN(finalValue) ? 0 : finalValue;
    }
  }
}
