import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormInputFileComponent } from './form-input-file.component';

@NgModule({
  declarations: [FormInputFileComponent],
  imports: [CommonModule, IonicModule, TranslateModule],
  exports: [FormInputFileComponent],
})
export class FormInputFileModule {}
