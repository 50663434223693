import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { I18nModule } from '@foxeet/utils-modules';
import { ImageCropperCustomizerComponent } from './image-cropper-customizer.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ImageCropperCustomizerComponent],
  imports: [CommonModule, FontAwesomeModule, I18nModule.forChild(), ImageCropperModule, MatSliderModule, MatIconModule, MatButtonModule],
  exports: [ImageCropperCustomizerComponent],
})
export class ImageCropperCustomizerModule {}
