import { createAction, props } from '@ngrx/store';
import { CountryModel, LocalityModel, ProvinceModel, StreetModel } from '@foxeet/domain';
import { FEATURE_NAME } from '../domain/location-chest.config';

const featureTITLE = `[${FEATURE_NAME}] `;

export const setCountries = createAction(featureTITLE + 'setCountries');
export const setCountriesAPI = createAction(featureTITLE + 'setCountriesAPI', props<{ countries: CountryModel[] }>());
export const setProvinces = createAction(featureTITLE + 'setProvinces', props<CountryModel>());
export const setProvincesAPI = createAction(featureTITLE + 'setProvincesAPI', props<{ country: CountryModel; provinces: ProvinceModel[] }>());
export const setLocalities = createAction(featureTITLE + 'setLocalities', props<{ country: CountryModel; province: ProvinceModel }>());
export const setLocalitiesAPI = createAction(featureTITLE + 'setLocalitiesAPI', props<{ country: CountryModel; province: ProvinceModel; localities: LocalityModel[] }>());
export const setVias = createAction(featureTITLE + 'setVias', props<{ locality: LocalityModel; province: ProvinceModel }>());
export const setViasAPI = createAction(featureTITLE + 'setViasAPI', props<{ locality: LocalityModel; vias: StreetModel[] }>());
