import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingData } from '@foxeet/domain';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class LicensePaymentMethodsService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'LicenseePaymentMethods', cache);
  }

  editBillingData(data: BillingData): Observable<void> {
    return this.put<typeof data, void>(`BillingData`, data);
  }

  getBillingData(): Observable<BillingData> {
    return this.get(`BillingData`);
  }

  setAsDefaultPaymentOption(id: number): Observable<void> {
    return this.put(`${id}/SetAsDefaultPaymentMethod`, null);
  }

  urlAddPayment(): Observable<string> {
    return this.get('UrlAddPayment');
  }
}
