import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18nModule } from '@foxeet/utils-modules';
import { WebAsyncButtonModule, WebExpertOnlyContainerModule } from '../..';
import { WebFormInputDropdownModule } from '../../../form-inputs/form-dropdown';
import { WebFormInputCalendarModule } from '../../../form-inputs/form-input-calendar';
import { WebFormInputCheckboxModule } from '../../../form-inputs/form-input-checkbox';
import { WebFormInputChipsModule } from '../../../form-inputs/form-input-chips';
import { WebFormInputMaskModule } from '../../../form-inputs/form-input-mask';
import { WebFormInputMultiselectModule } from '../../../form-inputs/form-input-multiselect';
import { WebFormInputNumberModule } from '../../../form-inputs/form-input-number';
import { WebFormInputPasswordModule } from '../../../form-inputs/form-input-password';
import { WebFormInputTextModule } from '../../../form-inputs/form-input-text';
import { WebFormInputTextareaModule } from '../../../form-inputs/form-input-text-area';
import { WebFormInputTripleSwitchModule } from '../../../form-inputs/form-input-triple-switch';
import { DynamicFormComponent } from './dynamic-form.component';

const UI_COMPONENTS = [
  WebFormInputCheckboxModule,
  WebFormInputDropdownModule,
  WebFormInputTextModule,
  WebFormInputTextareaModule,
  WebFormInputCalendarModule,
  WebFormInputTripleSwitchModule,
  WebFormInputMultiselectModule,
  WebFormInputNumberModule,
  WebFormInputMaskModule,
  WebFormInputChipsModule,
  WebFormInputPasswordModule,
  WebAsyncButtonModule,
];

@NgModule({
  declarations: [DynamicFormComponent],
  imports: [CommonModule, I18nModule.forChild(), MatTooltipModule, MatFormFieldModule, MatIconModule, MatButtonModule, WebExpertOnlyContainerModule, ...UI_COMPONENTS],
  exports: [DynamicFormComponent],
})
export class DynamicFormModule {}
