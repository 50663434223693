import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toPendingState' })
export class ToPendingState implements PipeTransform {
  constructor(private _translateService: TranslateService) {}

  transform(value: boolean) {
    return value ? this._translateService.instant('pending') : '-';
  }
}
