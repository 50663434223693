import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { FormCalendarModule } from '../../form-input-calendar/mobile/form-calendar.module';
import { FormToggleModule } from '../../form-toggle/mobile/form-toggle.module';
import { FormProposedDatesComponent } from './form-proposed-dates.component';

@NgModule({
  declarations: [FormProposedDatesComponent],
  imports: [CommonModule, ReactiveFormsModule, IonicModule, I18nModule.forChild(), FormCalendarModule, FormToggleModule],
  exports: [FormProposedDatesComponent],
})
export class FormProposedDatesModule {}
