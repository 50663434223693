import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InternationalizationTypes } from '@foxeet/domain';
import { BaseFormMobileComponent } from '../../../base-components';

@Component({
  selector: 'ui-mobile-form-input-number',
  templateUrl: 'form-input-number.component.html',
  styleUrls: ['form-input-number.component.scss'],
})
export class FormInputNumberComponent extends BaseFormMobileComponent<string | number> implements OnInit {
  @Input() internationalizationType?: InternationalizationTypes;
  @Input() enableFormat = true;
  @Input() suffix? = '';
  /** @deprecated Se deberia pasar el valor traducido por el suffix */
  @Input() suffixType?: InternationalizationTypes;
  @Input() min?: number;
  @Input() max?: number;
  @Input() step = 1;
  @Input() cssClass?: string;
  @Input() digitsInfo = '.2-2';
  @Input() subLabel = '';
  @Input() canEmitOnBlur = true;
  @Output() blur = new EventEmitter();

  public focused = false;
  public internationalizationTypes = InternationalizationTypes;

  emitBlur() {
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.blur.emit(this.control.value);
  }
}
