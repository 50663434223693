import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { loginControls } from '../../auth/domain/auth.config';

@Component({
  selector: 'mcore-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss'],
})
export class FormLoginComponent {
  @Input() form: FormGroup;
  @Output() login: EventEmitter<boolean> = new EventEmitter(false);

  public formNames = loginControls;

  public submitLogin() {
    this.login.next(true);
  }
}
