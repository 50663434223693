import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';
import { FormInputSearcherComponent } from './form-input-searcher.component';
import { SearchableListModule } from '../../../general-components/searchable-list/mobile/searchable-list.module';

@NgModule({
  declarations: [FormInputSearcherComponent],
  imports: [CommonModule, IonicModule, FormValidationMessagesModule, ReactiveFormsModule, I18nModule.forChild(), PipesModule, SearchableListModule],
  exports: [FormInputSearcherComponent],
})
export class FormInputSearcherModule {}
