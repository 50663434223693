<form class="form" [formGroup]="form">
  <ion-row>
    <ion-col size="12">
      <ui-mobile-form-input-text [componentId]="'AT_loginEmail'" [label]="'LOGIN_email' | translate" [form]="form" [controlName]="formNames.user"></ui-mobile-form-input-text>
    </ion-col>

    <ion-col size="12">
      <ui-mobile-form-input-password
        [componentId]="'AT_loginPassword'"
        [label]="'LOGIN_password' | translate"
        [form]="form"
        [controlName]="formNames.password"
      ></ui-mobile-form-input-password>
    </ion-col>
    <ion-col size="12" *ngIf="form.get(formNames.password).hasError('wrongCredentials')">
      <p>{{ 'FORM_VALIDATION_wrongCredentials' | translate | capitalize }}</p>
    </ion-col>
  </ion-row>
</form>

<ion-row class="ion-justify-content-center">
  <ion-col size-xs="8" size-sm="6" size-md="5" size-lg="5">
    <ion-button id="AT_login_btn" (click)="submitLogin()">{{ 'LOGIN_login' | translate | uppercase }}</ion-button>
  </ion-col>
</ion-row>
