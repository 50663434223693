import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CountryModel, LocalityModel, ProvinceModel } from '@foxeet/domain';
import { FEATURE_NAME } from '../domain/location-chest.config';
import { generateKey, LocalitiesKey, LocationState } from './location.state';

export const MainSelector = createFeatureSelector<LocationState>(FEATURE_NAME);

export const Countries = createSelector(MainSelector, (state) => state.countries);
export const AllProvinces = createSelector(MainSelector, (state) => state.provinces);
export const getProvinces = (props: { country: CountryModel }) => createSelector(MainSelector, (state) => state.provinces[props.country.threeLetterIso]);
export const AllLocaties = createSelector(MainSelector, (state) => state.localities);
export const getLocaties = (props: { country: CountryModel; province: ProvinceModel }) => {
  const key: LocalitiesKey = generateKey(props.country.threeLetterIso, props.province.ineCod);
  return createSelector(MainSelector, (state) => state.localities[key]);
};
export const getVias = (props: { locality: LocalityModel }) => createSelector(MainSelector, (state) => state.vias[`${props.locality.ineCodLocality}`]);
export const AllVias = createSelector(MainSelector, (state) => state.vias);
