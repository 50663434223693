<div class="container">
  <div class="title-container">
    <h2>
      {{ 'change_password' | translate }}
    </h2>
  </div>

  <div class="body-container">
    <form [formGroup]="formGroup">
      <div class="full-width py-3">
        <ui-form-input-password [form]="formGroup" [controlName]="'password'" [label]="'password' | translate"></ui-form-input-password>
      </div>
      <div class="full-width py-3">
        <ui-form-input-password [form]="formGroup" [controlName]="'confirmPassword'" [label]="'confirmPassword' | translate"></ui-form-input-password>
      </div>
      <div class="full-width py-3 passwortNotMatchConainer">
        <span
          *ngIf="formGroup.hasError('notSamePassword') && !formGroup.get('confirmPassword')?.hasError('minlength') && !formGroup.get('confirmPassword')?.hasError('required')"
          class="span-error"
          >{{ 'CHANGE_TEMPORAL_PASSWORD_passwordsNotMatch' | translate }}
        </span>
      </div>
    </form>
  </div>

  <div class="footer-container">
    <div class="p-grid">
      <ui-async-button
        componentId="AT_close_change_password_modal_btn"
        class="p-col"
        [asyncButton]="false"
        [buttonClassByType]="'secondary'"
        [size]="'md'"
        [label]="'close' | translate"
        [loadingLabel]="'closing' | translate"
        (clickEvent)="closeEventTrigger()"
      ></ui-async-button>

      <ui-async-button
        componentId="AT_confirm_change_password_modal_btn"
        class="p-col"
        [asyncButton]="false"
        [buttonClassByType]="'primary'"
        [size]="'md'"
        [label]="'confirm' | translate"
        [loadingLabel]="'confirming' | translate"
        (clickEvent)="confirmEventTrigger()"
        [inputState]="stateButton"
        [disabled]="formGroup.invalid"
      ></ui-async-button>
    </div>
  </div>
</div>
