import { CreditCardIndustries } from '../enums';

export interface SupplierCompanyPaymentMethodFilterModel {
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  possibleOrderFieldNames: string[];
  id: number;
  ids: string[];
  supplierCompanyId: number;
  supplierCompanyIds: string[];
}

export interface LicensePaymentMethodIndexModel {
  id: number;
  supplierCompanyId: number;
  creditCardToken: string;
  creditCardNumber: string;
  creditCardExpiryDateMonth: number;
  creditCardExpiryDateYear: number;
  isDefault: boolean;
  creditCardType: CreditCardTypes;
  creditCardIndustry: CreditCardIndustries;
}

export interface SupplierCompanyPaymentMethodEditModel {
  id: number;
  creditCardToken: string;
  creditCardNumber: string;
  creditCardExpiryDateMonth: number;
  creditCardExpiryDateYear: number;
  creditCardCsv: string;
  isDefault: boolean;
}

export enum CreditCardTypes {
  Debit = 0,
  Credit = 1,
}
