import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum ConfirmExitModalResponseEnum {
  CANCEL = 0,
  EXIT_WITHOUT_SAVING,
  EXIT_SAVING,
}

@Component({
  selector: 'ui-confirm-exit',
  templateUrl: 'confirm-exit-modal.component.html',
  styleUrls: ['confirm-exit-modal.component.scss'],
})
export class ConfirmExitModalComponent {
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() private dialogRef: MatDialogRef<ConfirmExitModalComponent>) {}

  cancel() {
    this.dialogRef.close(ConfirmExitModalResponseEnum.CANCEL);
  }

  exit() {
    // console.log('EXIT');
    this.dialogRef.close(ConfirmExitModalResponseEnum.EXIT_WITHOUT_SAVING);
  }
  exitSaving() {
    // console.log('EXIT SAVING');
    this.data.saveCallback();
    setTimeout(() => this.dialogRef.close(ConfirmExitModalResponseEnum.EXIT_SAVING), 1000);
  }
}
