<mat-menu #appMenu="matMenu" class="menu-user-panel">
  <ng-template matMenuContent>
    <div class="menu-user-panel-header-container">
      <span>
        <img [src]="avatar || '/assets/images/profile/user.jpg'" />
      </span>
      <span class="user-name">{{ userName }}</span>
    </div>

    <div class="menu-user-panel-content-container">
      <mat-list class="pt-0">
        <ul>
          <ng-container *ngFor="let item of menuItems; index">
            <li class="li-element-link" *ngIf="item?.isVisible | async">
              <mat-list-item (click)="item?.callback()" id="AT_menuitem_{{ index }}">
                <a>
                  <mat-icon> {{ item?.icon?.name }} </mat-icon>
                  <span class="pl-3">{{ item?.label | translate }}</span>
                </a>
              </mat-list-item>
            </li>
          </ng-container>

          <ng-container *ngFor="let license of licenseList; first as isFirst; last as isLast">
            <hr *ngIf="isFirst" />
            <li class="li-element-link license">
              <mat-list-item (click)="selectLicense(license)">
                <a>
                  <mat-icon *ngIf="license.isCurrent" color="primary"> done </mat-icon>
                  <mat-icon *ngIf="!license.isCurrent" color="primary"> </mat-icon>
                  <span class="pl-3">{{ license?.name | translate }}</span>
                  <mat-icon
                    class="account-icon"
                    *ngIf="license.isAdmin"
                    id="AT_account_management_btn_{{ license.id ?? license.name }}"
                    [matTooltip]="'ACCOUNT_MANAGEMENT_accountManagement' | translate"
                    (click)="goToAccountManagement(license); $event.stopPropagation()"
                  >
                    settings_outline
                  </mat-icon>
                </a>
              </mat-list-item>
            </li>
            <hr *ngIf="isLast" />
          </ng-container>
          <li class="m-nav__item logout-btn">
            <button [id]="'AT_btnProfileLogout'" mat-stroked-button (click)="onLogout()" translate>{{ 'logout' | translate }}</button>
          </li>
        </ul>
      </mat-list>
    </div>
  </ng-template>
</mat-menu>

<button mat-icon-button [matMenuTriggerFor]="appMenu" class="image-container">
  <img [src]="avatar || '/assets/images/profile/user.jpg'" />
</button>
