import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { ChipComponent } from './chip.component';

@NgModule({
  declarations: [ChipComponent],
  imports: [CommonModule, IonicModule, I18nModule.forChild(), PipesModule],
  exports: [ChipComponent],
})
export class ChipModule {}
