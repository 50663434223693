import { inject, InjectionToken } from '@angular/core';
import { HjFn } from '../types/hj';
import { WINDOW } from './window-token';

/**
 * Check if there is some global function called gtag on Window object, or create an empty function to doesn't brake codes...
 */
export function getHjFn(window: (Window & { hj?: HjFn }) | null | undefined): HjFn | null | undefined {
  // // h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  return window ? window.hj : null;
}

/**
 * Provides an injection token to access Google Analytics Gtag Function
 */
export const NGX_HJ_FN = new InjectionToken<HjFn | null | undefined>('ngx-hj-fn', {
  providedIn: 'root',
  factory: () => getHjFn(inject(WINDOW)),
});
