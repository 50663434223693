import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private translate: TranslateService, private toastController: ToastController) {}

  default(message: string) {
    from(
      this.toastController.create({
        message: this.translate.instant(message),
        duration: 3000,
        position: 'bottom',
        color: 'primary',
        buttons: [
          {
            side: 'end',
            text: 'x',
            role: 'cancel',
          },
        ],
      }),
    ).subscribe((toast) => toast.present());
  }

  success(message: string, extraClass?: string) {
    from(
      this.toastController.create({
        message: this.translate.instant(message),
        duration: 3000,
        position: 'bottom',
        color: 'success',
        cssClass: extraClass,
        buttons: [
          {
            side: 'end',
            text: 'x',
            role: 'cancel',
          },
        ],
      }),
    ).subscribe((toast) => toast.present());
  }

  error(title: string, message: string, extraClass?: string) {
    from(
      this.toastController.create({
        header: this.translate.instant(title),
        message: this.translate.instant(message),
        duration: 3000,
        position: 'bottom',
        color: 'danger',
        cssClass: extraClass,
        buttons: [
          {
            side: 'end',
            text: 'x',
            role: 'cancel',
          },
        ],
      }),
    ).subscribe((toast) => toast.present());
  }

  info(message: string) {
    from(
      this.toastController.create({
        message: this.translate.instant(message),
        position: 'bottom',
        color: 'secondary',
        cssClass: 'toast-appraisers-in-exchange',
        buttons: [
          {
            side: 'end',
            text: 'x',
            role: 'cancel',
          },
        ],
      }),
    ).subscribe((toast) => toast.present());
  }
}
