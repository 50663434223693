import { CommonModule } from '@angular/common';
import { Input, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { FormValidationMessagesModule } from '../../form-validation-messages/web/form-validation-messages.module';
import { FormInputTextComponent } from './form-input-text.component';

@NgModule({
  declarations: [FormInputTextComponent],
  imports: [CommonModule, FormValidationMessagesModule, InputTextModule, TooltipModule, ReactiveFormsModule, MatIconModule, MatButtonModule],
  exports: [FormInputTextComponent],
})
export class FormInputTextModule {}
