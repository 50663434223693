import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseFormMobileComponent } from '../../../base-components';
import { RadioModel } from '../form-input-radio-list.types';

@Component({
  selector: 'ui-mobile-form-input-radio-list',
  templateUrl: './form-input-radio-list.component.html',
  styleUrls: ['./form-input-radio-list.component.scss'],
})
export class FormInputRadioListComponent extends BaseFormMobileComponent implements OnInit {
  @Input() radioList: RadioModel[];

  @Output() controlEmitter = new EventEmitter<boolean>();

  ngOnInit() {
    super.ngOnInit();
    this.control.valueChanges.pipe(takeUntil(this._componentDestroyed)).subscribe(() => {
      this.controlEmitter.emit(true);
    });
  }
}
