import { createReducer, on } from '@ngrx/store';
import { generateKey, initialState, LocationState } from './location.state';
import { setCountriesAPI, setLocalitiesAPI, setProvincesAPI, setViasAPI } from './location.actions';

export const LocationReducer = createReducer<LocationState>(
  initialState,
  on(setCountriesAPI, (state, action) => ({
    ...state,
    countries: action.countries,
  })),
  on(setProvincesAPI, (state, action) => ({
    ...state,
    provinces: {
      ...state.provinces,
      [action.country.threeLetterIso]: action.provinces,
    },
  })),
  on(setLocalitiesAPI, (state, action) => ({
    ...state,
    localities: {
      ...state.localities,
      [generateKey(action.country.threeLetterIso, action.province.ineCod)]: action.localities,
    },
  })),
  on(setViasAPI, (state, action) => ({
    ...state,
    vias: {
      ...state.vias,
      [action.locality.ineCodLocality]: action.vias,
    },
  })),
);
