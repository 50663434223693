import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BACK_CONFIG, BackConfig } from '../injectors';

@Injectable()
export class BackUrlService {
  public showAppVersionAlert$: Subject<boolean> = new Subject();

  constructor(@Inject(BACK_CONFIG) private backConfig: BackConfig) {}

  getFullUrl(): string {
    return `${this.backConfig.baseUrl.value}/${this.backConfig.lang?.value}`;
  }
}
