<ion-list>
  <ion-radio-group [formControl]="control" mode="md" [value]="control?.value">
    <ion-list-header>
      <ion-label *ngIf="label" class="title"> {{ label | translate }} </ion-label>
      <ui-mobile-form-validation-messages *ngIf="showValidationMessages && control" [control]="control"></ui-mobile-form-validation-messages>
    </ion-list-header>

    <ion-item *ngFor="let radio of radioList; let i = index" [id]="componentId + '_' + i">
      <ion-radio [value]="radio.value" labelPlacement="end" justify="start">
        <ui-mobile-chip *ngIf="radio.decorator" [chip]="radio.decorator"></ui-mobile-chip> {{ radio.label | translate }}
      </ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-list>
