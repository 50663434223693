<ion-col class="no-padding">
  <ion-item
    [ngClass]="{
      error: control && control.errors,
      'non-opacity': readonly,
      suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === '')
    }"
  >
    <ion-input
      type="text"
      [id]="componentId"
      label="{{ label ? (label | translate | capitalize) : '' }}"
      labelPlacement="floating"
      [formControl]="control"
      [placeholder]="placeholder ?? undefined"
      (ionBlur)="blur($event)"
      [readonly]="readonly"
    >
    </ion-input>
  </ion-item>
</ion-col>
<ion-col *ngIf="showButton" class="button no-padding" size="auto">
  <ion-button class="button-icon-clear-rounded" [style]="'color: ' + buttonColor + ' !important'" (click)="callback(control.value)" [disabled]="!control.value">
    <ion-icon [name]="buttonIcon"></ion-icon>
  </ion-button>
</ion-col>

<ui-mobile-form-validation-messages *ngIf="control && control.errors" [control]="control"></ui-mobile-form-validation-messages>
