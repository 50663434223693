import { Pipe } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { InternationalizationTypes } from '@foxeet/domain';
import { ASYNC_BUTTON_STATE } from '@foxeet/ui';
import { BehaviorSubject } from 'rxjs';

export interface TableConfig {
  topButtonActions: TopButtonTable[];
  downloadExcelEnabled: boolean;
  expandableRows?: boolean;
  expandableRowComponent?: any;
  displayedColumns: string[];
  columns: TableColumn[];
  displayedColGroup?: string[];
  headerColGroup?: any;
  footer?: any;
}

export interface TopButtonTable {
  id: string;
  label: string;
  icon?: any; // string or IconDefinition
  disabled?: boolean;
  action: () => any;
  isVisible: BehaviorSubject<boolean>;
  inputState?: BehaviorSubject<ASYNC_BUTTON_STATE>;
}

export interface TableCellTooltip {
  label: string;
  position?: TooltipPosition;
}

export enum TableCellAlignment {
  center = 'center',
  left = 'left',
  right = 'right',
}

export enum TableActionColumns {
  download = 'download',
  print = 'print',
  delete = 'delete',
  menu = 'menu',
}

export interface TableColumn {
  columnId: string | TableActionColumns;
  name: string;
  internationalizationType?: InternationalizationTypes;
  btnState?: BehaviorSubject<ASYNC_BUTTON_STATE>;
  // TODO: change and refactor every config and look up for types
  color?: string; // TableCellType; // Se usa muy raro
  cellType?: string; // TableCellType; // Por defecto SPAN
  sortable?: boolean;
  pipe?: Pipe; // PipeTransform;
  suffix?: string;
  keysPipe?: any[];
  entryComponent?: any; // Component | Directive
  options?: TableMenuButtonOption[];
  icon?: string;
  callback?: (...args: any) => unknown;
  link?: any;
  tooltip?: TableCellTooltip;
  truncatedTooltipPosition?: TooltipPosition;
  truncatedTooltipDelay?: number;
  id?: string;
  dynamicValues?: string[];
  alignment?: TableCellAlignment;
  hideField?: string; // Usar solo con ACTION_BUTTON
  entryComponentContentTypes?: EntryComponentContentTypes;
}

export enum TableCellType {
  SPAN = 'span',
  SPAN_TEXT_TRUNCATED = 'SPAN_TEXT_TRUNCATED',
  PIPE = 'PIPE',
  ENTRY_COMPONENT = 'ENTRY_COMPONENT',
  BOOLEAN = 'BOOLEAN',
  LABEL = 'LABEL',
  CHECKBOX = 'CHECKBOX',
  ACTION_BUTTON = 'ACTION_BUTTON',
  OPTIONS_MENU = 'OPTIONS_MENU',
  CHIPS_LIST = 'CHIPS_LIST',
  MAT_ICON = 'MAT_ICON',
  ACTION_BUTTON_SPAN = 'ACTION_BUTTON_SPAN',
  LINK = 'LINK',
  NOTE = 'NOTE',
}

export interface TableMenuButtonOption {
  id: string;
  label: string;
  callback: any;
  hideField?: string;
  icon?: string;
  isHidden?: boolean;
}

export enum EntryComponentContentTypes {
  APPRAISAL = 0,
  USERS = 1,
  REQUESTS = 2,
  REQUESTS_COMMUNICATIONS = 3,
}
