<div class="preview-wrapper" *ngIf="showPreview">
  <div class="preview-background" *ngIf="preview" [ngStyle]="{ 'background-image': 'url(' + photo + ')' }">
    <div class="preview-content">
      <div class="preview"><img [src]="preview" /></div>
      <div *ngIf="previewDetail && preview" class="preview-detail"><img [src]="previewDetail" /></div>
    </div>
  </div>
</div>
<div class="wrapper">
  <image-cropper
    [imageChangedEvent]="imageEvent"
    [maintainAspectRatio]="imageCropperOptions.maintainAspectRatio"
    [containWithinAspectRatio]="imageCropperOptions.containWithinAspectRatio"
    [aspectRatio]="aspectRatio"
    [resizeToWidth]="imageCropperOptions.resizeToWidth"
    [cropperMinWidth]="imageCropperOptions.cropperMinWidth"
    [onlyScaleDown]="imageCropperOptions.onlyScaleDown"
    [roundCropper]="roundCropper"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [alignImage]="'center'"
    [style.display]="showCropper ? null : 'none'"
    format="png"
    output="base64"
    backgroundColor="white"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
  ></image-cropper>

  <div class="actions-wrapper row" *ngIf="showCropper">
    <!-- ZOOM -->
    <div class="col-xs-6 col-sm-6 action-section">
      <p class="action-section-title">{{ 'zoom' | translate }}</p>
      <div class="action-section-slider">
        <button mat-icon-button (click)="transformCropper(actionsTypes.ZoomOut, null)">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <mat-slider thumbLabel [min]="imageCropperTransformConfig.minScale" [max]="imageCropperTransformConfig.maxScale" [step]="imageCropperTransformConfig.stepScale"
          ><input matSliderThumb (change)="transformCropper(actionsTypes.Zoom, $event)" [value]="transform.scale"
        /></mat-slider>
        <button mat-icon-button (click)="transformCropper(actionsTypes.ZoomIn, null)">
          <mat-icon>zoom_in</mat-icon>
        </button>
      </div>
    </div>

    <!-- FLIP -->
    <div class="col-xs-6 col-sm-3 action-section">
      <p class="action-section-title">{{ 'reflect' | translate }}</p>
      <div class="action-section-buttons">
        <button mat-icon-button class="button-flip-horizontal" (click)="transformCropper(actionsTypes.FlipH, null)">
          <mat-icon>flip</mat-icon>
        </button>
        <button mat-icon-button class="button-flip-vertical" (click)="transformCropper(actionsTypes.FlipV, null)">
          <mat-icon>flip</mat-icon>
        </button>
      </div>
    </div>

    <!-- ROTATE -->
    <div class="col-xs-6 col-sm-3 action-section">
      <p class="action-section-title">{{ 'rotate' | translate }}</p>
      <div class="action-section-buttons">
        <button mat-icon-button (click)="rotateLeft()">
          <mat-icon>rotate_left</mat-icon>
        </button>
        <button mat-icon-button (click)="rotateRight()">
          <mat-icon>rotate_right</mat-icon>
        </button>
      </div>
    </div>

    <!-- ENDEREZAR -->
    <div class="col-xs-6 col-sm-6 action-section">
      <p class="action-section-title">{{ 'straightened' | translate }}</p>
      <div class="action-section-buttons">
        <div class="action-section-slider">
          <button mat-icon-button (click)="transformCropper(actionsTypes.RotateLeft, null)">
            <mat-icon>remove</mat-icon>
          </button>
          <mat-slider thumbLabel [min]="imageCropperTransformConfig.minRotate" [max]="imageCropperTransformConfig.maxRotate" [step]="imageCropperTransformConfig.stepRotate"
            ><input matSliderThumb (change)="transformCropper(actionsTypes.Rotate, $event)" [value]="transform.rotate"
          /></mat-slider>
          <button mat-icon-button (click)="transformCropper(actionsTypes.RotateRight, null)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-6 action-section-reset">
      <button mat-button class="button-flip-horizontal" color="warn" (click)="resetImage()">{{ 'resetImageCropperChanges' | translate }}</button>
    </div>
  </div>
</div>
