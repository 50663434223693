import { HttpClient, HttpEvent, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppraisalAssetLogItemIndexModel,
  AppraisalAssignWorkerModel,
  AppraisalsEventsByDayForAppraiserModel,
  AppraiserAppraisalExchangePreferencesModel,
  AppraiserUserAppraisalExchangePreferences,
  AppraiserUserAppraisalExchangeSpecificationsModel,
  AppraiserUserEventFilterModel,
  AppraiserUserSummaryEventsByDay,
  BillingDataEditModel,
  BillingDataModel,
  DateModel,
  GenericTableData,
  OrNull,
  UserEditPartnerDataModel,
  UserEditPasswordModel,
  UserFileModel,
  UserIndexModel,
  UserPartnerDataModel,
  WorkflowItemWorkerModel,
} from '@foxeet/domain';
import { Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';
import { CreateEditAbsenceModel } from '../../../../domain/src/lib/models/absences.model';

@Injectable()
export class UsersService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Users', cache);
  }

  getRandomPassword(): Observable<HttpResponse<string>> {
    return this.http.get(`${this.apiBranch}/GenerateRandomPassword`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  sendNewPassword(body: UserEditPasswordModel) {
    return this.http.put(`${this.apiBranch}/${body.userId}/Password`, body, { observe: 'response' });
  }

  public getUserFilesById(userId: any) {
    return this.get<UserFileModel[]>(`${userId}/Files`);
  }

  public deleteUserFilesById(userId: any, fileType: any) {
    return this.http.delete(`${this.apiBranch}/${userId}/Files/${fileType}`, { observe: 'response' });
  }

  public uploadDocument(file: Blob, UserId: any, FileType: any, ExpirationDateTime: any): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('File', file);
    if (ExpirationDateTime) formData.append('DocumentExpirationDate', `${ExpirationDateTime}`);
    return this.putMultipartFile(`${this.apiBranch}/${UserId}/Files/${FileType}`, formData);
  }

  public getAppraisalExchangePreferences(userId: string | number): Observable<AppraiserUserAppraisalExchangePreferences> {
    return this.get<AppraiserUserAppraisalExchangePreferences>(`${userId}/AppraisalExchangePreferences`);
  }

  public putAppraisalExchangePreferences(data: AppraiserAppraisalExchangePreferencesModel, userId: string | number): Observable<HttpResponse<void>> {
    return this.http.put<void>(`${this.apiBranch}/${userId}/AppraisalExchangePreferences`, data, { observe: 'response' });
  }

  public getPartnerData(userId: string | number): Observable<UserPartnerDataModel> {
    return this.get<UserPartnerDataModel>(`${userId}/PartnerData`);
  }

  public putPartnerData(userId: string | number, data: UserEditPartnerDataModel): Observable<HttpResponse<void>> {
    return this.http.put<void>(`${this.apiBranch}/${userId}/PartnerData`, data, { observe: 'response' });
  }

  public assignToAppraisal(workflowItemWorkers: WorkflowItemWorkerModel[], reason: OrNull<string> = null) {
    const body = { workflowItemWorkers, reason };
    return this.http.put<AppraisalAssignWorkerModel[]>(`${this.apiBranch}/AssignToAppraisal`, body, { observe: 'response' });
  }

  public assignToAppraisalValidation(workflowItemWorkers: WorkflowItemWorkerModel[], reason: OrNull<string> = null) {
    const body = { workflowItemWorkers, reason };
    return this.http.put<AppraisalAssignWorkerModel[]>(`${this.apiBranch}/AssignToAppraisalValidation`, body, { observe: 'response' });
  }

  public listPageDistanceBetweenPointByFilter(
    page: string | number | boolean,
    size: string | number | boolean,
    body: any,
  ): Observable<HttpResponse<GenericTableData<UserIndexModel>>> {
    const params = new HttpParams().append('page', page).append('pagesize', size);
    return this.http.post<GenericTableData<UserIndexModel>>(`${this.apiBranch}/ListPagedDistanceBetweenPoint`, body, {
      observe: 'response',
      params,
    });
  }

  public getBillingDataByUserId(id: number): Observable<HttpResponse<BillingDataModel>> {
    return this.http.get<BillingDataModel>(`${this.apiBranch}/${id}/BillingData`, { observe: 'response' });
  }

  public putBillingDataByUserId(id: number, body: BillingDataEditModel): Observable<HttpResponse<string>> {
    return this.http.put<string>(`${this.apiBranch}/${id}/BillingData`, body, { observe: 'response' });
  }

  public ListEventsByDateRange(id: number, agendaConfig: AppraiserUserEventFilterModel) {
    return this.post<AppraiserUserEventFilterModel, AppraiserUserSummaryEventsByDay[]>(`${id}/ListEventsByDateRange`, agendaConfig);
  }

  public ListEventsByDay(date: DateModel) {
    return this.post<null, AppraisalsEventsByDayForAppraiserModel>('ListEventsByDay', null, {
      params: this.setParams({
        year: date.Year,
        month: date.Month,
        day: date.Day,
      }),
    });
  }

  public acceptIncompatibilityContract() {
    return this.put<null, boolean>('AcceptIncompatibility', null);
  }

  public createAbsence(userId: number, absence: CreateEditAbsenceModel) {
    return this.post(`${userId}/Absences/Create`, absence);
  }

  public editAbsence(userId: number, absenceId: number, body: CreateEditAbsenceModel) {
    return this.put(`${userId}/Absences/${absenceId}/Edit`, body);
  }

  public listPageAbsence(page: number | undefined, size: number | undefined, body: any, userId: number) {
    const params = new HttpParams().append('page', page).append('pagesize', size);
    return this.http.post<GenericTableData<CreateEditAbsenceModel>>(`${this.apiBranch}/${userId}/Absences/ListPaged`, body, {
      params,
    });
  }

  public deleteAbsence(userId: number, absenceId: number) {
    return this.http.delete(`${this.apiBranch}/${userId}/Absences/${absenceId}`, { observe: 'response' });
  }

  public getExchangeSpecifications(userId: number) {
    return this.http.get<AppraiserUserAppraisalExchangePreferences>(`${this.apiBranch}/${userId}/AppraiserUserAppraisalExchangeSpecifications`);
  }

  public editExchangeSpecifications(userId: number, body: AppraiserUserAppraisalExchangeSpecificationsModel) {
    return this.http.put(`${this.apiBranch}/${userId}/AppraiserUserAppraisalExchangeSpecifications`, body);
  }

  public getUserValuations(userId: number) {
    return this.http.get(`${this.apiBranch}/${userId}/AppraiserUserGradeSummary`);
  }

  public getUserLogs(userId: number, page: number, pagesize: number, filter: any) {
    const body = filter;

    return this.post<typeof body, GenericTableData<AppraisalAssetLogItemIndexModel>>(`${userId}/Logs/ListPaged`, body, {
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }
}
