import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { I18nModule } from '@foxeet/utils-modules';
import { FormInputSwitchComponent } from './form-input-switch.component';

@NgModule({
  declarations: [FormInputSwitchComponent],
  imports: [CommonModule, InputSwitchModule, ReactiveFormsModule, I18nModule.forChild()],
  exports: [FormInputSwitchComponent],
})
export class FormInputSwitchModule {}
