import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class LicenseeInvoiceCustomizeService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'LicenseeBillingDataCustomize', cache);
  }

  public updateLogo(imageFile: File): Observable<string> {
    const formData = new FormData();
    formData.append('ImageFile', imageFile);

    return this.post<typeof formData, string>(`Logo`, formData, { responseType: 'text' });
  }

  public deleteLogo() {
    return this.delete<void>(`Logo`);
  }
}
