import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CultureSelectors } from '@foxeet/chests/culture';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { isNil } from '@foxeet/utils/functions';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'toDistance',
})
export class ToDistancePipe extends UnsuscriptionHandler implements PipeTransform {
  private decimalPipe!: DecimalPipe;

  constructor(protected store: Store) {
    super();
    this.store
      .select(CultureSelectors.CultureCode)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((culture) => (this.decimalPipe = new DecimalPipe(culture)));
  }

  /**
   * @todo Adapt to Other Lenght Units
   * @param value Distance
   * @param digitsInfo Digit Format Value
   * @returns OrNull<string>
   */
  transform(value: number | string | null | undefined, digitsInfo = '.1-1'): any {
    if (isNil(value)) {
      return null;
    }

    value = Number(value);
    const units = value > 1000 ? 'km' : 'm';
    const auxValue = value > 1000 ? value / 1000 : value;

    return `${this.decimalPipe.transform(auxValue, digitsInfo)} ${units}`;
  }
}
