import { Pipe, PipeTransform } from '@angular/core';
import { AuthoritiesMiddlewareService } from '../providers/authorities.service';

@Pipe({ name: 'editableIfHasAuthorities' })
export class EditableIfHasAuthoritiesPipe implements PipeTransform {
  constructor(private _authoritiesMiddlewareService: AuthoritiesMiddlewareService) {}

  transform(value: any): boolean {
    return this._authoritiesMiddlewareService.canAccess(value);
  }
}
