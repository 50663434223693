import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// Directive is needed for Ivy compatibility:
// https://angular.io/guide/migration-undecorated-classes
@Directive()
// Suppress warning due to Directive is only a "patch" to skip Ivy DIY requirements.
// tslint:disable-next-line: directive-class-suffix
export abstract class UnsuscriptionHandler implements OnDestroy {
  public _componentDestroyed: Subject<any> = new Subject();

  ngOnDestroy() {
    this._componentDestroyed.next('');
    this._componentDestroyed.unsubscribe();
  }
}
