export const FORM_REPORT_STEPS_KEY = {
  location: 'location',
  identification: 'identification',
  typology: 'typology',
  surfaces: 'surfaces',
  localityEnvironment: 'locality-environment',
  terrainFeatures: 'terrainFeatures',
  buildingTerrain: 'building-terrain',
  buildingFeatures: 'building-features',
  propertyFeatures: 'property-features',
  urbanisticSituation: 'urbanistic-situation',
  holding: 'holding',
  checks: 'checks',
  valuations: 'valuations',
  conditioningFactors: 'conditioningFactors',
  photos: 'photos',
  documentation: 'documentation',
  possession: 'possession',
  buildingState: 'buildingState',
  marketStudy: 'market-studies',
};
