import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { I18nModule } from '@foxeet/utils-modules';
import { CalendarModule } from 'primeng/calendar';
import { FormValidationMessagesModule } from '../../form-validation-messages/web/form-validation-messages.module';
import { FormInputCalendarComponent } from './form-input-calendar.component';

@NgModule({
  declarations: [FormInputCalendarComponent],
  imports: [CommonModule, CalendarModule, ReactiveFormsModule, FormValidationMessagesModule, I18nModule.forChild(), MatIconModule, MatButtonModule],
  exports: [FormInputCalendarComponent],
})
export class FormInputCalendarModule {}
