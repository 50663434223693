import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { Observable } from 'rxjs';
import { CheckCancelModel, LicenseeUserConnectionRequestEditModel, LicenseeUserConnectionRequestModel } from '@foxeet/domain';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class ConnectionsService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'LicenseeUserConnectionRequest', cache);
  }

  acceptConnectionRequest(connectionId: number): Observable<LicenseeUserConnectionRequestModel> {
    return this.put<null, LicenseeUserConnectionRequestModel>(`${connectionId}/Accept`, null, { observe: 'response' });
  }

  cancelConnectionRequest(connectionId: number): Observable<LicenseeUserConnectionRequestModel> {
    return this.put<null, LicenseeUserConnectionRequestModel>(`${connectionId}/Cancel`, null, { observe: 'response' });
  }

  rejectConnectionRequest(connection: LicenseeUserConnectionRequestEditModel): Observable<LicenseeUserConnectionRequestModel> {
    return this.put<typeof connection, LicenseeUserConnectionRequestModel>(`${connection.id}/Reject`, connection, { observe: 'response' });
  }

  resendConnectionRequest(connectionId: number): Observable<any> {
    return this.put<null, any>(`${connectionId}/Resend`, null, { observe: 'response' });
  }

  checkCancel(id: number, userId: number, licenseeId: number) {
    return this.get<CheckCancelModel>(`${id}/checkCancel`, {
      params: this.setParams({
        userId,
        licenseeId,
      }),
    });
  }
}
