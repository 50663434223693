<div class="calendar-header">
  <ion-grid>
    <ion-row class="legend ion-align-items-center ion-justify-content-around">
      <ion-row class="ion-align-items-center"
        ><div class="event event--visit"></div>
        <p class="legend-text">{{ 'APPRAISER_visits' | translate }}</p></ion-row
      >
      <ion-row class="ion-align-items-center"
        ><div class="event event--limit"></div>
        <p class="legend-text">{{ 'APPRAISER_deadlineDate' | translate }}</p></ion-row
      >
      <ion-row class="ion-align-items-center"
        ><div class="event event--absent"></div>
        <p class="legend-text">{{ 'APPRAISALS_absent' | translate }}</p></ion-row
      >
    </ion-row>
    <ion-row class="calendar-month ion-justify-content-center">
      <ion-col size="2" (click)="changeMonth.emit(_currentMonth - 1)"><ion-icon name="arrow-back"></ion-icon></ion-col>
      <ion-col size="8"> {{ _currentMonth + 1 | enumToString : 'MonthsEnum' | translate }} {{ _currentYear }}</ion-col>
      <ion-col size="2" (click)="changeMonth.emit(_currentMonth + 1)"><ion-icon name="arrow-forward"></ion-icon></ion-col>
    </ion-row>
  </ion-grid>
</div>
<div class="calendar-body">
  <ion-grid>
    <ion-row class="calendar-weekday">
      <ion-col *ngFor="let day of _INITIAL_WEEK">{{ day }}</ion-col>
    </ion-row>
    <div class="calendar-date">
      <div
        *ngFor="let item of _calendar"
        [ngClass]="{ 'is-event': item.hasVisits || item.hasExpiredDeadlines || item.absent }"
        class="calendar-date-item {{ item.class }}"
        (click)="calendarDetail.emit(item)"
        (click)="presentCalendarInfo($event, item)"
      >
        <span class="item-number">{{ item.day }}</span>
        <div *ngIf="item.hasVisits || item.hasExpiredDeadlines || item.absent" class="events">
          <div *ngIf="item.hasVisits" class="event event--visit"></div>
          <div *ngIf="item.hasExpiredDeadlines" class="event event--limit"></div>
          <div *ngIf="item.absent" class="event event--absent"></div>
        </div>
      </div>
    </div>
  </ion-grid>
</div>
