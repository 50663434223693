<form id="password-change" class="example-container" [formGroup]="temporalPasswordChangeForm" (ngSubmit)="changeTemporalPassword()">
  <a class="logo-container" [routerLink]="['/']">
    <img src="/assets/images/logo.png" />
  </a>
  <h1>{{ 'CHANGE_TEMPORAL_PASSWORD_changeTemporalPassword' | translate }}</h1>
  <span [ngStyle]="{ width: !mobileQuery ? '25%' : '100%' }">{{ 'CHANGE_TEMPORAL_PASSWORD_yourPasswordIsTemporal' | translate }}</span>
  <div class="input-container mb-3">
    <ui-form-input-password
      [componentId]="'user-pass'"
      [label]="'CHANGE_TEMPORAL_PASSWORD_newPassword' | translate"
      [form]="temporalPasswordChangeForm"
      [controlName]="'password'"
    ></ui-form-input-password>
  </div>

  <div class="input-container mt-4">
    <ui-form-input-password
      [componentId]="'repeat-user-pass'"
      [label]="'CHANGE_TEMPORAL_PASSWORD_repeatPassword' | translate"
      [form]="temporalPasswordChangeForm"
      [controlName]="'confirmPassword'"
    ></ui-form-input-password>
  </div>
  <div class="login-input-button">
    <ui-async-button
      [inputState]="changeTemporalPasswordButtonState"
      componentId="AT_CHANGE_TEMPORAL_PASSWORD_change"
      [rounded]="true"
      [type]="'submit'"
      [asyncButton]="true"
      [buttonClassByType]="'primary'"
      [size]="'lg'"
      [label]="'CHANGE_TEMPORAL_PASSWORD_change' | translate"
      [loadingLabel]="'CHANGE_TEMPORAL_PASSWORD_change' | translate"
      [disabled]="temporalPasswordChangeForm.invalid"
      (clickEvent)="changeTemporalPassword()"
    ></ui-async-button>
  </div>
</form>
