import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { BaseFormMobileComponent } from '../../../base-components';
import { FormCalendarMobileIcons } from '../form-input-calendar.types';

@Component({
  selector: 'ui-mobile-form-calendar',
  templateUrl: 'form-calendar.component.html',
  styleUrls: ['form-calendar.component.scss'],
})
export class FormCalendarComponent extends BaseFormMobileComponent implements OnInit {
  @Input() subLabel: string;

  @Input() minDate: string;
  @Input() maxDate: string;

  @Input() icon: FormCalendarMobileIcons | string = FormCalendarMobileIcons.CALENDAR;
  @Input() pickerFormat: 'date' | 'date-tieme' | 'month' | 'month-year' | 'time' | 'time-date' | 'year' = 'date';
  @Input() displayFormat: 'dd/MM/yyyy' | 'HH:mm' | 'dd/MM/yyyy HH:mm' | 'YYYY' = 'dd/MM/yyyy';
  @Input() canReset = true;
  @Input() showTime = false;

  @ViewChild(IonModal) modal: IonModal;

  openDate() {
    this.modal.present();
  }

  async dismiss($event) {
    const value = $event.detail.value;
    // console.log(value, new Date(value), new Date(value).toISOString())
    if (value) this.control.setValue(value);
    // this.modal.dismiss();
  }
}
