import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MergedRouterStateSerializer, reducers } from './redux';
import { UrlDataService } from './services/url-data.service';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forRoot(),
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot({
      serializer: MergedRouterStateSerializer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      connectInZone: true,
    }),
  ],
  providers: [UrlDataService],
})
export class NewCoreModule {}
