import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@foxeet/feature/auth';
import { ModalController } from '@ionic/angular';
import { ContactUsModalComponent } from '../../modals/contact-us-modal/contact-us-modal.component';

@Component({
  selector: 'mcore-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
})
export class MenuFooterComponent {
  public defaultLanguage = 'es';

  @Input() email: string;
  @Input() showContactButton: boolean = false;

  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private _router: Router, private _authService: AuthService, private _modalController: ModalController) {}

  public async contactUs() {
    const modal = await this._modalController.create({
      component: ContactUsModalComponent,
      cssClass: 'contact-us-modal-class',
      componentProps: { email: this.email },
    });
    modal.present();
  }

  public logOut() {
    this.closeMenu.emit(true);
    this._authService.logOut();
    this._router.navigate(['login']);
  }
}
