import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ComparablesService } from './comparables.service';

/**
 * Utilizar sólo para el endpoint de ListPagedDistanceBetweenPoint hasta que en back lo simplifiquen a un solo endpoint
 */
@Injectable()
export class ComparablesByDistanceService extends ComparablesService {
  public override listPageByFilter<T>(page: number, size: number, body: any): Observable<T> {
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('pagesize', `${size}`);
    return this.post<typeof body, T>(`ListPagedDistanceBetweenPoint`, body, { observe: 'response', params });
  }
  public override listPageByFilterExternal<T>(page: number, size: number, body: any): Observable<T> {
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('pagesize', `${size}`);
    return this.post<typeof body, T>(`GetComparableFromIdealista`, body, { observe: 'response', params });
  }
}
