import { Pipe, PipeTransform } from '@angular/core';
import { backendEnums as enumsB, frontendEnums as enumsA } from '@foxeet/domain';
import { isNil } from '@foxeet/utils/functions/javascript.functions';

@Pipe({ name: 'enumToString' })
export class EnumToStringPipe implements PipeTransform {
  enums = { ...enumsA, ...enumsB };

  transform(value: number, enumType: any): string {
    return !isNil(value) ? `${enumType}_${value}` : '';
  }
}
