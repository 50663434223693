import { Observable } from 'rxjs';
import { dynamicInjector } from './dynamic-injector';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

export function getFileFromPath(url: string): Observable<Blob> {
  const httpClient = dynamicInjector().get(HttpClient);
  const options = { responseType: 'blob' as 'json' };
  return httpClient.get<Blob>(url, options);
}

export function downloadExcel(blob: Blob, title?: string) {
  const fileName = `${title?.trim()}_${new Date().toLocaleDateString().split('/').join('_')}`;
  downloadFile(blob, fileName);
}

export function downloadDocument(blob: Blob, fileName: string) {
  const uri = window.URL.createObjectURL(blob);
  window.open(uri, fileName, 'toolbar=1,location=1,directories=1,status=1,menubar=1,scrollbars=1,resizable=1');
}

export function getURI(blob: Blob) {
  return window.URL.createObjectURL(blob);
}

export const downloadFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  downloadUrlContent(url, fileName);
};

export const downloadUrlContent = (url: string, fileName?: string) => {
  const a = document.createElement('a');
  a.setAttribute('style', 'diplay: none');
  document.body.appendChild(a);
  a.href = url;
  if (fileName) a.download = fileName;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 1000);
};

export const downloadFileMobile = (blob, fileName) => {
  const _platform = dynamicInjector().get(Platform);
  const _file = dynamicInjector().get(File);
  const _fileOpener = dynamicInjector().get(FileOpener);
  const folderpath = _platform.is('android') ? _file.externalCacheDirectory : _file.cacheDirectory;

  _file
    .writeFile(folderpath, fileName, blob, { replace: true })
    .then((fileEntry) => {
      _fileOpener
        .open(fileEntry.toURL(), blob.type)
        .then(() => {
          return;
        })
        .catch((err) => {
          console.error(err);
        });
    })
    .catch((err) => {
      throw err;
    });
};
