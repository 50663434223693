import { Component, Input } from '@angular/core';
import { DateDisplayModel } from '../../domain/date-display.model';

/**
 * @TODO MOVE TO UI LIB
 */
@Component({
  selector: 'mcore-date-display',
  templateUrl: './date-display.component.html',
  styleUrls: ['./date-display.component.scss'],
})
export class DateDisplayComponent {
  @Input() dateDisplay: DateDisplayModel;
}
