import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import {
  AppraisalFileIndexModel,
  AppraisalImplementationFileDocumentTypes,
  AppraisalReportAssetFileFilterModel,
  FileTypes,
  OrNull,
  WorkflowItemAssetFileModelBase,
  WorkflowItemReportAssetFileChangeOrderModel,
} from '@foxeet/domain';
import { Observable } from 'rxjs';
import { removeNullsAndUndefined } from '../utils/data-access.utils';
import { isNil } from '../to-delete/bucket';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class AppraisalReportAssetFilesService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalReportAssetFiles', cache);
  }

  public editDocument(id: number, body: any): Observable<any> {
    return this.put<typeof body, void>(`${id}/Files`, body);
  }

  public editPhotoData(id: number, body: WorkflowItemAssetFileModelBase): Observable<any> {
    const data = removeNullsAndUndefined(body);
    return this.put<typeof data, void>(`${id}/Files`, data);
  }

  public deletePhotos(ids: number[]): Observable<any> {
    return this.post(`DeleteMultiple`, ids);
  }

  public download(id: number): Observable<Blob> {
    return this.get(`${id}/Download`, {
      responseType: 'blob',
    });
  }

  public createMultiplePhoto(file: Blob, appraisalAssets: { id: number; isPrintable: boolean }[], name: string, description: string): Observable<any> {
    const body = new FormData();
    body.append('File', file);
    body.append('Name', JSON.stringify(name));
    body.append('Description', JSON.stringify(description));
    appraisalAssets.forEach((el, i) => {
      body.append(`AppraisalAssets[${i}].Id`, `${el.id}`);
      body.append(`AppraisalAssets[${i}].IsPrintable`, `${!isNil(el.isPrintable) ? el.isPrintable : true}`);
    });

    return this.postMultipartFile<any>(`Pictures`, body);
  }

  public createMultipleDocument(
    file: Blob,
    appraisalDocumentType: AppraisalImplementationFileDocumentTypes,
    appraisalAssets: { id: number; isPrintable: boolean }[],
    obtainedDatetime: OrNull<Date>,
  ): Observable<any> {
    const body = new FormData();
    body.append('File', file);
    body.append('FileType', `${FileTypes.Documents}`);
    body.append('AppraisalDocumentType', JSON.stringify(appraisalDocumentType));
    appraisalAssets.forEach((el, i) => {
      body.append(`AppraisalAssets[${i}].Id`, `${el.id}`);
      body.append(`AppraisalAssets[${i}].IsPrintable`, `${!isNil(el.isPrintable) ? el.isPrintable : true}`);
    });

    if (obtainedDatetime) body.append('ObtainedDatetime', `${obtainedDatetime.toISOString()}`);

    return this.postMultipartFile<any>(`Documents`, body);
  }

  setIsPrintableById(id: number, isPrintable: boolean) {
    const body = removeNullsAndUndefined({ fileId: id, isPrintable });
    return this.put<typeof body, void>(`${id}/Printable`, body);
  }

  setIsPrintableMassive(body: { fileId: number; isPrintable: boolean }[]) {
    const data = removeNullsAndUndefined(body);
    return this.put<typeof data, void>(`PrintableMassive`, data);
  }

  setIsMainImageInReport(id: number, isMainImageInReport: boolean) {
    const body = removeNullsAndUndefined({ fileId: id, isMainImageInReport });
    return this.put<typeof body, void>(`${id}/MainImage`, body);
  }

  public getPagedFunction() {
    return (page: number, pagesize: number, filter: any, endpoint = 'ListPaged'): Observable<any> => {
      return this.getAppraisalAssetDocuments(filter);
    };
  }

  public getAppraisalAssetDocuments(body: any): Observable<AppraisalFileIndexModel[]> {
    body = removeNullsAndUndefined(body);
    return this.post<typeof body, AppraisalFileIndexModel[]>('ListAll', body);
  }

  public orderPhotos(body: WorkflowItemReportAssetFileChangeOrderModel) {
    return this.put<typeof body, void>('Order', body);
  }

  downloadAppraisalFiles(body: AppraisalReportAssetFileFilterModel) {
    return this.http.post(`${this.apiBranch}/ZipAllFiles`, body, { observe: 'response', responseType: 'blob' });
  }
}
