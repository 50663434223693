import { Component, Input, OnInit } from '@angular/core';
import { ToastrWrapperService, UnsuscriptionHandler, getFileFromPath, getURI } from '@foxeet/utils/services';
import { UserProfileModel } from './domain/user-profile.model';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'mcore-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends UnsuscriptionHandler implements OnInit {
  @Input() user: UserProfileModel;

  constructor(private _toastWrapper: ToastrWrapperService) {
    super();
  }

  ngOnInit(): void {
    getFileFromPath(this.user.image)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((val) => {
        this.user.image = getURI(val);
      });
  }

  copyCode() {
    this._toastWrapper.success('NETWORK.codeCopied');
  }
}
