<div
  class="material-drawer"
  [style.maxHeight.px]="expandablePanel && expandablePanel.opened ? 1000 : 0"
  [ngClass]="{ opened: expandablePanel.opened, closed: !expandablePanel.opened }"
>
  <div class="material-drawer-inner">
    <div class="material-drawer-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
