import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppraisalReportMarketStudyEditModel, AppraisalReportMarketStudyItemModel, AssetTabModel, InitialDataForMarketStudyModel } from '@foxeet/domain';
import { BehaviorSubject, Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class MarketStudyReportService extends WorkflowService {
  marketStudyDetails$ = new BehaviorSubject<any>(null);

  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalReportMarketStudy', cache);
  }

  public createStudyMarket(body: AppraisalReportMarketStudyItemModel): Observable<AppraisalReportMarketStudyItemModel> {
    return this.post<AppraisalReportMarketStudyItemModel, AppraisalReportMarketStudyItemModel>('', body);
  }

  public deleteStudyMarket(id: number) {
    return this.delete(`${id}`);
  }

  public marketStudyListPaged<T>(page: number, size: number, body: any): Observable<T> {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    return this.post<typeof body, T>(`ListPaged`, body, { params });
  }
  public AppraisalReportMarketStudyListAll<T>(body: any): Observable<T> {
    return this.post<typeof body, T>(`ListAll`, body);
  }

  public marketStudyInformation(id: number) {
    return this.get(`${id}`);
  }

  public getMarketSudyInformationDetails(id: number) {
    this.marketStudyInformation(id).subscribe((marketInformation) => this.marketStudyDetails$.next(marketInformation));
  }

  public editMarketStudy(body: AppraisalReportMarketStudyEditModel): Observable<AssetTabModel<AppraisalReportMarketStudyEditModel>> {
    return this.put<AppraisalReportMarketStudyEditModel, AssetTabModel<AppraisalReportMarketStudyEditModel>>('', body);
  }

  public calculateMarketStudy(body: AppraisalReportMarketStudyEditModel) {
    return this.post<AppraisalReportMarketStudyEditModel, AppraisalReportMarketStudyEditModel>('Calculate', body);
  }

  public getInitialDataForMarketStudy(reportId: number) {
    return this.get(`AssetsDataForMarketStudy`, {
      params: this.setParams({
        reportId,
      }),
    });
  }
}
