import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

import { BACK_CONFIG, BackConfig, DataAccessModule } from '@foxeet/data-access';
import { LanguageFile, LanguageSelectorList, MapConfig } from '@foxeet/domain';
import { MobileCoreModule } from '@foxeet/mcore';
import { NewCoreModule } from '@foxeet/n-core';
import { ContactConfig } from '@foxeet/utils/interfaces';
import { ConfigLoaderModule, I18nModule } from '@foxeet/utils-modules';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { enLang } from '../assets/i18n/en';
import { esLang } from '../assets/i18n/es';
import { ptLang } from '../assets/i18n/pt';
import { authoritiesDefinition } from '../config/authorities';
import { contactConfig } from '../config/contact.config';
import { mapConfig } from '../config/map.config';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastWrapperService } from '@foxeet/utils/services/toast-wrapper.service';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { BehaviorSubject, merge } from 'rxjs';
import { CultureChestModule, CultureSelectors } from '@foxeet/chests/culture';
import { LocationChestModule } from '@foxeet/chests/location';
import { UtilsChestModule } from '@foxeet/chests/utils-chests';
import { LoadingService } from '@foxeet/utils/services/loading.service';
import { LOGGER_CONFIG, LoggerService } from '@foxeet/utils/services/logger.service';
import { AVAILABLE_LANGUAGES } from '../config/language-selector.config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypologyChestModule } from '@foxeet/chests/typology';
import { MarketStudyChestModule } from '@foxeet/chests/market-study';

registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');

const chests = [CultureChestModule, LocationChestModule, UtilsChestModule, TypologyChestModule, MarketStudyChestModule];

/**
 * BACK CONFIGURATION
 */

const DEFAULT_LANG_FILE: LanguageFile = esLang;

const backConfig: BackConfig = {
  baseUrl: new BehaviorSubject<string>(environment.defaultEndpoint),
  lang: new BehaviorSubject<string>(esLang.languageKey),
  platform: environment.platform,
};

const LANGUAGES_FILES: LanguageFile[] = [esLang, enLang, ptLang];

@NgModule({
  declarations: [AppComponent],
  imports: [
    chests,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      animated: true,
      rippleEffect: true,
      mode: 'ios',
      backButtonIcon: 'chevron-back-outline',
      backButtonText: '',
      loadingSpinner: 'dots',
      sanitizerEnabled: true,
      statusTap: true,
      swipeBackEnabled: true,
      innerHTMLTemplatesEnabled: true,
    }),
    ConfigLoaderModule.forRoot<LanguageSelectorList, { map: MapConfig; contact: ContactConfig }>({
      environment,
      languagesConfig: {
        defaultLanguage: esLang.languageKey,
        availableLanguages: AVAILABLE_LANGUAGES,
      },
      data: {
        map: mapConfig,
        contact: contactConfig,
      },
      authorities: authoritiesDefinition,
    }),
    AppRoutingModule,
    MobileCoreModule,
    I18nModule.forRoot(),
    DataAccessModule,
    OverlayModule,
    NewCoreModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: BACK_CONFIG,
      useValue: backConfig,
    },
    {
      provide: LOGGER_CONFIG,
      useValue: { isEnabled: !environment.production, isProduction: environment.production },
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ToastrWrapperService, useClass: ToastWrapperService },
    File,
    FileOpener,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _translateService: TranslateService,
    private store: Store,
    @Inject(BACK_CONFIG) private backConfig: BackConfig,
    private readonly logger: LoggerService,
    private readonly loading: LoadingService,
  ) {
    LANGUAGES_FILES.forEach((language) => this._translateService.setTranslation(language.languageKey, language.translates, true));
    this._translateService.use(DEFAULT_LANG_FILE.languageKey);
    this.store.select(CultureSelectors.LanguageCode).subscribe((langCode) => {
      const lang = AVAILABLE_LANGUAGES.find((l) => l.cultureCodeLanguage === langCode)?.lang || langCode;
      this.backConfig.lang.next(lang);
    });
    merge(this.loading.onCreateSpinner, this.loading.onDismissSpinner).subscribe((event) => {
      event.error ? this.logger.error(event.message, event.error) : this.logger.info(event.message);
    });
  }
}
