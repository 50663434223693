import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AppraisalReportMarketStudyItemModel, AssetTabInitialDetailsModel, EnumModel, EnumsModel, FormModel, ReducerByTabInitialDetailsFilter } from '@foxeet/domain';
import { PrimeNgDropdownItem } from '../interfaces';
import { isNil, isNilOrEmptyString } from './javascript.functions';

export class FormUtils {
  static toPrimeNgDropdownValue(enumerator: { [x: string]: any }, enumName?: string): PrimeNgDropdownItem[] {
    const keys = Object.keys(enumerator).filter((key) => isNaN(+key));
    return keys.map((key) => ({
      value: FormUtils.isBoolean(enumerator[key]) ? FormUtils.transformBooleanValue(enumerator[key]) : enumerator[key],
      label: `${enumName || key}_${enumerator[key]}`,
    }));
  }

  static toPrimeNgMultiselectorValue(items: any[], indexBy: string | number, labelKeysToJoin = []) {
    // Const variable should be declared to solve ng build @iv/core --prod error.
    // Metadata collected contains an error that will be reported at runtime: Lambda not supported.
    // https://stackoverflow.com/questions/57594723/angular-metadata-collected-contains-an-error-that-will-be-reported-at-runtime
    // @dynamic https://angular.io/guide/angular-compiler-options#strictmetadataemit
    const multiselectorData = items.map((option) => ({
      label: labelKeysToJoin.map((key) => option[key]).join(' '),
      value: option[indexBy],
      fullItem: option,
    }));
    return multiselectorData;
  }

  static fromEnumToKeyValueArray(enumerator: { [x: string]: any }, enumName?: string): EnumsModel[] {
    const keys = Object.keys(enumerator).filter((key) => isNaN(+key));
    return keys.map((key) => ({
      key: FormUtils.isBoolean(enumerator[key]) ? FormUtils.transformBooleanValue(enumerator[key]) : enumerator[key],
      value: key,
      translateKey: `${enumName || key}_${enumerator[key]}`,
    }));
  }

  static fromEnumKeyToTranslateKey(enumerator: Record<string, number | string>, enumName: string | undefined, enumKey: string | number): string {
    const el = FormUtils.fromEnumToKeyValueArray(enumerator, enumName).find((element) => element.key === enumKey);
    return el?.translateKey ?? '';
  }

  static fromArrayKeyToTranslateKey(data: AppraisalReportMarketStudyItemModel) {
    return {
      value: data,
      label: data.name,
    };
  }

  static isBoolean(value: string) {
    const booleanValues = ['true', 'false', 'True', 'False', 'TRUE', 'FALSE'];
    return booleanValues.includes(value);
  }

  static transformBooleanValue(value: string) {
    const trueValues = ['true', 'True', 'TRUE'];
    return trueValues.includes(value);
  }

  static fromEnumToLabelValue(enumerator: { [x: string]: any }, enumName?: string) {
    const keys = Object.keys(enumerator).filter((key) => isNaN(+key));
    return keys.map((key) => ({ label: `${enumName || key}_${enumerator[key]}`, value: enumerator[key] }));
  }

  static removeNullProperties<T = { [x: string]: unknown }>(obj: T): T {
    (Object.keys(obj) as (keyof T)[]).forEach((key) => {
      const value = obj[key];
      const hasProperties = value && Object.keys(value).length > 0;
      if (value === null) {
        delete obj[key];
      } else if (typeof value !== 'string' && hasProperties) {
        this.removeNullProperties(value);
      }
    });
    return obj;
  }

  static isEmpty(value: string) {
    return isNilOrEmptyString(value);
  }

  static setFormFieldsVisibility(formModel: any[][], formFields: string[], hide: boolean) {
    formModel.forEach((elementList) => {
      elementList.forEach((el) => (el.isHidden = formFields.includes(el.id) ? hide : el.isHidden));
    });
  }

  static setFormListFieldsAsRequired(form: FormGroup, fields: string[]) {
    fields.forEach((field) => {
      const control = form.get(field);
      control?.setValidators(Validators.required);
      control?.updateValueAndValidity({ emitEvent: false });
    });
  }

  static setFormListFieldsValidators(form: FormGroup, fields: string[], nameFields = [''], validator: ((control: AbstractControl) => ValidationErrors | null)[]) {
    const ValidatorDefault = [Validators.required];
    fields.forEach((field) => {
      const control = form.get(field);
      control?.setValidators(nameFields.includes(field) ? validator : ValidatorDefault);
      control?.updateValueAndValidity({ emitEvent: false });
    });
  }

  static clearFormListFieldsValidators(form: FormGroup, fields: string[], shouldReset = false) {
    fields.forEach((field) => {
      const control = form.get(field);
      if (shouldReset) control?.reset();
      control?.clearValidators();
      control?.updateValueAndValidity({ emitEvent: false });
    });
  }

  static resetFormListFields(form: FormGroup, fields: string[], emitEvent = true) {
    fields.forEach((field) => form.get(field) && form.get(field)?.reset(null, { emitEvent }));
  }

  static clearValidatorsAndResetFormListFields(form: FormGroup, fields: string[]) {
    this.clearFormListFieldsValidators(form, fields, true);
  }

  static customErrorDependsOnAField = (control: AbstractControl, errorKey: string, errorTranlateKey: string): ValidatorFn => {
    return (): { [key: string]: any } | null => {
      const correctValue = !(isNil(control.value) || control.value === 0);

      return correctValue ? null : { [errorKey]: errorTranlateKey };
    };
  };

  // TODO: el valor que devuelve la función es de tipo DropdownItemModel<EnumModel>[] pero existe dependencia circular con la librería ui
  static fromEnumModelToPrimengModel(data: EnumModel[]): any[] {
    return data && data.map((el) => ({ label: el.translateKey, value: el.key, fullItem: el }));
  }

  static updateValueAndValidityOfForm(form: FormGroup): void {
    Object.keys(form.controls).forEach((key) => {
      if (isNil(form.controls[key]?.value)) {
        form.controls[key]?.updateValueAndValidity();
        form.controls[key]?.markAsTouched();
      }
    });
  }

  static toggleformListEnable(form: FormGroup, fields: string[], enable: boolean) {
    fields.forEach((field) => {
      if (enable) {
        form.controls[field]?.enable();
      } else {
        form.controls[field]?.disable();
      }
      form.controls[field]?.updateValueAndValidity();
    });
  }
}

export type FormControlServiceModel = FormModel;

export const suggestedFields = (formConfig: FormModel[]) => {
  const suggested: Record<string, boolean> = {};
  formConfig.map((c) => (suggested[c.name] = c.suggested ?? false));
  return suggested;
};

export const toggleRequiredFormControls = (form: FormGroup, controlName: string, required: boolean) => {
  const control = form.get(controlName);
  if (control) {
    required ? control.setValidators(Validators.required) : control.clearValidators();
    control.updateValueAndValidity();
  }
};

export const resetField = (form: FormGroup, formControlName: string) => {
  const fc = form.get(formControlName);
  fc && fc.reset();
};

export const requiredFieldsValidatorFnByTabInitialDetail =
  (requiredFields: Record<string, (args: AssetTabInitialDetailsModel) => boolean>, initialTabValues: AssetTabInitialDetailsModel): ValidatorFn =>
  (form: AbstractControl): ValidationErrors | null => {
    const requiredFieldsCompleted = Object.keys(requiredFields).reduce((acc, curr) => {
      const control = form.get(curr);
      const isRequired = requiredFields[curr](initialTabValues);
      control?.setErrors(isRequired && isNil(control.value) ? { required: true } : null);
      if (!isRequired) control?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      return acc && !!control?.valid;
    }, true);
    return requiredFieldsCompleted ? null : { requiredFields: true };
  };

export const getRequiredFieldNamesByTabInitialDetail = (
  requiredFields: Record<string, (args: AssetTabInitialDetailsModel) => boolean>,
  initialTabValues: AssetTabInitialDetailsModel,
) => Object.keys(requiredFields).filter((field) => requiredFields[field](initialTabValues));

/**
 *
 * @param fields Control names del formulario
 * Reducer para obtener un objeto de validación en base a un listado de campos a los que se les aplica
 * las mismas condiciones de validación. Este objeto está representado por la clave que sería el controlName y el value que
 * sería la función validadora, devolviendo true en caso de ser un campo requerido.
 */
export const reducerByTabInitialDetails = (
  fields: string[],
  { requesters, uses: filterUses, typologies: filterTypologies }: ReducerByTabInitialDetailsFilter,
): Record<string, (args: AssetTabInitialDetailsModel) => boolean> =>
  fields.reduce(
    (acc, field) => ({
      ...acc,
      [field]: ({ uses, typologies, validationType }: AssetTabInitialDetailsModel) =>
        (!requesters?.length || requesters.includes(validationType)) &&
        (!filterUses?.length || uses?.some((u) => filterUses?.includes(u))) &&
        (!filterTypologies?.length || typologies?.some((t) => filterTypologies?.includes(t))),
    }),
    {},
  );

export const setRequiredFieldsByConfig = (form: FormGroup, validationObject: Record<string, (args: AssetTabInitialDetailsModel) => boolean>, data: AssetTabInitialDetailsModel) => {
  Object.entries(validationObject).forEach(([field, validationFn]) => {
    const control = form.get(field) as FormControl;
    if (control) {
      addOrRemoveRequiredValidator(control, validationFn(data));
    } else {
      throw Error(`Field (${field}) does not exist in form`);
    }
  });
};

export const addOrRemoveRequiredValidator = (control: AbstractControl | null, isRequired: boolean) => {
  if (control) {
    isRequired ? control.addValidators(Validators.required) : control.removeValidators(Validators.required);
    control.updateValueAndValidity({ emitEvent: false });
  } else {
    console.error(`Control does not exist`, control);
  }
};

/* MOBILE PURPOSE */
export const mapDateFields = (data: any, includeFields: string[], excludeFields: string[] = []) => {
  const mappedData = { ...data };
  Object.keys(mappedData).forEach((key) => {
    if (!excludeFields.includes(key)) {
      if (key.toLocaleLowerCase().includes('datetime') || includeFields.includes(key)) {
        mappedData[key] = mappedData[key] ? new Date(mappedData[key]).toISOString() : null;
      } else if (includeFields.includes(key) && key.toLocaleLowerCase().includes('year')) {
        // console.log('year', mappedData[key], new Date().setFullYear(mappedData[key]))
        const newDate = new Date().setFullYear(mappedData[key]);
        mappedData[key] = mappedData[key] ? new Date(newDate).toISOString() : null;
      }
    }
  });

  return mappedData;
};

export const mapDateWebFields = (data: any, includeFields: string[], excludeFields: string[] = []) => {
  const mappedData = { ...data };
  Object.keys(mappedData).forEach((key) => {
    if (!excludeFields.includes(key)) {
      if (key.toLocaleLowerCase().includes('datetime') || includeFields.includes(key)) {
        mappedData[key] = mappedData[key] ? new Date(mappedData[key]) : null;
      } else if (includeFields.includes(key) && key.toLocaleLowerCase().includes('year')) {
        const newDate = new Date().setFullYear(mappedData[key]);
        mappedData[key] = mappedData[key] ? new Date(newDate) : null;
      }
    }
  });

  return mappedData;
};

export const validateNumericRange = (min: number, max: number): ValidatorFn => {
  return (control: AbstractControl): { numericRange: boolean; valueRange: { min: number; max: number } } | null => {
    const value = control.value;

    if (value !== null && (isNaN(value) || value <= min || value > max)) {
      return {
        numericRange: true,
        valueRange: {
          min: min,
          max: max,
        },
      };
    }

    return null;
  };
};
