import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateDisplayState } from '../../domain';
import { ChipModel } from '@foxeet/ui';

export interface CardActions {
  label: string;
  size: { xs?: number; sm?: number; md?: number; lg?: number };
  type?: 'link' | 'primary';
  icon?: string;
  callback: (el?: any) => void;
}

export interface CardAddress {
  province?: string;
  fullAddressGeneratedName: string;
}

export interface CardDate {
  label: string;
  date: string;
  icon: string;
  state: DateDisplayState;
}

export interface CardItem {
  data: any;
  title?: string;
  image?: string;
  actions?: CardActions[];
  address?: CardAddress;
  leftChip?: ChipModel;
  rightChip?: ChipModel;
  rightBottomChip?: ChipModel;
  dates?: CardDate[];
  color?: string;
}

/**
 * @TODO MOVE TO UI LIB
 */
@Component({
  selector: 'mcore-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  public onerror = false;

  @Input() componentId: string | number | undefined;
  @Input() cardItem: CardItem;
  @Output() cardClicked: EventEmitter<any> = new EventEmitter();

  public getOnErrorImgPath() {
    this.onerror = true;
    this.cardItem.image = './assets/img/default-map-world.png';
  }
}
