import { BillingTaxTypes, FloorTypes, StreetTypes } from '../enums';

export enum CustomerBillingMode {
  DirectPayment = 1,
}
export interface CustomerFilterModel {
  Summary: string;
  ActivatedFiltersCount: number;
  OrderFieldName: string;
  OrderFieldAsc: boolean;
  PossibleOrderFieldNames: string[];
  CustomerId: number;
  IsCompany: boolean;
  Name: string;
  NationalIdentityDocument: string;
  AddedMaxDateTime: Date;
  AddedMinDateTime: Date;
}

// BANKIA PURPOSE
export interface CustomerItemModel {
  Observations: string;
  LastUpdatedDateTime: Date;
  LastUpdatedUserId: number;
  CustomerId: number;
  Name: string;
  FirstName: string;
  LastName: string;
  NameSearchPattern: string;
  NationalIdentityDocument: string;
  ContactEmail: string;
  ContactPhone: string;
  ThumbnailFileWebPath: string;
  AddedDateTime: Date;
  AddedUserId: number;
  IsCompany: boolean;
  RequesterCompanyId: number;
}

export interface CustomerEditBillingDataModel {
  id: number;
  legalName: string;
  nationalIdentityDocument: string;
  bankAccount: string;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  block: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  automaticInvoicing: boolean;
}

//////////////////////////////////////////////////
// Nuevos modelos

export interface CustomerModel {
  id: number;
  isCompany: boolean;
  fullName: string; // name: string;
  firstName: string;
  lastName: string;
  nationalIdentityDocument: string;
  email: string;
  phone: string;
  observations: string;
  nameSearchPattern: string;
  profileImageFileName: string;
  profileImageFileWebPath: string;
}

export interface CustomerIndexModel {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  nationalIdentityDocument: string;
  email: string;
  phone: string;
  addedDateTime?: string;
}

export interface CustomerEditModel {
  id: number;
  isCompany: boolean;
  name: string;
  firstName: string;
  lastName: string;
  nationalIdentityDocument: string;
  email: string;
  phone: string;
  observations: string;
}

export interface CustomerUserIndexModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  customerName: string;
  requesterCompanyName: string;
}
