import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiPropertyRegister,
  ConditionWarningEnumModel,
  CountryConfigurationEnumModel,
  CurrencyEnumModel,
  EnumsModel,
  KeyValueModel,
  ReportTypes,
  WorkTypes,
} from '@foxeet/domain';
import { Observable } from 'rxjs';
import { ApiBaseService2 } from '../services/api-base.service';
import { CacheService } from '../services/cache.service';
import { isNil } from 'lodash';

@Injectable()
export class EnumsService extends ApiBaseService2 {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Enums', cache);
  }

  getAppraisalDocumentTypes(workType?: number): Observable<EnumsModel[]> {
    if (!isNil(workType)) {
      const params: HttpParams = new HttpParams().append('workType', workType);
      return this.get<EnumsModel[]>('AppraisalDocumentTypes', { params });
    } else {
      return this.get<EnumsModel[]>('AppraisalDocumentTypes');
    }
  }

  getCountryConfigurations(): Observable<CountryConfigurationEnumModel[] | null> {
    return this.get<CountryConfigurationEnumModel[]>('CountryConfigurations');
  }

  getEntityTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('EntityTypes');
  }

  getObservationsTypes(): Observable<ConditionWarningEnumModel[]> {
    return this.get<ConditionWarningEnumModel[]>('Observations');
  }

  getPrescriptorTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('PrescriptorTypes');
  }

  getValidationAppraisalRegisteredPropertyValidationTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('GetValidationAppraisalRegisteredPropertyValidationTypes');
  }

  getAssetStates(assetInstruction: number) {
    const params: HttpParams = new HttpParams().append('assetInstruction', assetInstruction);
    return this.get<EnumsModel[]>('AssetStates', { params });
  }

  getAssetUses(assetInstruction: number) {
    const params: HttpParams = new HttpParams().append('assetInstruction', assetInstruction);
    return this.get<EnumsModel[]>('AssetUses', { params });
  }

  getAssetTypologies(assetUse: number) {
    const params: HttpParams = new HttpParams().append('assetUse', assetUse);
    const expires = new Date();
    expires.setHours(expires.getHours() + 2);
    return this.get<EnumsModel[]>('AssetTypologies', { params }, { expires });
  }
  getAssetTypologiesList(uses: number[]) {
    return this.post<unknown, EnumsModel[]>('AssetTypologiesList', uses?.length ? uses : []);
  }

  getAssetSubTypologies(assetTypology: number) {
    const params: HttpParams = new HttpParams().append('assetTypology', assetTypology);
    const expires = new Date();
    expires.setHours(expires.getHours() + 2);
    return this.get<EnumsModel[]>('AssetSubTypologies', { params }, { expires });
  }
  getAssetSubTypologiesList(typologies: number[]) {
    return this.post<unknown, EnumsModel[]>('AssetSubTypologiesList', typologies?.length ? typologies : []);
  }

  getRegisteredPropertyValidationTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('GetValidationAppraisalRegisteredPropertyValidationTypes');
  }

  getLegalSituationTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('LegalSituationTypes');
  }

  getConservationStateTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('ConservationStateTypes');
  }

  getBuildingQualityTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('BuildingQualityTypes');
  }

  getEnergyCertificationGrades(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('EnergyCertificationGrades');
  }

  getDocumentationTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('AppraisalDocumentTypes');
  }

  getPanoramaCodes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('PanoramaCodes');
  }

  getFacilitiesTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('FacilitiesTypes');
  }

  getFacilityLocationTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('FacilityLocationTypes');
  }

  getAppraisalPurposesTypes(orderType?: number, currentValue?: number) {
    let params: HttpParams;

    if (!isNil(currentValue) && !isNil(orderType)) {
      params = new HttpParams({
        fromObject: {
          appraisalOrderType: orderType,
          currentValue,
        },
      });
    }
    if (isNil(currentValue) && !isNil(orderType)) {
      params = new HttpParams({
        fromObject: {
          appraisalOrderType: orderType,
        },
      });
    }

    return this.get<EnumsModel[]>('AppraisalPurposes', { params });
  }

  getAppraisalOrderTypesByWorkType(workType: number) {
    let params: HttpParams = new HttpParams();
    if (!isNil(workType)) params = params.append('workType', workType);
    return this.get<EnumsModel[]>('AppraisalOrderTypesByWorkType', { params });
  }

  getVinculationTypesByWorkType(workType: number) {
    const params: HttpParams = new HttpParams().append('workType', workType);
    return this.get<EnumsModel[]>('AppraisalVinculationByWorkType', { params });
  }

  getRequestOrderTypes() {
    return this.get<EnumsModel[]>('OrderRequestOrderTypesByWorkType');
  }

  getRequestOrderTypesByWorkTypes(workType?: number) {
    let params: HttpParams = new HttpParams();
    if (!isNil(workType)) params = params.append('workType', workType);
    return this.get<EnumsModel[]>('OrderRequestOrderTypesByWorkType', { params });
  }

  getTranslations() {
    return this.get<any>('LangJsonFilesForAngular');
  }

  getAppraisalOrderTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('AppraisalOrderTypes');
  }

  getAppraisalVisitTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('AppraisalVisitTypes');
  }

  getQualityMesuresTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('QuantityMeasuresTypes');
  }

  getLivingTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('StayTypes');
  }

  getPavementTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('PavementTypes');
  }

  getWallTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('WallTypes');
  }

  getCeilingTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('CeilingTypes');
  }

  getUrbanisticSituationTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('UrbanisticSituationTypes');
  }

  getComparableDataTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('ComparableDataTypes');
  }

  getOfferTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('OfferTypes');
  }

  getSourceTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('SourceTypes');
  }

  getSwimmingPoolTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('SwimmingPoolTypes');
  }

  getHeatingTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('HeatingTypes');
  }

  getValorationMethodsTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('ValorationMethodsTypes');
  }

  getTenureTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('TenureTypes');
  }

  getBuildingUseTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('BuildingUses');
  }

  getConditioningsTypes(): Observable<ConditionWarningEnumModel[] | null> {
    return this.get<ConditionWarningEnumModel[]>('Conditionals');
  }

  getWarningsTypes(): Observable<ConditionWarningEnumModel[] | null> {
    return this.get<ConditionWarningEnumModel[]>('Warnings');
  }

  getPropertyRegisters(): Observable<ApiPropertyRegister[]> {
    const expires = new Date();
    expires.setHours(expires.getHours() + 2);
    return this.get<ApiPropertyRegister[]>(`PropertyRegisters`, {}, { expires });
  }

  getAppraisalPurposesList(ids: number[]) {
    return this.post<unknown, EnumsModel[]>('AppraisalPurposesList', ids?.length ? ids : []);
  }

  priceRangesByUseAndTypology(use: number, typology: number, reportType: ReportTypes) {
    const params: HttpParams = new HttpParams().append('use', use).append('typology', typology);
    if (!isNil(reportType)) params.append('reportType', reportType);
    return this.get<EnumsModel[]>('PriceRangesByUseAndTypology', { params });
  }

  getPaymentOptionsByCurrentUser(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('PaymentOptionsByCurrentUser');
  }

  getNationalBankSpainCutomerTypes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('NationalBankSpainCutomerTypes');
  }

  getPurposes(): Observable<EnumsModel[] | null> {
    return this.get<EnumsModel[]>('Purposes');
  }

  getAuthorities(): Observable<HttpResponse<string>> {
    return this.http.get<string>(`${this.apiBranch}/Authorities`, { observe: 'response' });
  }

  getCurrencies(): Observable<CurrencyEnumModel[] | null> {
    return this.get<CurrencyEnumModel[]>('Currencies');
  }

  getOrderRequestCopyOptions() {
    return this.get<EnumsModel[]>('BillingDataCopyOptions');
  }

  getExternalCommunicationKeys() {
    return this.get<KeyValueModel[]>('KeysForKeysValues');
  }

  getIncidentsCodesOptions() {
    return this.get<EnumsModel[]>('AppraisalIncidentCodes');
  }
}
