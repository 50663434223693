import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiBaseService2 } from '../services/api-base.service';
import { CacheService } from '../services/cache.service';
import { Observable } from 'rxjs';
import { CountryModel, LocalityModel, LocationModel, OrNull, ProvinceModel, StreetModel } from '@foxeet/domain';

@Injectable()
export class LocationService extends ApiBaseService2 {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Locations', cache);
  }

  getCountries(): Observable<OrNull<CountryModel[]>> {
    return this.get<CountryModel[]>('Countries');
  }

  getProvinces(): Observable<ProvinceModel[]> {
    return this.get<ProvinceModel[]>('Provinces');
  }

  getLocalitiesByIneCodProvince(ineCodProvince: number): Observable<LocalityModel[]> {
    const params: HttpParams = new HttpParams().append('ineCodProvince', `${ineCodProvince}`);
    return this.get<LocalityModel[]>('Localities', { observe: 'response', params });
  }

  getViasByLocalityAndIneCodProvinceIds(localityId: number, ineCodProvince: number): Observable<StreetModel[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('ineCodLocality', `${localityId}`);
    params = params.append('ineCodProvince', `${ineCodProvince}`);
    return this.get<StreetModel[]>('Vias', { observe: 'response', params });
  }

  getCoordinatesByLocation(body: Partial<LocationModel>) {
    let params: HttpParams = new HttpParams();
    params = params.append('excludeLoading', `true`);
    return this.post<Partial<LocationModel>, LocationModel>('Locate', body, { params });
  }
}
