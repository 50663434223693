import { isNilOrEmptyString, isNumber } from '../functions';

export class Phone {
  private countryCode!: string;
  private phoneNumber!: string;

  constructor(countryCode?: string | number, phoneNumber?: string | number) {
    this.setCountryCode(countryCode ?? '');
    this.setPhone(phoneNumber ?? '');
  }

  public setPhone(phoneNumber: string | number): void {
    this.phoneNumber = isNumber(phoneNumber) ? phoneNumber.toString(10) : phoneNumber;
  }

  public getPhone(phoneInterval = 0, separator = ' '): string {
    const phoneIntervals: string[] = [];
    let res = '';
    if (!phoneInterval || phoneInterval < 0 || !Number.isInteger(phoneInterval) || isNilOrEmptyString(this.phoneNumber)) {
      res = `${this.countryCode}${this.phoneNumber}`;
    } else {
      let interval = '';
      for (let i = 0; i < this.phoneNumber.length; i++) {
        interval += this.phoneNumber[i];
        if (!((i + 1) % phoneInterval)) {
          phoneIntervals.push(interval);
          interval = '';
        }
      }
      if (!isNilOrEmptyString(interval)) {
        phoneIntervals.push(interval);
      }
      res = phoneIntervals.join(separator);
    }
    return res;
  }

  public setCountryCode(countryCode: string | number): void {
    this.countryCode = isNumber(countryCode) ? countryCode.toString(10) : countryCode;
  }

  public getCountryCode(countryCodeSymbol: string | undefined | null = '+'): string {
    return `${countryCodeSymbol ?? ''}${this.countryCode}`;
  }

  public getFullPhone(phoneInterval = 0, separator = ' ', countryCodeSymbol: string | undefined | null = '+'): string {
    return `${this.getCountryCode(countryCodeSymbol)} ${this.getPhone(phoneInterval, separator)}`;
  }
}
