export enum FilterInputTypesEnum {
  COMBO = 'COMBO',
  COMBO_FILTER = 'COMBO_FILTER',
  TEXT = 'TEXT',
  DATE_PICKER = 'DATE_PICKER',
  MULTISELECT = 'MULTISELECT',
  SEARCHER_MULTISELECT = 'SEARCHER_MULTISELECT',
  SEARCHER_SELECT = 'SEARCHER_SELECT',
  TEXT_AREA = 'TEXT_AREA',
  CHECK_BOX = 'CHECK_BOX',
  CDK_SEARCHER_DROPDOWN = 'CDK_SEARCHER_DROPDOWN',
  TRIPLE_SWITCH = 'TRIPLE_SWITCH',
  NUMBER = 'NUMBER',
  MASK = 'MASK',
  CHIPS_SEARCHER = 'CHIPS_SEARCHER',
  PASSWORD = 'PASSWORD',
  ASYNC_BUTTON = 'ASYNC_BUTTON',
}

export interface IFilterInputTypes {
  COMBO: FilterInputTypesEnum;
  COMBO_FILTER: FilterInputTypesEnum;
  TEXT: FilterInputTypesEnum;
  PASSWORD: FilterInputTypesEnum;
  DATE_PICKER: FilterInputTypesEnum;
  MULTISELECT: FilterInputTypesEnum;
  SEARCHER_MULTISELECT: FilterInputTypesEnum;
  SEARCHER_SELECT: FilterInputTypesEnum;
  TEXT_AREA: FilterInputTypesEnum;
  CHECK_BOX: FilterInputTypesEnum;
  CDK_SEARCHER_DROPDOWN: FilterInputTypesEnum;
  TRIPLE_SWITCH: FilterInputTypesEnum;
  NUMBER: FilterInputTypesEnum;
  MASK: FilterInputTypesEnum;
  CHIPS_SEARCHER: FilterInputTypesEnum;
  ASYNC_BUTTON: FilterInputTypesEnum;
}

export const FilterInputTypes: IFilterInputTypes = {
  COMBO: FilterInputTypesEnum.COMBO,
  COMBO_FILTER: FilterInputTypesEnum.COMBO_FILTER,
  TEXT: FilterInputTypesEnum.TEXT,
  PASSWORD: FilterInputTypesEnum.PASSWORD,
  DATE_PICKER: FilterInputTypesEnum.DATE_PICKER,
  MULTISELECT: FilterInputTypesEnum.MULTISELECT,
  SEARCHER_MULTISELECT: FilterInputTypesEnum.SEARCHER_MULTISELECT,
  SEARCHER_SELECT: FilterInputTypesEnum.SEARCHER_SELECT,
  TEXT_AREA: FilterInputTypesEnum.TEXT_AREA,
  CHECK_BOX: FilterInputTypesEnum.CHECK_BOX,
  CDK_SEARCHER_DROPDOWN: FilterInputTypesEnum.CDK_SEARCHER_DROPDOWN,
  TRIPLE_SWITCH: FilterInputTypesEnum.TRIPLE_SWITCH,
  NUMBER: FilterInputTypesEnum.NUMBER,
  MASK: FilterInputTypesEnum.MASK,
  CHIPS_SEARCHER: FilterInputTypesEnum.CHIPS_SEARCHER,
  ASYNC_BUTTON: FilterInputTypesEnum.ASYNC_BUTTON,
};
