import { Component } from '@angular/core';
import { BaseFormMobileComponent } from '../../../base-components';
import { setValue } from '../form-input-triple-switch.utils';

@Component({
  selector: 'ui-mobile-form-triple-switch',
  templateUrl: './form-triple-switch.component.html',
  styleUrls: ['./form-triple-switch.component.scss'],
})
export class FormTripleSwitchComponent extends BaseFormMobileComponent<boolean | null> {
  setValue = setValue;
}
