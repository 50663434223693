import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem, MenuItemType } from '@foxeet/mcore';

@Component({
  selector: 'mcore-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  public MenuItemType = MenuItemType;

  /**
   * TODO: Mirar de crear variables css específicas para el menú para sacar este componente a core
   */
  @Input() item: MenuItem;
  @Input() selectedPath: string;

  @Input() itemClass: string;
  @Input() dropdownClass: string;

  @Output() itemClicked: EventEmitter<any> = new EventEmitter(null);

  actionOnClick($event) {
    this.item.opened = !this.item.opened;
    this.itemClicked.emit(this.item);
  }
}
