import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppraiserAppraisalExchangePreferencesModel, AppraisersUserEditPartnerDataModel, AppraiserUserAppraisalExchangePreferences } from '@foxeet/domain';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class ProfessionalsService extends WorkflowService {
  constructor(http: HttpClient, cache?: CacheService) {
    super(http, 'Appraisers', cache);
  }

  getPartnerData(userId: string): Observable<AppraisersUserEditPartnerDataModel> {
    return this.get<AppraisersUserEditPartnerDataModel>(`${userId}/PartnerData`);
  }

  editPartnerData(body: AppraisersUserEditPartnerDataModel): Observable<AppraisersUserEditPartnerDataModel> {
    return this.put<typeof body, AppraisersUserEditPartnerDataModel>(`${body.id}/PartnerData`, body);
  }

  getPreferences(appraiserId: string): Observable<AppraiserUserAppraisalExchangePreferences> {
    return this.get<AppraiserUserAppraisalExchangePreferences>(`${appraiserId}/AppraisalExchangePreferences`);
  }

  savePreferences(preferences: AppraiserAppraisalExchangePreferencesModel) {
    return this.put<AppraiserAppraisalExchangePreferencesModel, AppraiserAppraisalExchangePreferencesModel>(`${preferences.id}/AppraisalExchangePreferences`, preferences);
  }

  assignAppraiserToAppraisals<T = any>(body: any) {
    return this.put<typeof body, T>('AssignToAppraisal', body);
  }
}

@Injectable()
export class ProfessionalsListByDistanceService extends ProfessionalsService {
  public override listPageByFilter<T>(page: any, pagesize: any, body: any) {
    return this.post<typeof body, T>(`ListPagedDistanceBetweenPoint`, body, {
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }
}
