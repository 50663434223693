<div *ngIf="control?.errors" class="validation-messages">
  <p *ngIf="control?.errors[inputValidationErrors.REQUIRED]">
    {{ 'FORM_VALIDATION_REQUIRED' | translate }}
  </p>

  <p *ngIf="displayEmail() || displayEmailValidation()">
    {{ 'FORM_VALIDATION_EMAIL_VALIDATION_ERROR' | translate }}
  </p>

  <p *ngIf="displayEmailAltValidation()">
    {{ 'FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT' | translate }}
  </p>

  <p *ngIf="displayBudgetNumberValidation()">
    {{ 'FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR' | translate }}
  </p>

  <p *ngIf="displayMaxLengthError()">
    {{ 'FORM_VALIDATION_MAX_LENGTH' | translate }}
    {{ control?.errors[inputValidationErrors.MAX_LENGTH]?.requiredLength }}
  </p>

  <p *ngIf="displayMinLengthError()">
    {{ 'FORM_VALIDATION_MIN_LENGTH' | translate }}
    {{ control?.errors[inputValidationErrors.MIN_LENGTH]?.requiredLength }}
  </p>

  <p *ngIf="displayMinInputError()">
    {{ 'FORM_VALIDATION_MIN' | translate }}
    {{ control?.errors[inputValidationErrors.MIN]?.min }}
  </p>

  <p *ngIf="displayMaxInputError()">
    {{ 'FORM_VALIDATION_MAX' | translate }}
    {{ control?.errors[inputValidationErrors.MAX]?.max }}
  </p>

  <p *ngIf="displayAphanumericError()">
    {{ 'FORM_VALIDATION_ALPHANUMERIC' | translate }}
  </p>

  <p *ngIf="displayPatternValidation()">
    {{ 'FORM_VALIDATION_PATTERN' | translate }}
  </p>

  <p *ngIf="displayCustomerAlreadyExistError()">
    {{ 'FORM_VALIDATION_CUSTOMER_ALREADY_EXISTS' | translate }}
  </p>

  <p *ngIf="displayMaxAdditionError()">
    {{ 'FORM_VALIDATION_MAX_ADDITION' | translate }}
    {{ control?.errors[inputValidationErrors.MAX_ADDITION] }}
  </p>

  <p *ngIf="displayNotSameError()">
    {{ 'FORM_VALIDATION_NOT_SAME' | translate }}
  </p>

  <p *ngIf="displayPassNotMatchError()">
    {{ 'FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH' | translate }}
  </p>

  <p *ngIf="displayMinAndMaxError()">
    {{ 'FORM_VALIDATION_MIN_MAX_VALUE' | translate : null : { min: control?.errors.valueRange?.min, max: control?.errors.valueRange?.max } }}
  </p>
</div>
