<div class="wrapper">
  <ion-item
    class="ion-align-items-end"
    [ngClass]="{
      error: control?.invalid,
      disable: control?.disabled,
      'non-opacity': readonly,
      suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === ''),
      'item-has-value': control?.value !== null && control?.value !== undefined,
    }"
    [lines]="readonly ? 'none' : 'inset'"
    (click)="control?.enabled && !readonly ? openDate() : $event.stopPropagation()"
  >
    <ion-label [ngClass]="{ 'item-has-value': control?.value }" [position]="labelPosition" *ngIf="label">{{ label | translate | capitalize }}</ion-label>
    <ion-icon *ngIf="icon !== ''" size="small" slot="start" [name]="icon" [color]="'primary'"></ion-icon>
    <ion-text *ngIf="control && control?.value" class="calendar-value"> {{ control?.value | date : displayFormat }} </ion-text>
  </ion-item>
  <ion-button
    [id]="'AT_' + componentId + ' _remove'"
    slot="end"
    *ngIf="control?.enabled && !readonly && canReset"
    class="button-icon-clear-rounded clear-date-btn"
    (click)="control?.reset()"
  >
    <ion-icon [name]="'trash'" [color]="'danger'" [size]="'small'"></ion-icon>
  </ion-button>
</div>
<ion-modal [id]="componentId" class="input-datetime" [keepContentsMounted]="true" size="cover">
  <ng-template>
    <ion-datetime
      [preferWheel]="false"
      [firstDayOfWeek]="1"
      [showDefaultButtons]="true"
      [cancelText]="'ACTIONS_cancel' | translate"
      [doneText]="'ACTIONS_done' | translate"
      locale="es-ES"
      hourCycle="h23"
      [value]="control?.value"
      [min]="minDate || undefined"
      [max]="maxDate || undefined"
      [presentation]="pickerFormat"
      [disabled]="control?.disabled"
      [readonly]="readonly"
      (ionChange)="dismiss($event)"
    >
      <span slot="time-label">{{ 'hour' | translate }}</span>
    </ion-datetime>
  </ng-template>
</ion-modal>
<!-- TODO: review classes breaks alignment -->
<p class="form-hint" *ngIf="subLabel">{{ subLabel | translate }}</p>

<ui-mobile-form-validation-messages *ngIf="showValidationMessages && control" [control]="control"></ui-mobile-form-validation-messages>
