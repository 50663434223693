import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { createGesture, GestureDetail } from '@ionic/core';
import { ChipModel } from '@foxeet/ui';

/**
 * @TODO MOVE TO UI LIB
 */
@Component({
  selector: 'mcore-chips-slider',
  templateUrl: './chips-slider.component.html',
  styleUrls: ['./chips-slider.component.scss'],
})
export class ChipsSliderComponent implements AfterViewInit {
  @Input() chips: ChipModel[] = [];

  @Input() set urlActive(urlActive: string) {
    if (urlActive) this.setActiveChipByUrl(urlActive);
  }

  @Output() filterChange = new EventEmitter<ChipModel>();

  private _el: Element;

  ngAfterViewInit() {
    this._el = document.querySelector('.js-chips-slider');
    const gesture = createGesture({
      gestureName: 'scroll',
      el: document.querySelector('.js-chips-slider'),
      onMove: (move: GestureDetail) => {
        this._el.scrollLeft = move.startX > move.currentX ? this._el.scrollLeft + 10 : this._el.scrollLeft - 10;
      },
    });

    gesture.enable();
  }

  selectChip(selectedChip: ChipModel) {
    this.chips.forEach((chip) => (chip.active = false));
    selectedChip.active = true;
    this.filterChange.emit(selectedChip);
  }

  setActiveChipByUrl(urlActive) {
    this.chips.forEach((chip) => {
      chip.active = chip.link === urlActive ? true : false;
    });
  }
}
