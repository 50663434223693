import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export { sectionListMapper } from './appraisal-asset-cond-warn-obser.util';
export { getVerificationDataMapped, VerificationItem, VerificationSection } from './appraisal-asset-verification.map';
export { ArrayUtils } from './array.functions';
export { dynamicInjector } from './dynamic-injector';
export { EntitiesUtils } from './entities-get-data.functions';
export { downloadExcel, downloadDocument, downloadFile, downloadUrlContent, getFileFromPath, getURI, downloadFileMobile } from './file.functions';
export * from './form.functions';
export { isBoolean, cloneObject, isNil, isEmptyString, isNilOrEmptyString, isNumber, ObjectTS } from './javascript.functions';
export { checkIfLocationChange } from './location.utils';
export { applyMixins } from './mixins.functions';
export { NumberUtils } from './number.functions';
export { ObjectUtils } from './object.functions';
export { AppraisalAssetSurfaceSelectedDataUtils, SurfacesChildData, SurfaceSelectData } from './surface-selector.functions';
export { WorkersUtils } from './workers-get-data.functions';
export { assetCreateModelToOrderRequestElementModel, assetCreateModelToComparableModel } from './cadastral-reference.map';
export * from './contact-us.functions';
export * from './date.functions';
export * from './validations.functions';
export * from './external-communications.utils';
export * from './hotjar-cookies-consent.functions';
export * from './google-analytics-cookies-consent.functions';
export * from './appraisal-work-plan-assets.functions';
export { LicenseeUsersUtils } from './licensee-users-get-data.functions';
export * from './multiple-edition.functions';
export * from './ag-grid.functions';
export * from './workType.functions';

/**
 *
 * @param url$ Observable que lleve el dominio del back
 * @param lang$ Observable que lleve el lenguage configurado
 */
export const defaultBaseUrlFactory = (url$: Observable<string>, lang$?: Observable<string>): Observable<string> =>
  lang$ ? combineLatest([url$, lang$]).pipe(map(([url, lang]) => `${url}/${lang}`)) : url$;
