import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';
import { ComparableEditModel, ComparableIndexModel, ComparableMarketStudies, IdealistaRequestModel } from '@foxeet/domain';
import { Observable } from 'rxjs';

@Injectable()
export class ComparablesService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Comparables', cache);
  }

  createWitness(body: ComparableIndexModel) {
    return this.post<typeof body, ComparableEditModel>('', body);
  }

  createMultipleWitness(body: ComparableIndexModel[]) {
    return this.post<typeof body, ComparableEditModel[]>('CreateMultiple', body);
  }

  editWitness(id: number, body: ComparableIndexModel) {
    return this.put<typeof body, ComparableEditModel>(`${id}`, body);
  }

  /*
  // TODO: revisar por qué no se llame simplemente ListPaged
  public override listPageByFilter<T>(page: number, size: number, body: any): Observable<T> {
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('pagesize', `${size}`);
    body = removeNullsAndUndefined(body);
    return this.post<typeof body, T>(`ListPagedDistanceBetweenPoint`, body, { observe: 'response', params });
  }*/

  getComparablesFromIdealista(body: IdealistaRequestModel): Observable<ComparableIndexModel[]> {
    return this.post('GetComparableFromIdealista', body);
  }

  deleteWitness(id: number, assetId: number | undefined = undefined, clusterId: number | undefined = undefined) {
    return this.delete<void>('' + id, { params: this.setParams({ assetId, clusterId }) });
  }

  editComparableObservations(id: number, observations: string): Observable<ComparableIndexModel> {
    return this.put(`${id}/EditObservations`, { id, observations });
  }

  getComparablesMarketStudies(id: number): Observable<ComparableMarketStudies[]> {
    return this.get(`${id}/GetMarketStudies`);
  }

  getComparablesMarketStudiesPaged(id: number, page: number, size: number, body: any) {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    return this.http.post(`${this.apiBranch}/${id}/GetMarketStudiesListPaged`, body, { params });
  }
}
