import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetTypologies, AssetUses, OfferTypes } from '@foxeet/domain';
import { FEATURE_NAME } from '../domain/market-study-chest.config';
import { MarketStudyState } from './market-study.state';

export const useMainSelector = createFeatureSelector<MarketStudyState>(FEATURE_NAME);

// Se usa en el select
export const UseMarketStudy = (props: { use: AssetUses; typology: AssetTypologies; offerType: OfferTypes }) =>
  createSelector(useMainSelector, (state) => {
    return state.marketStudies.filter((el) => {
      return el.use === props.use && el.typology === props.typology && el.offerType === props.offerType;
    });
  });
