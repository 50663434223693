<span class="p-float-label my-4" *ngIf="control" [ngClass]="{ readonly: readonly }" [ngStyle]="{ 'max-height': '200px', 'overflow-y': control?.value ? 'scroll' : 'hidden' }">
  <!--    'p-disabled': control?.disabled || disabled,-->
  <!--  El ngStyle es necesario para el evento resize no vale con ponerlo en la clase -->
  <textarea
    pInputTextarea
    id="{{ componentId }}"
    class="full-width"
    [ngClass]="{ 'p-filled': control?.value !== null && control?.value !== undefined }"
    [ngStyle]="{ 'padding-top': '1.5rem' }"
    [rows]="rows"
    [cols]="30"
    [formControl]="control"
    [autoResize]="autoResize"
    ng-trim="true"
    [readonly]="readonly"
  ></textarea>
  <label *ngIf="label" for="{{ componentId }}" [ngClass]="{ primary: control?.value || control?.value === 0 }" for="float-input">{{ label | translate }}</label>
  <ui-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-form-validation-messages>
</span>
