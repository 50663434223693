import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './domain/location-chest.config';
import { LocationReducer } from './redux/location.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LocationEffects } from './redux/location.effects';
import { DataAccessModule } from '@foxeet/data-access';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, LocationReducer), EffectsModule.forFeature([LocationEffects]), DataAccessModule],
})
export class LocationChestModule {}
