import { OrNullOrUndef, PrimitiveTypes } from '@foxeet/domain';

export const isNil = (value?: any): value is null | undefined => [null, undefined, 'null'].includes(value);

export const isPrimitiveType = (value?: unknown): value is PrimitiveTypes => {
  return !isNil(value) && (isNumber(value) || isString(value) || isBoolean(value));
};

export const isValidJSON = (value?: unknown) => {
  try {
    if (typeof value === 'string') {
      return !!JSON.parse(value);
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const isString = (value?: unknown): value is string => typeof value === 'string';

export const isEmptyString = (value: string): boolean => value === '';

export const isNilOrEmptyString = (value: OrNullOrUndef<string>): boolean => isNil(value) || isEmptyString(value);

export function cloneObject<T = any>(source: T): T {
  return Array.isArray(source)
    ? source.map((item) => cloneObject(item))
    : source instanceof Date
    ? new Date(source.getTime())
    : source && typeof source === 'object'
    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
        o[prop] = cloneObject((source as { [key: string]: any })[prop]);
        return o;
      }, Object.create(Object.getPrototypeOf(source)))
    : (source as T);
}

export const isNumber = (value?: unknown): value is number => typeof value === 'number';

export const isBoolean = (value?: unknown): value is boolean => {
  return value === true || value === false;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ObjectTS {
  // eslint-disable-next-line @typescript-eslint/ban-types
  export const keys = <T = {}>(v: T): Array<keyof typeof v> => Object.keys(v) as Array<keyof typeof v>;
  export const values = <T>(v: { [s: string]: T } | ArrayLike<T>) => Object.values<T>(v);
  export const getProperty = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];
  export const setProperty = <T, K extends keyof T>(obj: T, key: K, value: T[K]): void => {
    obj[key] = value;
  };
}
