import {
  AppraisalOrderTypes,
  ValidationAppraisalRegisteredPropertyValidationType,
  LicenseeTypes,
  WorkerRoles,
  FloorTypes,
  ValorationMethods,
  AppraisalImplementationFileDocumentTypes,
} from '../enums';
import { WorkflowItemLicenseeIndexModel, WorkflowItemWorkerIndexModel } from './appraisal.model';
import { UserIndexModel } from './user.model';
import { IWorkflowStepModel } from './workflow-step.model';

export interface ValidationAppraisalCreateModel {
  customerId: number;
  recordId: number;
  customerReferenceId: string;
}

export interface ValidationAppraisalFilterModel {
  id?: number;
  ids?: number[];
  currentWorkflowStep?: number;
  currentWorkflowSteps?: number[];
  excludedCurrentWorkflowSteps?: number[];
  requesterCompanyId?: number;
  requesterCompanyIds?: number[];
  supplierCompanyId?: number;
  supplierCompanyIds?: number[];
  customerId?: number;
  customerIds?: number[];
  batchId?: number;
  isFinished?: boolean;
  finishedMaxDateTime?: string;
  finishedMinDateTime?: string;
  addedMaxDateTime?: string;
  addedMinDateTime?: string;
  isCancelled?: boolean;
  cancelledMaxDateTime?: string;
  cancelledMinDateTime?: string;
  withIncidents?: boolean;
  withIncidentsNotResolved?: boolean;
  withIncidentsMaxDateTime?: string;
  withIncidentsMinDateTime?: string;
  withClaims?: boolean;
  withClaimsNotResolved?: boolean;
  withClaimsMaxDateTime?: string;
  withClaimsMinDateTime?: string;
  excludedId?: number;
  excludedIds?: number[];
  summary?: string;
  activatedFiltersCount?: number;
  orderFieldName?: string;
  orderFieldAsc?: boolean;
  withRevisions?: boolean;
  withRevisionsNotResolved?: boolean;
  withRevisionsMaxDateTime?: string;
  withRevisionsMinDateTime?: string;
  recordId?: string;
  technicalUserId?: number;
  technicalUserIds?: number[];
  hasAssignedAppraiserUser?: boolean;
  processManagerUserId?: number;
  processManagerUserIds?: number[];
  technicalManagerUserId?: number;
  technicalManagerUserIds?: number[];
  customerIdOrRequesterCompanyId_CustomerId?: number;
  customerIdOrRequesterCompanyId_RequesterCompanyId?: number;
  validationStartMaxDatetime?: string;
  validationStartMinDatetime?: string;
  validationFinishMaxDatetime?: string;
  validationFinishMinDatetime?: string;
  isOk?: boolean;
  isPendingFinishValidation?: boolean;
  workflowItemLicenseeTypeAndLicenseeIds: Map<LicenseeTypes, number[]>;
  workerRoleAndUserIds: Map<WorkerRoles, number[]>;
}

export enum DefaultQAFiltersEnum {
  All = 0,
  PendingToAssignProcessManager,
  PendingToAssignTechnicalManager,
  PendingToAssignTechnician,
  PendingToStartValidation,
  ValidationInProcess,
  Validated,
  DeliveredToClient,
  Cancelled,
  Alive,
  Claims,
}

export enum DefaultQAFiltersNameEnum {
  'all' = 0,
  'pendingToAssignProcessManager',
  'pendingToAssignTechnicalManager',
  'pendingTOAssignTechnician',
  'pendingToStartValidation',
  'validationProcess',
  'validationFinished',
  'deliveredToClient',
  'cancelled',
  'alive',
  'claims',
}

export interface ValidationAppraisalClaimIndexModel {
  claimId: number;
  workflowItemId: number;
  addedDateTime: string;
  addedUserId: number;
  isSolved: boolean;
  solvedDateTime: string;
  solvedUserId: number;
  reasonDescription: string;
  solutionDescription: string;
  addedUser: UserIndexModel;
  solvedUser: UserIndexModel;
  workflowItem: ValidationAppraisalIndexModel;
}

/////////////////////////
// Nuevos modelos

export interface ValidationAppraisalIndexModel {
  id: number;
  parentId: number;
  rootId: number;
  currentStepValue: number;
  addedDateTime: string;
  addedUserId: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  workers: WorkflowItemWorkerIndexModel[];
  recordId: string;
  isOk: boolean;
}

export interface ValidationAppraisalModel {
  id: number;
  parentId: number;
  rootId: number;
  currentStepValue: number;
  addedDateTime: string;
  addedUserId: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  workers: WorkflowItemWorkerIndexModel[];
  recordId: string;
  isOk: boolean;
  steps: IWorkflowStepModel[];
  orderType: AppraisalOrderTypes;
  customerReferenceId: string;
  observations: string;
  streetType: string;
  streetName: string;
  streetNumber: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
  finishedDateTime: string;
  canAssignManagerProcessUser: boolean;
  canAssignManagerTechnicalUser: boolean;
  canAssignTechnicalUser: boolean;
  cancelledUserFullName: string;
  cancelledDateTime: string;
  cancelledDescription: string;
  canCancel: boolean;
  canValidate: boolean;
  canStartValidation: boolean;
  canFinish: boolean;
  canEdit: boolean;
  canCreateClaim: boolean;
  isValidating: boolean;
  showValidationTab: boolean;
  canShowClaimsTab: boolean;
  appraiserName: string;
  validationStartDatetime: string;
  validationFinishDatetime: string;
}

export interface ValidationAppraisalEditModel {
  parentId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  id: number;
  excelFile: string;
  pdfFile: string;
  recordId: string;
  customerReferenceId: string;
}

export interface ValidationAppraisalRegisteredPropertyModel {
  id: number;
  registeredPropertyId: string;
  idUfir: string;
  cadastralReference: string;
  adoptedSurface: number;
  unitValue: number;
  appraisalValue: number;
  estimatedValue: number;
  isOk: boolean;
  isFinished: boolean;
  validationItems: ValidationAppraisalRegisteredPropertyValidationItemIndexModel[];
  valorationMethodAdopted: ValorationMethods;
}

export interface ValidationAppraisalRegisteredPropertyValidationItemIndexModel {
  registeredPropertyId: number;
  validationType: ValidationAppraisalRegisteredPropertyValidationType;
  observations: string;
}

export interface ValidationAppraisalFilesIndexModel {
  id: number;
  fileName: string;
  fileWebPath: string;
  addedDateTime: string;
  name: string;
  isPrintable: boolean;
  validationAppraisalDocumentType: AppraisalImplementationFileDocumentTypes;
}

export interface ValidationAppraisalRegisteredPropertyValidationModel {
  id: number;
  estimatedValue: number;
  isOk: boolean;
  isFinished: boolean;
  validationItems: ValidationAppraisalRegisteredPropertyValidationItemIndexModel[];
  valorationMethodAdopted: ValorationMethods;
}
