export interface TutorialStep {
  header?: TutorialStepHeader;
  title?: string;
  titles?: string[];
  titleAfterImage?: string;
  subtitle?: string;
  titleClass?: string;
  titleInfo?: string;
  subtitleClass?: string;
  imageAfterParagraph?: string[];
  imageClasses?: string[];
  imageClass?: string;
  imageCustomRounded?: TutorialStepImage;
  imageCustom?: TutorialStepImage;
  image?: string;
  imageSubtitle?: string;
  paragraphs?: string[];
  paragraphsBeforeImage?: string[];
  paragraphsSeparators?: boolean[];
  paragraphsClasses?: string[];
  paragraphsContainerClasses?: string;
  sections?: TutorialStepSection[];
  links?: TutorialStepLink[];
  button?: TutorialStepButton;
  buttons?: TutorialStepButton[];
  paragraphsBottom?: string[];
  paragraphsBottomClasses?: string[];
  imageBottom?: string;
  stepClass?: string;
  contentClasses?: string;
}

export enum TutorialStepDirection {
  default = 'default',
  reverse = 'reverse',
}

export interface TutorialStepHeader {
  title?: string;
  image?: string;
}

export interface TutorialStepImage {
  url: string;
  size?: any;
}

export interface TutorialStepSection {
  title?: string;
  paragraphs?: string[];
  sectionClasses?: string;
  paragraphsClasses?: string[];
  image?: string;
  iconName?: string;
  direction?: TutorialStepDirection;
  textAlign?: string;
  showNumber?: boolean;
}

export interface TutorialStepLink {
  url: string;
  label: string;
  icon: string;
  direction?: TutorialStepDirection;
}

export interface TutorialStepButton {
  title: string;
  callback: any;
}
