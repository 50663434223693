import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {
  EditLicenseePlanModel,
  License,
  LicenseeAllowedRoleEditModel,
  LicenseeEditModel,
  LicenseeEditPartnerDataModel,
  LicenseeFilterModel,
  LicenseeModel,
  LicenseePaymentEstimationModel,
  LicensePlans,
  LicensePlansModel,
  UserEditModel,
  UserModel,
  ValidateCanChangePlanModel,
} from '@foxeet/domain';
import { Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { Injectable } from '@angular/core';
import { ParticipantsAndWorkflowService } from '../services/participants.service';

@Injectable()
export class LicenseService extends ParticipantsAndWorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Licensees', cache);
  }

  public editPlan(licenseId: License['id'], plan: LicensePlans): Observable<HttpResponse<string>> {
    return this.http.put<string>(`${this.apiBranch}/${licenseId}/Plan`, { plan }, { observe: 'response' });
  }

  public rollBackPlanDowngrade(licenseId: License['id']) {
    return this.http.put<EditLicenseePlanModel>(`${this.apiBranch}/${licenseId}/CancelContractedPlan`, null, { observe: 'response' });
  }

  public getPartnerData(userId: UserModel['id']): Observable<LicenseeEditPartnerDataModel> {
    return this.get<LicenseeEditPartnerDataModel>(`${userId}/PartnerData`);
  }

  public editPartnerData(body: LicenseeEditPartnerDataModel): Observable<LicenseeEditPartnerDataModel> {
    return this.put<LicenseeEditPartnerDataModel, LicenseeEditPartnerDataModel>(`${body.id}/PartnerData`, body);
  }

  public usersListPagedByLicenseeId<T>(licenseeId: License['id'], page: number, size: number, body: any): Observable<HttpResponse<T>> {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    return this.http.post<T>(`${this.apiBranch}/${licenseeId}/Users/ListPaged`, body, { observe: 'response', params });
  }

  public isCreated(nationalDocumentNumber: string): Observable<boolean> {
    const params = new HttpParams().set('nif', `${nationalDocumentNumber}`);
    return this.http.get<boolean>(this.apiBranch + '/IsCreated', { params });
  }

  public current(): Observable<LicenseeModel> {
    return this.http.get<LicenseeModel>(`${this.apiBranch}/Current`);
  }

  public validateCanChangePlan(licensesId: License['id'], planToChange: LicensePlansModel['id']) {
    return this.http.get<ValidateCanChangePlanModel>(`${this.apiBranch}/${licensesId}/ValidateCanChangePlan?planToChange=${planToChange}`);
  }

  public getAllowedRoles(licenseeId: License['id']) {
    return this.http.get<LicenseeAllowedRoleEditModel[]>(`${this.apiBranch}/${licenseeId}/AllowedRoles`);
  }

  public updateAllowedRole(licenseeId: License['id'], role: LicenseeAllowedRoleEditModel) {
    return this.http.put<LicenseeAllowedRoleEditModel>(`${this.apiBranch}/${licenseeId}/AllowedRoles`, role);
  }

  public getLicenseeUser(licenseeId: License['id'], userId: UserModel['id']) {
    return this.http.get<UserModel>(`${this.apiBranch}/${licenseeId}/Users/${userId}`);
  }

  public updateLicenseeUser(licenseeId: License['id'], userId: UserModel['id'], user: UserEditModel) {
    return this.http.put<UserModel>(`${this.apiBranch}/${licenseeId}/Users/${userId}`, user);
  }

  public getPaymentEstimation(licenseeId: License['id']) {
    return this.http.get<LicenseePaymentEstimationModel>(`${this.apiBranch}/${licenseeId}/PaymentEstimation`);
  }

  public getProfile() {
    return this.http.get<LicenseeModel>(`${this.apiBranch}/Profile`);
  }

  public putProfile(body: LicenseeEditModel) {
    return this.http.put<LicenseeEditModel>(`${this.apiBranch}/Profile`, body);
  }

  public getResolverUsersPaged(page: number, size: number, orderRequestId: number, filter: any): Observable<any> {
    return this.post('SupplierCompanies/ListPaged', filter, {
      params: this.setParams({
        page,
        size,
        orderRequestId,
      }),
    });
  }

  public getResolverUsersListAll(filter: LicenseeFilterModel): Observable<any> {
    return this.post<typeof filter, any>('SupplierCompanies/ListAll', filter);
  }

  public checkIfShowWelcomeConnectionModal(licenseeId: number) {
    return this.get<boolean>(`${licenseeId}/ConnectionViewMessageAccepted`);
  }

  public welcomeConnectionModalAccepted(licenseeId: number, connectionViewMessageAccepted: boolean) {
    const url = `${this.apiBranch}/${licenseeId}/EditConnectionViewMessageAccepted?connectionViewMessageAccepted=${connectionViewMessageAccepted}`;
    return this.http.put(url, null);
  }
}
