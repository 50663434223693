import { Directive, ElementRef, forwardRef, Inject, Input, OnChanges, Optional, Renderer2, SimpleChanges } from '@angular/core';
import { COMPOSITION_BUFFER_MODE, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil, isNilOrEmptyString, NumberUtils } from '@foxeet/utils/functions';
import { NormalizeNumberPipe } from '../pipes-module';
import { OrNull } from '@foxeet/domain';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[minMaxRestriction]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinMaxRestrictionDirective),
      multi: true,
    },
  ],
})
export class MinMaxRestrictionDirective extends DefaultValueAccessor implements OnChanges {
  protected _myRenderer: Renderer2;
  protected _myElementRef: ElementRef;
  protected _translateService: TranslateService;

  private normalizeNumber = new NormalizeNumberPipe();

  @Input() min?: number = Number.MIN_SAFE_INTEGER;
  @Input() max?: number = Number.MAX_SAFE_INTEGER;
  @Input() digitsInfo = '.2-2';

  constructor(renderer: Renderer2, elementRef: ElementRef, translateService: TranslateService, @Optional() @Inject(COMPOSITION_BUFFER_MODE) _compositionMode: boolean) {
    super(renderer, elementRef, _compositionMode);
    this._myRenderer = renderer;
    this._myElementRef = elementRef;
    this._translateService = translateService;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.min && isNil(changes.min.currentValue)) {
      this.min = Number.MIN_SAFE_INTEGER;
    }
    if (changes.max && isNil(changes.max.currentValue)) {
      this.max = Number.MAX_SAFE_INTEGER;
    }
  }

  public registerOnChange(fn: (_: unknown) => unknown): void {
    this.onChange = (_: string | number) => {
      const parsedValue = new DecimalPipe(this._translateService.currentLang).transform(this.toNumber(_), this.digitsInfo);
      fn(this.checkMinMaxAndSetValueIfIsInvalid(parsedValue));
    };
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = () => {
      this.writeValue(this.checkMinMaxAndSetValueIfIsInvalid(this._myElementRef.nativeElement.value));
    };
  }

  /**
   * let parsedValue = value == '' ? null : parseFloat(value);
   * NumberValueAccessor uses previous line to format number.
   */
  checkMinMaxAndSetValueIfIsInvalid(value: OrNull<string | number>): OrNull<number> {
    let parsedValue: OrNull<number> = this.toNumber(value);

    if (parsedValue) {
      parsedValue = NumberUtils.setMinMaxValue(parsedValue, this.min, this.max);
    }

    return parsedValue;
  }

  writeValue(value: any): void {
    if (!isNil(value)) {
      const parsedValue = this.toNumber(new DecimalPipe(this._translateService.currentLang).transform(value, this.digitsInfo));
      this._myRenderer.setProperty(this._myElementRef.nativeElement, 'value', parsedValue);
    }
  }

  toNumber(value: any): number | null {
    return isNilOrEmptyString(value) ? null : this.normalizeNumber.transform(value);
  }
}
