import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildingElementItemResidualModel } from '@foxeet/domain';
import { Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

// TODO: Change service name
@Injectable()
export class ValorationBuildingElementService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalReportAssetValorationBuildingElements', cache);
  }

  public getDefaultValues(body: BuildingElementItemResidualModel): Observable<BuildingElementItemResidualModel> {
    return this.post<BuildingElementItemResidualModel, BuildingElementItemResidualModel>(`DefaultValues`, body, { observe: 'response' });
  }
}
