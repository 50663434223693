import { AssetUses, CountryConfigurationEnumModel, CurrencyEnumModel, EnumsModel, InternationalizationTypes, ValorationLegalMaximumOptions } from '@foxeet/domain';
import { CultureSetted } from '../redux/culture.state';
import { isNil, isNilOrEmptyString } from '../to-delete/bucket';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { CultureCurrencies, CultureInUse, CultureSetted as SelectCultureSetted } from '../redux/culture.selectors';
import { Observable } from 'rxjs';

const meassurementConfig = (use: AssetUses, config: CountryConfigurationEnumModel) => config?.meassurementConfigurations?.find((mC) => mC.use === use);

export function getSurfaceUnitByAssetUse(use: AssetUses, config: CountryConfigurationEnumModel): string {
  return meassurementConfig(use, config)?.surfaceUnitMeasurement?.symbol ?? config.defaultSurfaceUnitMeasurement?.symbol;
}

export function getUnitLengthByAssetUse(use: AssetUses, config: CountryConfigurationEnumModel): string {
  return meassurementConfig(use, config)?.lengthUnitMeasurement?.symbol ?? config.defaultLengthUnitMeasurement?.symbol;
}

export function getCurrencyByCultureCode(cultureCode: string, currencies: CurrencyEnumModel[], defaultValue: string): string {
  return currencies.find((currency) => currency.cultureCodeCurrency === cultureCode)?.symbol ?? defaultValue;
}

export function getCurrencyPerSurfaceUnitByAssetUse(use: AssetUses, config: CountryConfigurationEnumModel, customCurrencySymbol?: string): string {
  return `${customCurrencySymbol ?? config.defaultCurrency.symbol}/${getSurfaceUnitByAssetUse(use, config)}`;
}

export function getSymbolByCultureCode(currencies: CurrencyEnumModel[], cultureCode: string): string | undefined {
  return currencies.find((el) => el.cultureCodeCurrency === cultureCode)?.symbol;
}

export function getCodeByCultureCode(currencies: CurrencyEnumModel[], cultureCode: string): string | undefined {
  return currencies.find((el) => el.cultureCodeCurrency === cultureCode)?.code;
}

const cultureCurrency = (cultureSetted?: CultureSetted | null) =>
  (!isNil(cultureSetted) && isNilOrEmptyString(cultureSetted?.reportCurrency) ? cultureSetted?.currency : cultureSetted?.reportCurrency) ?? cultureSetted?.currency ?? '';

export function getSuffixBySuffixTypeAndAssetUse(
  suffixType: InternationalizationTypes,
  assetUse: AssetUses | null = null,
  cultureCodeCurrency: string | null,
  countryConfig: CountryConfigurationEnumModel,
  cultureSetted: CultureSetted | null | undefined,
  currencies: CurrencyEnumModel[] | null | undefined,
) {
  let suffix = '';
  switch (suffixType) {
    case InternationalizationTypes.Currency:
      suffix = getCurrencyByCultureCode(cultureCodeCurrency ?? '', currencies ?? [], cultureCurrency(cultureSetted));
      break;
    case InternationalizationTypes.UnitLength:
      suffix = assetUse !== null ? getUnitLengthByAssetUse(assetUse, countryConfig) : cultureSetted?.unitLength ?? '';
      break;
    case InternationalizationTypes.SurfaceUnit:
      suffix = assetUse !== null ? getSurfaceUnitByAssetUse(assetUse, countryConfig) : cultureSetted?.surfaceUnit ?? '';
      break;
    case InternationalizationTypes.CurrencyPerSurfaceUnit:
      if (assetUse !== null) {
        suffix = getCurrencyPerSurfaceUnitByAssetUse(
          assetUse,
          countryConfig,
          getCurrencyByCultureCode(cultureCodeCurrency ?? '', currencies ?? [], cultureCurrency(cultureSetted)),
        );
      } else if (cultureCodeCurrency) {
        suffix = `${getCurrencyByCultureCode(cultureCodeCurrency ?? '', currencies ?? [], cultureCurrency(cultureSetted))}/${cultureSetted?.surfaceUnit}`;
      } else {
        suffix =
          !isNil(cultureSetted) && isNilOrEmptyString(cultureSetted?.currencyPerSurfaceUnit)
            ? cultureSetted?.reportCurrencyPerSurfaceUnit
            : cultureSetted?.currencyPerSurfaceUnit ?? '';
      }

      break;
  }
  return suffix;
}

export class CultureUtils {
  private _countryConfig!: CountryConfigurationEnumModel;

  get CultureSetted() {
    return this._cultureSetted;
  }

  private _cultureSetted: CultureSetted | null | undefined = null;
  private _currencies: CurrencyEnumModel[] = [];

  constructor(private store: Store, _componentDestroyed: Observable<any>) {
    this.store
      .select(CultureCurrencies)
      .pipe(takeUntil(_componentDestroyed))
      .subscribe((currencies) => (this._currencies = currencies));

    this.store
      .select(CultureInUse)
      .pipe(takeUntil(_componentDestroyed))
      .subscribe((countryConfig) => (this._countryConfig = countryConfig ?? ({} as CountryConfigurationEnumModel)));

    this.store
      .select(SelectCultureSetted)
      .pipe(takeUntil(_componentDestroyed))
      .subscribe((cultureSetted) => (this._cultureSetted = cultureSetted));
  }

  private getSurfaceUnitByAssetUse(use: AssetUses): string {
    return meassurementConfig(use, this._countryConfig)?.surfaceUnitMeasurement?.symbol ?? this._countryConfig.defaultSurfaceUnitMeasurement?.symbol;
  }

  private getUnitLengthByAssetUse(use: AssetUses): string {
    return meassurementConfig(use, this._countryConfig)?.lengthUnitMeasurement?.symbol ?? this._countryConfig.defaultLengthUnitMeasurement?.symbol;
  }

  private getCurrencyByCultureCode(cultureCodeCurrency: string): string {
    return this._currencies.find((currency) => currency.cultureCodeCurrency === cultureCodeCurrency)?.symbol ?? cultureCurrency(this._cultureSetted);
  }

  private getCurrencyPerSurfaceUnitByAssetUse(use: AssetUses, customCurrencySymbol?: string): string {
    return `${customCurrencySymbol ?? this._countryConfig.defaultCurrency.symbol}/${this.getSurfaceUnitByAssetUse(use)}`;
  }

  getSuffixBySuffixTypeAndAssetUse(suffixType: InternationalizationTypes, assetUse: AssetUses | null = null, cultureCodeCurrency: string | null = null) {
    let suffix = '';
    switch (suffixType) {
      case InternationalizationTypes.Currency:
        suffix = this.getCurrencyByCultureCode(cultureCodeCurrency ?? '');
        break;
      case InternationalizationTypes.UnitLength:
        suffix = assetUse !== null ? this.getUnitLengthByAssetUse(assetUse) : this._cultureSetted?.unitLength ?? '';
        break;
      case InternationalizationTypes.SurfaceUnit:
        suffix = assetUse !== null ? this.getSurfaceUnitByAssetUse(assetUse) : this._cultureSetted?.surfaceUnit ?? '';
        break;
      case InternationalizationTypes.CurrencyPerSurfaceUnit:
        if (assetUse !== null) {
          suffix = this.getCurrencyPerSurfaceUnitByAssetUse(assetUse, this.getCurrencyByCultureCode(cultureCodeCurrency ?? ''));
        } else if (cultureCodeCurrency) {
          suffix = `${this.getCurrencyByCultureCode(cultureCodeCurrency)}/${this._cultureSetted?.surfaceUnit}`;
        } else {
          suffix =
            !isNil(this._cultureSetted) && isNilOrEmptyString(this._cultureSetted?.currencyPerSurfaceUnit)
              ? this._cultureSetted?.reportCurrencyPerSurfaceUnit
              : this._cultureSetted?.currencyPerSurfaceUnit ?? '';
        }

        break;
    }
    return suffix;
  }

  getSuffixByValueOfLegalMaximumOptions(selectedOption: ValorationLegalMaximumOptions): string | undefined {
    if (selectedOption === ValorationLegalMaximumOptions.UnitValue) {
      return this._cultureSetted?.currencyPerSurfaceUnit;
    } else if (selectedOption === ValorationLegalMaximumOptions.Value) {
      return this._cultureSetted?.currency;
    } else {
      return '%';
    }
  }
}
