import { AfterViewChecked, Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[utilsResponsiveTable]',
})
export class ResponsiveTableDirective implements OnInit, AfterViewChecked {
  public showLeftGradient = false;
  public showRightGradient = false;
  public gradientsPosition = 0;

  gradientWrapperDiv!: HTMLElement;
  gradientLeftDiv!: HTMLElement;
  gradientRightDiv!: HTMLElement;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this._renderer.addClass(this._elementRef.nativeElement, 'table-container');
    this.buildGradientTemplate();
  }

  ngAfterViewChecked(): void {
    this.onScroll(this._elementRef);
    this.setGradientElementsStyle();
  }

  buildGradientTemplate(): void {
    this.gradientWrapperDiv = this._renderer.createElement('div');
    this._renderer.addClass(this.gradientWrapperDiv, 'gradients-wrapper');

    this.gradientLeftDiv = this._renderer.createElement('div');
    this._renderer.addClass(this.gradientLeftDiv, 'gradient');
    this._renderer.addClass(this.gradientLeftDiv, 'gradient-left');

    this.gradientRightDiv = this._renderer.createElement('div');
    this._renderer.addClass(this.gradientRightDiv, 'gradient');
    this._renderer.addClass(this.gradientRightDiv, 'gradient-right');

    this._renderer.appendChild(this.gradientWrapperDiv, this.gradientLeftDiv);
    this._renderer.appendChild(this.gradientWrapperDiv, this.gradientRightDiv);

    this._renderer.appendChild(this._elementRef.nativeElement, this.gradientWrapperDiv);
  }

  setGradientElementsStyle(): void {
    this._renderer.setStyle(this.gradientWrapperDiv, 'left', `${this.gradientsPosition.toString()}px`);
    this.showRightGradient ? this._renderer.addClass(this.gradientRightDiv, 'visible') : this._renderer.removeClass(this.gradientRightDiv, 'visible');
    this.showLeftGradient ? this._renderer.addClass(this.gradientLeftDiv, 'visible') : this._renderer.removeClass(this.gradientLeftDiv, 'visible');
  }

  @HostListener('scroll', ['$event']) onScroll(event: any): void {
    const { scrollLeft, scrollWidth, clientWidth } = event.target ? event.target : event;
    const scrollLeftCeil = Math.ceil(scrollLeft);

    this.showLeftGradient = scrollLeftCeil > 0;
    this.showRightGradient = scrollLeftCeil + clientWidth < scrollWidth;
    this.gradientsPosition = scrollLeftCeil + clientWidth >= scrollWidth ? scrollWidth - clientWidth : scrollLeftCeil;

    this.setGradientElementsStyle();
  }
}
