import { BillingTaxTypes, InvoiceFlowDays, InvoiceFlows, InvoiceRevisionState } from '../enums';
import { AppraisalIndexModel } from './appraisal.model';
import { BillingData } from './billing-data.model';

export interface InvoiceIrpfSummary {
  IrpfPercentage: number;
  FeeTotal: number;
  IrpfTotal: number;
}

export interface InvoiceIvaSummary {
  IvaPercentage: number;
  FeeTotal: number;
  IvaTotal: number;
}

export interface InvoiceFilterModel {
  Summary?: string;
  ActivatedFiltersCount?: number;
  InvoiceId?: number;
  Year?: number;
  Month?: number;
  UserId?: number;
  IsConfirmed?: boolean;
  InvoiceNumberId?: string;
  RevisionState?: number;
  AppraisalContainsRecordId?: string;
  UserContainsNationalIdentityDocument?: string;
  UserContainsFirstName?: string;
  UserContainsLastName?: string;
  ContainsWorkflowItemId?: number;
  InvoiceFlows: InvoiceFlows;
  ConfirmationMaxDateTime: Date;
  ConfirmationMinDateTime: Date;
  GeneratedMaxDateTime: Date;
  GeneratedMinDateTime: Date;
}

export interface InvoiceAppraisalIndexModel {
  InvoiceId: number;
  WorkflowItemId: number;
  Fee: number;
  OtherExpenses: number;
  IvaPercentage: number;
  IvaRatio: number;
  IrpfPercentage: number;
  IrpfRatio: number;
  IvaTotal: number;
  IrpfTotal: number;
  FeeTotalWithTaxes: number;
  TotalWithTaxes: number;
  TotalWithoutTaxes: number;
  BillingTaxType: BillingTaxTypes;
  WorkflowItem: AppraisalIndexModel;
}

export enum DefaultInvoicesFiltersEnum {
  All = 0,
  PendingRequestConfirmation,
  ConfirmationSent,
  Confirmed,
  Rejected,
}

export enum DefaultInvoicesFiltersNameEnum {
  INVOICES_ALL_INVOICES = 0,
  INVOICES_PENDING_REQUEST_CONFIRMATION = 1,
  INVOICES_CONFIRMATION_SENT = 2,
  INVOICES_CONFIRMED = 3,
  INVOICES_REJECTED = 4,
}

////////////////////////////////////
// Nuevos modelos

export interface AppraiserInvoiceIndexModel {
  id: number;
  userId: number;
  year: number;
  month: number;
  invoiceNumberId: string;
  invoiceDateTime: string;
  revisionState: InvoiceRevisionState;
  revisionStateLastUpdatedDateTime: string;
  addedDateTime: string;
  ivaTotal: number;
  irpfTotal: number;
  feeTotalWithTaxes: number;
  totalWithTaxes: number;
  totalWithoutTaxes: number;
  userFirstName: string;
  userLastName: string;
  userFullName: string;
  userNationalIdentityDocument: string;
  userBillingTaxType: BillingTaxTypes;
  licenseeFullName: string;
  invoiceFlowDay: InvoiceFlowDays;
}

export interface AppraiserInvoiceModel {
  id: number;
  customerId: number;
  requesterCompanyId: number;
  supplierCompanyId: number;
  currentStepValue: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  addedDateTime: string;
  addedUserId: number;
  invoicedCompany: IBillingDataModel;
  invoicerCompany: IBillingDataModel;
  revisionStateMessage: string;
  lineItems: AppraisalInvoiceLineAppraiserModel[];
  invoiceSummaries: InvoiceSummary[];
  canConfirmInvoiceByAppraiser: boolean;
  canRejectInvoiceByAppraiser: boolean;
  canDownloadInvoiceByAppraiser: boolean;
  userId: number;
  year: number;
  month: number;
  invoiceNumberId: string;
  invoiceDateTime: string;
  revisionState: InvoiceRevisionState;
  revisionStateLastUpdatedDateTime: string;
  ivaTotal: number;
  irpfTotal: number;
  feeTotalWithTaxes: number;
  totalWithTaxes: number;
  totalWithoutTaxes: number;
  userFirstName: string;
  userLastName: string;
  userFullName: string;
  userNationalIdentityDocument: string;
  userBillingTaxType: BillingTaxTypes;
  licenseeFullName: string;
  invoiceFlowDay: InvoiceFlowDays;
}

export interface AppraisalInvoiceLineAppraiserModel {
  invoiceId: number;
  workflowItemId: number;
  fee: number;
  otherExpenses: number;
  ivaPercentage: number;
  ivaRatio: number;
  irpfPercentage: number;
  irpfRatio: number;
  ivaTotal: number;
  irpfTotal: number;
  feeTotalWithTaxes: number;
  totalWithTaxes: number;
  totalWithoutTaxes: number;
  billingTaxType: BillingTaxTypes;
  workflowItemRecordId: string;
  workflowItemExecutionToolId: string;
  workflowItemAddress: string;
  workflowItemValidationFinishDatetime: string;
}

export interface InvoiceSummary {
  percentage: number;
  feeTotal: number;
  total: number;
  isIva: boolean;
}

export interface IBillingDataModel {
  id: number;
  billingData: BillingData;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
}
