import { AssetSurfaceGroup, AssetSurfaceIds, AssetSurfaceTypes } from '../enums';

export interface AssetSurfaceSelectorModel {
  groups: AssetSurfaceSelectorGroupModel[];
  isVisible: boolean;
  isClickable: boolean;
  errors?: string[];
}

export class AssetSurfaceSelectorGroupModel {
  groupId!: AssetSurfaceGroup;
  surfaces: AssetSurfaceSelectorItemModel[] = [];
}

export class AssetSurfaceSelectorItemModel {
  name!: string;
  value!: number;
  id!: AssetSurfaceIds;
  groupId!: AssetSurfaceGroup;
  type!: AssetSurfaceTypes;
}
