import { Injectable } from '@angular/core';
import { getLanguageList, LanguageFile, LanguageSelectorList } from '@foxeet/domain';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  /**
   * Quizá se podría mover aquí todo lo relativo a los idiomas para setear el defaultLang, etc
   */
  constructor(private _platform: Platform, private _translateService: TranslateService) {}

  public getLanguageList(languagesAvailables: LanguageSelectorList) {
    const isMobilePlatform = !this._platform.is('mobileweb') && (this._platform.is('android') || this._platform.is('ios'));
    const languages = getLanguageList(languagesAvailables);
    return isMobilePlatform
      ? languages
      : languages.map((language) => ({
          ...language,
          label: `${language.flag} ${this._translateService.instant(language.label)}`,
        }));
  }
}
