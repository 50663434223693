import { Pipe, PipeTransform } from '@angular/core';
import { CultureSelectors, CultureSetted, getSuffixBySuffixTypeAndAssetUse } from '@foxeet/chests/culture';
import { AssetUses, CountryConfigurationEnumModel, CurrencyEnumModel, InternationalizationTypes } from '@foxeet/domain';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Pipe({ name: 'SuffixBySuffixTypeAndUsePipe' })
export class SuffixBySuffixTypeAndUsePipe extends UnsuscriptionHandler implements PipeTransform {
  private _countryConfig: CountryConfigurationEnumModel | null | undefined = null;
  private _cultureSetted: CultureSetted | null | undefined = null;
  private _currencies: CurrencyEnumModel[] | null | undefined = null;

  constructor(private readonly store: Store) {
    super();

    this.store
      .select(CultureSelectors.CultureCurrencies)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((currencies) => (this._currencies = currencies));

    this.store
      .select(CultureSelectors.CultureInUse)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((countryConfig) => (this._countryConfig = countryConfig));

    this.store
      .select(CultureSelectors.CultureSetted)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((cultureSetted) => (this._cultureSetted = cultureSetted));
  }

  private _getSuffix(suffixType: InternationalizationTypes, assetUse: AssetUses | null = null, cultureCode: string | null = null): string {
    const countryConfig = this._countryConfig ?? ({} as CountryConfigurationEnumModel);
    return getSuffixBySuffixTypeAndAssetUse(suffixType, assetUse, cultureCode, countryConfig, this._cultureSetted, this._currencies);
  }

  public transform(suffixType: InternationalizationTypes, assetUse: AssetUses | null = null, cultureCode: string | null = null): string {
    return this._getSuffix(suffixType, assetUse, cultureCode);
  }
}
