import { FormControl } from '@angular/forms';
import { AssetCreateModel, CountryModel } from '../../models';

export const ESP: CountryModel = {
  threeLetterIso: 'ESP',
  name: '',
  englishName: '',
  nativeName: '',
};

export const someFieldIsNull = (body: AssetCreateModel): boolean => {
  const requiredLocationFields: (keyof AssetCreateModel['assetLocalization'])[] = [
    'countryThreeLetterISORegionName',
    'province',
    'locality',
    'streetName',
    'streetNumber',
    'postalCode',
  ];
  const requiredTypologiesFields: (keyof AssetCreateModel['assetTypologies'])[] = ['use', 'typology'];

  const someLocationFieldsAreNull = requiredLocationFields.some((el) => body.assetLocalization[el] === null);
  const someTypologiesFieldsAreNull = requiredTypologiesFields.some((el) => body.assetTypologies[el] === null);

  return someLocationFieldsAreNull || someTypologiesFieldsAreNull;
};

export const CadastralNotValidPattern = (group: FormControl) => {
  if (group.value === null || group.value.length === 20 || group.value.length === 14) {
    return null;
  } else {
    return { pattern: true };
  }
};
