import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InternationalizationTypes } from '@foxeet/domain';

@Component({
  selector: 'core-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent implements AfterViewInit {
  public dataSource = new MatTableDataSource([]);
  public internationalizationTypes = InternationalizationTypes;
  @Input() tableConfig: any;
  @Input() disablePagination: boolean = false;
  @Input() set data(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
