<ion-item
  [ngClass]="{
    error: control && control.errors,
    'non-opacity': readonly,
    'input-has-value': control?.value !== null && control?.value !== undefined,
    suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === '')
  }"
  (click)="onInputSearchBar()"
>
  <ion-text [position]="labelPosition" *ngIf="label">{{ label | translate | capitalize }}</ion-text>
  <p *ngIf="control.value !== null && control.value !== undefined && control.value !== ''">
    {{ searcherLabel | translate }}
  </p>
  <ion-input
    label="{{ label ? (label | translate | capitalize) : '' }}"
    labelPlacement="floating"
    [id]="componentId"
    type="text"
    hidden
    [formControl]="control"
    [placeholder]="placeholder ?? undefined"
    (ionBlur)="control.updateValueAndValidity()"
  ></ion-input>
  <ion-icon name="caret-down-outline" slot="end" color="medium"></ion-icon>
</ion-item>

<ui-mobile-form-validation-messages *ngIf="showValidationMessages" [control]="control"></ui-mobile-form-validation-messages>
