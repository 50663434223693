import { Directive, ElementRef, forwardRef, Inject, Input, OnChanges, OnDestroy, Optional, Renderer2, SimpleChanges } from '@angular/core';
import { COMPOSITION_BUFFER_MODE, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CultureSelectors, CultureSetted } from '@foxeet/chests/culture';
import { InternationalizationTypes, OrNull } from '@foxeet/domain';
import { isNil, isNilOrEmptyString } from '@foxeet/utils/functions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MinMaxRestrictionDirective } from './min-max-restriction.directive';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[utilsNumberFormatterDirective]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DecimalFormatterDirective),
      multi: true,
    },
  ],
})
export class DecimalFormatterDirective extends MinMaxRestrictionDirective implements OnDestroy, OnChanges {
  private _componentDestroyed = new Subject();
  private _cultureSetted!: CultureSetted;
  private _cultureCode!: string;

  @Input() suffix?: string;
  @Input() suffixType!: InternationalizationTypes;

  constructor(
    renderer: Renderer2,
    elementRef: ElementRef,
    translateService: TranslateService,
    private readonly _store: Store,
    @Optional() @Inject(COMPOSITION_BUFFER_MODE) _compositionMode: boolean,
  ) {
    super(renderer, elementRef, translateService, _compositionMode);

    this._store.select(CultureSelectors.CultureCode).subscribe((culture) => {
      this._cultureCode = culture;
      const [value] = (elementRef?.nativeElement?.value ?? '').split(' ');
      this.writeValue(this.checkMinMaxAndSetValueIfIsInvalid(value));
    });

    this._store
      .select(CultureSelectors.CultureSetted)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((values) => {
        this._cultureSetted = values;
        const [value] = ((elementRef.nativeElement.value ?? '') as string).split(' ');
        this.writeValue(this.checkMinMaxAndSetValueIfIsInvalid(value));
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes?.suffix) {
      const [value] = (this._myElementRef.nativeElement.value ?? '').split(' ');
      this.writeValue(this.checkMinMaxAndSetValueIfIsInvalid(value));
    }
  }

  private _getSuffixType(): string {
    if (this.suffix) {
      return this.suffix;
    } else {
      switch (this.suffixType) {
        case InternationalizationTypes.Currency:
          return isNilOrEmptyString(this._cultureSetted.reportCurrency) ? this._cultureSetted.currency : this._cultureSetted.reportCurrency;
        case InternationalizationTypes.UnitLength:
          return this._cultureSetted.unitLength;
        case InternationalizationTypes.SurfaceUnit:
          return this._cultureSetted.surfaceUnit;
        case InternationalizationTypes.CurrencyPerSurfaceUnit:
          return isNilOrEmptyString(this._cultureSetted.reportCurrencyPerSurfaceUnit)
            ? this._cultureSetted.currencyPerSurfaceUnit
            : this._cultureSetted.reportCurrencyPerSurfaceUnit;
        default:
          return '';
      }
    }
  }

  public writeValue(value: OrNull<number>): void {
    let formattedValue = new DecimalPipe(this._translateService.currentLang).transform(value, this.digitsInfo);

    if (!isNil(formattedValue) && (!isNilOrEmptyString(this.suffix) || !isNil(this.suffixType))) {
      formattedValue = `${formattedValue} ${this._getSuffixType()}`;
    }

    this._myRenderer.setProperty(this._myElementRef.nativeElement, 'value', formattedValue ?? value);
  }

  public ngOnDestroy(): void {
    this._componentDestroyed.next('');
    this._componentDestroyed.complete();
  }
}
