import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AgendaModalDataModel, DateModel } from '../my-schedule/my-schedule.component';

/**
 * @TODO UNUSED COMPONENT
 */
@Component({
  selector: 'mcore-appraiser-agenda-detail',
  templateUrl: './appraiser-agenda-detail.component.html',
  styleUrls: ['./appraiser-agenda-detail.component.scss'],
})
export class AppraiserAgendaDetailComponent implements OnInit {
  /**
   * TODO: esto no funciona porque no existe la llamada a back (app-appraisal-card no existe la clase, sólo el template) ¿eliminar?
   */
  visitScheduled: any = [];
  deadlines: any = [];

  public data: AgendaModalDataModel;

  public today: DateModel;

  constructor(private nav: NavParams, private modal: ModalController) {}

  ngOnInit() {
    this.data = this.nav.data as AgendaModalDataModel;
    this.today = this.data.Today;
    this.visitScheduled = this.data.VisitScheduled;
    this.deadlines = this.data.Deadlines;
  }

  dismiss() {
    this.modal.dismiss();
  }
}
