import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetTypologies, AssetUses } from '@foxeet/domain';
import { FEATURE_NAME } from '../domain/Typology-chest.config';
import { TypologiesState } from './typology.state';

export const useMainSelector = createFeatureSelector<TypologiesState>(FEATURE_NAME);

// Se usa en el select
export const UseTypologies = (props: { use: AssetUses }) => createSelector(useMainSelector, (state) => state.use && state.use[props.use]);

export const UseSubTypologies = (props: { typology: AssetTypologies }) => createSelector(useMainSelector, (state) => state.typologies && state.typologies[props.typology]);
