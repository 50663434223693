import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';
import { FormToggleComponent } from './form-toggle.component';

@NgModule({
  declarations: [FormToggleComponent],
  imports: [CommonModule, I18nModule.forChild(), FormValidationMessagesModule, IonicModule, ReactiveFormsModule],
  exports: [FormToggleComponent],
})
export class FormToggleModule {}
