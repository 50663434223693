export class NumberUtils {
  static roundTwoDecimals(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  static checkMinMaxValue(value: number, min: number = Number.MIN_SAFE_INTEGER, max: number = Number.MAX_SAFE_INTEGER) {
    return value >= min && value <= max;
  }

  static setMinMaxValue(value: number, min: number = Number.MIN_SAFE_INTEGER, max: number = Number.MAX_SAFE_INTEGER) {
    value = max < value ? max : value;
    value = min > value ? min : value;
    return value;
  }
}
