import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';

@Component({
  selector: 'ui-my-work-modal',
  templateUrl: './my-work-modal.html',
  styleUrls: ['./my-work-modal.scss'],
})
export class MyWorkModal {
  constructor(public dialogRef: MatDialogRef<MyWorkModal>, @Inject(MAT_DIALOG_DATA) public data: any, private _dynamicConfigLoaderService: DynamicConfigLoaderService) {}
  public rediretToMobile(licenseId, appraisalId?) {
    const mobileEndPoint = this._dynamicConfigLoaderService.Options.environment.defaultMobileEndpoint;
    if (appraisalId) {
      window.open(`${mobileEndPoint}/orders?appraisalId=${appraisalId}&licenseId=${licenseId}`, '_blank');
    } else {
      window.open(`${mobileEndPoint}/orders?licenseId=${licenseId}`, '_blank');
    }
    this.dialogRef.close();
  }
}
