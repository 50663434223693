import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class OrderRequestPromotionalCodeService extends WorkflowService {
  constructor(http: HttpClient, cache?: CacheService) {
    super(http, 'OrderRequestPromotionalCode', cache);
  }
}
