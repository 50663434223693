import { BuildingQualityTypes, ConservationStateTypes, EnergyCertificationGrades, LegalSituationTypes } from '../enums';

// TODO: move to a new file
export interface AssetStateInformation {
  ConstructionYear: number;
  ConstructionYearToUse: number;
  ReformLastYear: number;
  HasEnergeticCertificate: boolean;
  EnergeticCertificateGrade: EnergyCertificationGrades;
  LegalSituation: LegalSituationTypes;
  QualityOfBuilding: BuildingQualityTypes;
  ConservationState: ConservationStateTypes;
  CurrentStateOfAsset: string;
}
export interface AssetStateInformationModel {
  Id: number;
  State: AssetState;
}

export interface AssetState {
  ConstructionYear: number;
  ReformLastYear: number;
  HasEnergeticCertificate: boolean;
  EnergeticCertificateGrade: EnergyCertificationGrades;
  LegalSituation: LegalSituationTypes;
  QualityOfBuilding: BuildingQualityTypes;
  ConservationState: ConservationStateTypes;
  CurrentStateOfAsset: string;
  ConstructionYearToUse: number;
}

/////////////////////////////////////////
