import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseFormMobileComponent } from '../../../base-components';

@Component({
  selector: 'ui-mobile-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
})
export class FormCheckboxComponent extends BaseFormMobileComponent implements OnInit {
  @Output() inputEmitter: EventEmitter<boolean> = new EventEmitter(false);

  get showValidationMessages(): boolean {
    return this.errorKeys?.length;
  }

  focus() {
    this.inputEmitter.emit(this.control.value);
  }
}
