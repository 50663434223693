export type OrNull<T> = T | null;

export type OrUndef<T> = T | undefined;

export type OrNullOrUndef<T> = OrNull<T> | OrUndef<T>;

export type PrimitiveTypes = string | number | boolean;
/**
 * Tipos de valores de un objeto
 */
export type ValueOf<T> = T[keyof T];
/**
 * Tipo de valores de una key del Objeto
 */
export type ValueOfKey<T, K extends keyof T> = T[K];
/**
 * Tipar Getters de forma generica sin discriminación (Se puede discriminar por tipos o mezclar para que solo se haga con especificos)
 */
export type Getters<Type> = {
  [Property in keyof Type as `get${Capitalize<string & Property>}`]: () => Type[Property];
};
/**
 * Tipar Setters de forma generica sin discriminación (Se puede discriminar por tipos o mezclar para que solo se haga con especificos)
 */
export type Setters<Type> = {
  [Property in keyof Type as `set${Capitalize<string & Property>}`]: (val: Type[Property]) => void;
};
/**
 * Generar Flags de un tipo
 */
export type OptionsFlags<Type> = {
  [Property in keyof Type]: boolean;
};
/**
 * Puedes añadir un evento para cuando se modique un parametro de un objeto
 */
export type PropEventSource<Type> = {
  on<Key extends string & keyof Type>(eventName: `${Key}Changed`, callback: (newValue: Type[Key]) => void): void;
};

/** @example */
/*
declare function makeWatchedObject<Type>(obj: Type): Type & PropEventSource<Type>;
const person = makeWatchedObject({
  firstName: 'Saoirse',
  lastName: 'Ronan',
  age: 26,
});

person.on('firstNameChanged', (newName) => {
  console.log(`new name is ${newName.toUpperCase()}`);
});
*/

/**
 * @Example ExampleTestIDsEnum
 */
export enum ExampleTestIDsEnum {
  BUTTON = 'btn',
  INPUT = 'input',
  // ...
}

/**
 * @Example HTMLId
 */
export type HTMLId<T extends string> = `AT_${Capitalize<T>}_${ExampleTestIDsEnum}`;
// GOOD
// const sTestHTMLId: HTMLId<'appraiser'> = 'AT_Appraiser_btn';
// BAD
// const bTestHTMLId: HTMLId<'asdasd'> = 'AT_asda_asdasd';
