import { Component, Input } from '@angular/core';

@Component({
  selector: 'mcore-label-with-icon',
  templateUrl: './label-with-icon.component.html',
  styleUrls: ['./label-with-icon.component.scss'],
})
/**
 * TODO: MOVE TO MCORE (MCore components use it)
 */
export class LabelWithIconComponent {
  @Input() iconColor = 'dark';
  @Input() iconName: string;
  @Input() labelColor = 'dark';
  @Input() label: string;
  @Input() labelWeight: 'w-light' | 'w-default' | 'w-bold' = 'w-default';
  @Input() labelSize: 's-small' | 's-default' | 's-big' = 's-default';

  @Input() fontSize = '14px';
  @Input() iconSize = 'small';
  @Input() showIcon = true;
  @Input() extraClass: string;
}
