import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { ApiBaseService2 } from '../services/api-base.service';
import { Observable } from 'rxjs';
import { AppraisalRevisionIndexModel } from '@foxeet/domain';
import { removeNullsAndUndefined } from '../utils/data-access.utils';

@Injectable()
export class AppraisalRevisionService extends ApiBaseService2 {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalRevision', cache);
  }

  // TODO: Change to ListAll/ with according filters
  public listAllByAppraisalId(appraisalId: string | number): Observable<HttpResponse<AppraisalRevisionIndexModel[]>> {
    return this.http.get<AppraisalRevisionIndexModel[]>('ListAllByAppraisalId', {
      observe: 'response',
      params: this.setParams({ appraisalId }),
    });
  }

  public setAsResolvedById(revisionId: number, workflowItemId: string | number, solutionDescription: string): Observable<null> {
    const body = removeNullsAndUndefined({
      RevisionId: revisionId,
      WorkflowItemId: workflowItemId,
      SolutionDescription: solutionDescription,
    });
    return this.post<typeof body, null>('SetAsResolved', body);
  }
}
