<ion-item
  [ngClass]="{
    error: control && control.errors,
    'non-opacity': readonly,
    'item-has-value': control?.value !== null && control?.value !== undefined,
    suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === '') && control?.enabled
  }"
  [formGroup]="form"
>
  <!-- <ion-label color="warning" [position]="labelPosition" *ngIf="label">{{ label | translate: translateArgs | capitalize }}</ion-label> -->
  <ion-select
    [ngClass]="{
    'select-has-value': control?.value !== null && control?.value !== undefined,
  }"
    toggleIcon="caret-down-sharp"
    label="{{ label ? (label | translate : translateArgs | capitalize) : '' }}"
    labelPlacement="floating"
    [id]="componentId"
    [cancelText]="'ACTIONS_cancel' | translate"
    [multiple]="isMultipleSelection"
    [formControlName]="controlName"
    [placeholder]="placeholder ?? undefined"
    [interface]="isMultipleSelection ? '' : interface"
    [selectedText]="selectedText | translate"
    (ionChange)="changesEmitter($event)"
    (ionBlur)="validateControl()"
  >
    <ion-select-option *ngFor="let option of options" [value]="optionValueAttr ? option[optionValueAttr] : option" [disabled]="!!option.disabled">
      {{ option[optionLabelAttr] | translate : translateArgs }}
    </ion-select-option>
  </ion-select>
</ion-item>

<ui-mobile-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-mobile-form-validation-messages>
