import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { faCheck, faTimes, faClock } from '@fortawesome/free-solid-svg-icons';
import { merge, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { NDataSource, WebFilter } from '@foxeet/utils/classes';
import { FilterModel } from '@foxeet/domain';
import { TableCellType, TableColumn, TableConfig } from '../../domain/table.model';

export class NotDefinedFilter extends WebFilter {
  constructor(filterName: string, defaultFilter?: FilterModel) {
    super(filterName, defaultFilter);
  }
}

export enum RowType {
  Link,
  ClickableNotExpansible,
  ClickableExpansible,
}

@Component({
  selector: 'core-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss', './include-media.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GenericTableComponent extends UnsuscriptionHandler implements OnInit {
  public expandedElement: { id?: any; clickEvent?: (arg0: any) => void } | undefined;

  TableCellType = TableCellType;

  faCheck = faCheck;
  faTimes = faTimes;
  faClock = faClock;

  private _firstLoad = true;
  private sortPaginatorObservable: Observable<Sort | PageEvent>;
  private selectionChangeObservable: Observable<SelectionChange<any>>;
  private sortObservable: Observable<Sort>;

  public selection = new SelectionModel<any>(true, []);

  @Input() title: string;
  @Input() noContentLabel: string;
  @Input() filter: WebFilter = new NotDefinedFilter('NotDefinedFilter');
  @Input() tableConfig: TableConfig;
  @Input() dataSource: NDataSource<any, any, any, any>;
  @Input() isPaginated = true;
  @Input() showSelectAll = true;
  @Input() hideIfDatasourceIsEmpty = false;
  @Input() tableId: string;
  @Input() pageSizeOptions = [5, 10, 20, 40, 50];
  @Input() multiple: boolean;

  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  private _paginator!: MatPaginator;
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this._paginator = paginator;

      const { size, page } = this.filter.getCurrentFilter();
      this._paginator.pageIndex = page - 1;
      this._paginator.pageSize = size;
      this.setEventsObservable();
    }
  }

  private _sort: MatSort;
  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (sort) {
      this._sort = sort;
      this.setEventsObservable();
    }
  }

  ngOnInit() {
    if (!this.multiple) {
      this.selection = new SelectionModel<any>(this.multiple, []);
    }
    if (this.dataSource.entitySubject) {
      this.dataSource.entitySubject.pipe(takeUntil(this._componentDestroyed)).subscribe((res) => {
        if (this._firstLoad && !res?.length) {
          this._firstLoad = false;
        }
      });
    }

    if (this.dataSource.loadData) {
      this.dataSource.loadData();
    }
  }

  isExpansionDetailRow = (i: number, row: Record<string, any>) => !!row.detailRow;

  setSpinner(state: boolean) {
    this.dataSource.loadingSubject.next(state);
  }

  setDynamicRowValues(column: TableColumn, row: Record<string, unknown>): unknown[] {
    return column.dynamicValues
      ? Object.keys(row)
          .filter((key) => column.dynamicValues?.includes(key))
          .map((key) => row[key])
      : [];
  }

  setEventsObservable() {
    if (!this.selectionChangeObservable) {
      this.selectionChangeObservable = this.selection.changed.pipe(takeUntil(this._componentDestroyed));
      this.selectionChangeObservable.subscribe((selected) => {
        this.selectionChange.emit(selected.source.selected);
      });
    }

    if (this._sort && this._paginator) {
      if (!this.sortObservable) {
        this.sortObservable = this._sort.sortChange.pipe(takeUntil(this._componentDestroyed));
        this.sortObservable.subscribe((sort: Sort) => {
          this._paginator.pageIndex = 0;
          const newFilterData = {
            ...this.filter.getCurrentFilterData(),
            orderFieldName: sort.active,
            orderFieldAsc: sort.direction === 'asc',
          };
          this.filter.setCurrentFilterData(newFilterData);
        });
      }
      if (!this.sortPaginatorObservable) {
        this.sortPaginatorObservable = merge(this._sort.sortChange, this._paginator.page).pipe(
          takeUntil(this._componentDestroyed),
          tap(() => {
            const pageIndex = this._paginator.pageIndex + 1;
            this.saveFilter(this._paginator.pageSize, pageIndex);
            this.dataSource.loadData();
          }),
        );
        this.sortPaginatorObservable.subscribe();
      }
    }
  }

  private saveFilter(pagesize: number | undefined, index: number | undefined) {
    const filter = this.filter.getCurrentFilter();
    filter.size = pagesize;
    filter.page = index;
    this.filter.setCurrentFilter(filter);
  }

  onRowClick(row: { clickEvent: (arg0: any) => void }) {
    if (this.tableConfig.expandableRows) {
      if (this.expandedElement === row) {
        this.expandedElement = undefined;
      } else {
        this.expandedElement = row;
      }
    } else {
      row.clickEvent(row);
      this.rowClickEvent.emit(row);
    }
  }

  /** Generalizar propiedad (?) */
  isAllSelected() {
    const numRows = this.dataSource.data.length;
    const pageItemsSelected = this.dataSource.data.filter((el) => this.selection.selected.includes(el.id));
    return pageItemsSelected.length === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // Cada vez que se hace un select se notifica un evento de salida al padre con los seleccionados.
  // Cambiado un único select con una lista de ids
  masterToggle() {
    if (this.multiple) {
      if (this.isAllSelected()) {
        this.selection.clear();
      } else {
        // this.dataSource.data.forEach((row) => this.selection.select(row.id));
        // Los ids deberían ser números
        const idsToSelect: number[] = this.dataSource.data.map((el) => el.id);
        this.selection.select(...idsToSelect);
      }
    }
  }

  clickedSelectedOption(option: { callback: (...arg0: any) => void }, row: any, index: number) {
    option.callback(row, index);
  }

  updateSelection(selection: number[]) {
    // this.selection.deselect is broken.
    this.selection.clear();
    selection.forEach((id) => this.selection.select(id));
  }

  isExpanded(row: { id: any }) {
    return this.expandedElement?.id === row?.id;
  }
}
