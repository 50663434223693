import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { I18nModule } from '@foxeet/utils-modules';
import { ExpandableFilterService } from '../provider/expandable-filters.service';
import { ExpandableFiltersButtonComponent } from './components/button/expandable-filters-button.component';
import { ExpandableFiltersPanelComponent } from './expandable-filters-panel.component';

@NgModule({
  declarations: [ExpandableFiltersPanelComponent, ExpandableFiltersButtonComponent],
  imports: [CommonModule, FontAwesomeModule, MatTooltipModule, I18nModule.forChild()],
  providers: [ExpandableFilterService],
  exports: [ExpandableFiltersPanelComponent, ExpandableFiltersButtonComponent],
})
export class ExpandableFiltersModule {}
