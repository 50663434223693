import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { isNil, isNilOrEmptyString } from '@foxeet/utils/functions';
import { BaseFormMobileComponent } from '../../../base-components';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ui-mobile-form-dropdown',
  templateUrl: './form-dropdown.component.html',
  styleUrls: ['./form-dropdown.component.scss'],
})
export class FormDropdownComponent extends BaseFormMobileComponent implements OnInit, OnChanges {
  public isNilOrEmptyString = isNilOrEmptyString;
  public isNil = isNil;
  public selectedText?: string;

  @Input() interface = 'action-sheet';
  @Input() isMultipleSelection = false;
  @Input() optionValueAttr = 'key';
  @Input() optionLabelAttr = 'translateKey';
  @Input() defaultValue: any;
  @Input() translateArgs: any;
  @Input() emitChanges = false;
  @Input() options!: any[];

  @Output() changes: EventEmitter<any> = new EventEmitter();

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef, private readonly _ngZone: NgZone) {
    super();
    this.valueChanges.pipe(takeUntil(this._componentDestroyed)).subscribe(() => this._calculateSelectedText());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options?.currentValue.length) {
      this._changeDetectorRef.detectChanges();
      if (this.control?.value) this._calculateSelectedText();
    }

    if (changes.defaultValue?.currentValue) {
      this.control?.setValue(changes.defaultValue?.currentValue);
    }
  }

  private _calculateSelectedText() {
    const foundItem = this.options?.find((el: any) => el.key === this.control?.value);
    this.selectedText = foundItem ? foundItem[this.optionLabelAttr] : undefined;
  }

  public changesEmitter($event: Event) {
    this.changes.emit(($event as any).detail.value);
  }

  public validateControl() {
    if (!this.control.value && this.control.errors) {
      this.control.updateValueAndValidity();
    }
  }
}
