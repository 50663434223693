<a *ngIf="location" (click)="openLocation()">
  <ion-row class="ion-align-items-center">
    <ion-col size="2" class="icon-wrapper">
      <ion-icon name="location-outline"></ion-icon>
    </ion-col>
    <ion-col size="10">
      <ion-row class="subtitle text-lighted">{{ title | translate: internationalizationType | uppercase }}</ion-row>
      <ion-row class="text-default-bold text-darked">{{ subTitle | translate: internationalizationType | uppercase }}</ion-row>
    </ion-col>
  </ion-row>
</a>

<ion-row *ngIf="!location" class="ion-align-items-center">
  <ion-col size="2" class="icon-wrapper">
    <ion-icon name="location-outline"></ion-icon>
  </ion-col>
  <ion-col size="10">
    <ion-row class="subtitle text-lighted">{{ title | translate: internationalizationType | uppercase }}</ion-row>
    <ion-row class="text-default-bold text-darked">{{ subTitle | translate: internationalizationType | uppercase }}</ion-row>
  </ion-col>
</ion-row>
