import { WorkflowService } from './workflow.service';
import { HttpClient } from '@angular/common/http';
import { CacheService } from './cache.service';
import { Observable } from 'rxjs';
import { RelatedDeliverableDto } from '@foxeet/domain';

export abstract class ParticipantsAndWorkflowService extends WorkflowService {
  protected constructor(http: HttpClient, apiBranch: string, cache?: CacheService) {
    super(http, apiBranch, cache);
  }

  getBillingDataByUserId<T>(id: number): Observable<T> {
    return this.get<T>(`${id}/BillingData`);
  }

  putBillingDataByUserId<T>(id: number, body: any): Observable<T> {
    return this.put<typeof body, T>(`${id}/BillingData`, body);
  }

  getDeliverablesByUserId(id: number) {
    return this.get<RelatedDeliverableDto[]>(`${id}/Deliverables`);
  }

  setDeliverablesByUserId(id: number, body: RelatedDeliverableDto[]) {
    return this.put(`${id}/Deliverables`, body);
  }
}
