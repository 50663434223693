import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { ChangeTemporalPasswordComponent } from './auth/components/change-temporal-password/change-temporal-password.component';
import { LoginComponent } from './auth/components/login/login.component';
import { PasswordRestoreComponent } from './auth/components/password-restore/password-restore.component';
import { LoadingInterceptorService } from './auth/interceptors/mobile-loading.interceptor';
import { ActionsContainerComponent } from './components/actions-container/actions-container.component';
import { AddressComponent } from './components/address/address.component';
import { AppraiserAgendaDetailComponent } from './components/agenda/appraiser-agenda-detail/appraiser-agenda-detail.component';
import { MobileCalendarComponent } from './components/agenda/calendar/calendar.component';
import { MyScheduleComponent } from './components/agenda/my-schedule/my-schedule.component';
import { CardComponent } from './components/card/card.component';
import { ChipsSliderComponent } from './components/chips-slider/chips-slider.component';
import { DateDisplayComponent } from './components/date-display/date-display.component';
import { FormLoginComponent } from './components/form-login/form-login.component';
import { FormRecoverPasswordComponent } from './components/form-recover-password/form-recover-password.component';
import { GenericSelectorComponent } from './components/generic-dropdown/generic-dropdown.component';
import { GenericListPagedComponent } from './components/generic-list-paged';
import { InternationalizationModalComponent } from './components/internationalization-modal/internationalization-modal.component';
import { LabelWithIconComponent } from './components/label-with-icon/label-with-icon.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { MenuFooterComponent } from './components/menu-footer/menu-footer.component';
import { MenuHeaderComponent } from './components/menu-header/menu-header.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { DebugModalComponent } from './modals/debug-modal/debug-modal.component';
import { AuthTokenInterceptor, LicenseInterceptor, PlatformInterceptor, VersionInterceptor } from '@foxeet/feature/auth';
import { CoreModule } from '@foxeet/core';
import { FormInputsMobileModule, MobileBasicContainerModule, MobileChipModule, StarsRatingModule } from '@foxeet/ui';
import { InternationalizationButtonComponent } from './components/internationalization-button/internationalization-button.component';
import { ContactUsModalComponent } from './modals/contact-us-modal/contact-us-modal.component';
import { MobileCalendarInfoComponent } from './components/agenda/calendar-info/calendar-info.component';

const agenda = [AppraiserAgendaDetailComponent, MyScheduleComponent, MobileCalendarComponent, MobileCalendarInfoComponent];

const formInputs = [GenericSelectorComponent];
const components = [
  ChangeTemporalPasswordComponent,
  FormLoginComponent,
  FormRecoverPasswordComponent,
  PasswordRestoreComponent,
  LanguageSelectorComponent,
  UserProfileComponent,
  DateDisplayComponent,
  NavigationBarComponent,
  InternationalizationModalComponent,
  CardComponent,
  ChipsSliderComponent,
  GenericListPagedComponent,
  ActionsContainerComponent,
  AddressComponent,
  MenuItemComponent,
  MenuHeaderComponent,
  MenuComponent,
  MenuFooterComponent,
  LoginComponent,
  LabelWithIconComponent,
  InternationalizationButtonComponent,
  ...agenda,
];

const modals = [DebugModalComponent, ContactUsModalComponent];

const exportables = [...formInputs, ...components, ...modals];

const UI_MODULES = [FormInputsMobileModule, MobileBasicContainerModule, MobileChipModule, StarsRatingModule];

@NgModule({
  imports: [CoreModule, IonicModule, SwiperModule, I18nModule.forChild(), PipesModule, ...UI_MODULES],
  declarations: [...components, ...formInputs, ...modals],
  exports: [...exportables],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LicenseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true,
    },
  ],
})
export class MCoreModule {}
