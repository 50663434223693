import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Params, Router } from '@angular/router';
import { selectQueryParams, selectRouteData, selectRouteParams, selectUrl } from '../redux';
import { Subscription } from 'rxjs';
import { isNil } from 'lodash';

@Injectable()
export class UrlDataService implements OnDestroy {
  private subs$ = new Subscription();

  private _queryParams: Params = {};
  private _routeParams: Params = {};
  private _routeData: Params = {};
  private _url = '';

  constructor(private _router: Router, private store: Store) {
    this.subs$.add(this.store.select(selectQueryParams).subscribe((queryParams) => (this._queryParams = queryParams)));
    this.subs$.add(this.store.select(selectRouteParams).subscribe((routeParams) => (this._routeParams = routeParams)));
    this.subs$.add(this.store.select(selectRouteData).subscribe((routeData) => (this._routeData = routeData)));
    this.subs$.add(this.store.select(selectUrl).subscribe((url) => (this._url = url)));
  }

  public getAllDataFromUrl(): Params {
    return this._queryParams;
  }

  public getDataByQueryParamId(queryParamId: string): Params | null {
    return isNil(this._queryParams) || isNil(this._queryParams[queryParamId]) ? null : JSON.parse(this._queryParams[queryParamId]);
  }

  public getUrlWithoutData(): string {
    return this._url.split('?')[0];
  }

  public saveDataIntoUrlAndNavigate(queryParamName: string, data: Params): void {
    const paramsEncoded = { ...this.getAllDataFromUrl(), [queryParamName]: JSON.stringify(data) };
    this._router.navigate([location.pathname], { queryParams: paramsEncoded, replaceUrl: true });
  }

  public ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
