import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';

/**
 *
 * @param isEnabled Habilitar
 * @param isProduction Si es producción
 * @constructor Construye el LoggerService
 *
 * @example Error Handler
 *
 * providers:[{
 *       provide: LOGGER_CONFIG,
 *       useValue: { isEnabled: !environment.production, isProduction: environment.production },
 * },{
 *   provide: ErrorHandler,
 *   useClass: LoggerService,
 * },
 * ]
 *
 * @example Global Log
 *  **app.module.ts**
 * providers:[{
 *       provide: LOGGER_CONFIG,
 *       useValue: { isEnabled: !environment.production, isProduction: environment.production },
 * }]
 */
export type LoggerConfig = {
  isEnabled: boolean;
  isProduction: boolean;
};

export const LOGGER_CONFIG = new InjectionToken<LoggerConfig>('Logger Config');

@Injectable({ providedIn: 'root' })
export class LoggerService implements ErrorHandler {
  protected console?: Console;

  constructor(@Inject(LOGGER_CONFIG) config: LoggerConfig = { isEnabled: true, isProduction: false }) {
    this.console = config.isEnabled ? console : undefined;
    if (!config.isEnabled && !config.isProduction) {
      // eslint-disable-next-line no-restricted-syntax
      console.info(`${this.constructor.name} is Disabled`);
    }
  }

  assert(condition?: boolean, message?: string, ...optionalParams: unknown[]): void {
    this.console?.assert(condition, message, optionalParams);
  }

  clear(): void {
    this.console?.clear();
  }

  count(label?: string): void {
    this.console?.count(label);
  }

  countReset(label?: string): void {
    this.console?.countReset(label);
  }

  debug(message?: unknown, ...optionalParams: unknown[]): void {
    this.console?.debug(message, optionalParams);
  }

  dir(obj?: unknown, options?: unknown): void {
    this.console?.dir(obj, options);
  }

  dirxml(...data: unknown[]): void {
    this.console?.dirxml(data);
  }

  error(message?: unknown, ...optionalParams: unknown[]): void {
    this.console?.error(message, optionalParams);
  }

  group(...label: unknown[]): void {
    this.console?.group(label);
  }

  groupCollapsed(...label: unknown[]): void {
    this.console?.groupCollapsed(label);
  }

  groupEnd(): void {
    this.console?.groupEnd();
  }

  info(message?: unknown, ...optionalParams: unknown[]): void {
    this.console?.info(message, optionalParams);
  }

  log(message?: unknown, ...optionalParams: unknown[]): void {
    this.console?.log(message, optionalParams);
  }

  table(tabularData?: unknown, properties?: string[]): void {
    this.console?.table(tabularData, properties);
  }

  time(label?: string): void {
    this.console?.time(label);
  }

  timeEnd(label?: string): void {
    this.console?.timeEnd(label);
  }

  timeLog(label?: string, ...data: unknown[]): void {
    this.console?.timeLog(label, data);
  }

  timeStamp(label?: string): void {
    this.console?.timeStamp(label);
  }

  trace(message?: unknown, ...optionalParams: unknown[]): void {
    this.console?.trace(message, optionalParams);
  }

  warn(message: unknown, ...optionalParams: unknown[]): void {
    this.console?.warn(message, optionalParams);
  }

  public handleError(error: unknown): void {
    this.error(error);
  }
}
