<span class="md-inputfield p-float-label my-4">
  <form [formGroup]="form" class="form">
    <div class="wrapper p-float-label" #container>
      <p-multiSelect
        [ngClass]="{ 'p-disabled': disabled }"
        [id]="componentId"
        [appendTo]="appendToBody ? 'body' : container"
        [options]="optionList"
        [formControlName]="controlName"
        [virtualScroll]="shouldVirtualScroll"
        [itemSize]="30"
        [filter]="shouldDisplayFilter"
        [filterBy]="filterBy"
        (onChange)="getLabelResume($event.value)"
        (onPanelShow)="opened = true"
        (onPanelHide)="opened = false"
      >
        <ng-template let-selectedList pTemplate="selectedItems">
          <div class="select-item-wrapper">
            <span *ngIf="selectedList && selectedList.length" class="overflow">
              {{ labelResume }}
            </span>
            <span *ngIf="!selectedList || !selectedList.length" class="float-label" [ngClass]="{ 'float-effect': opened || (selectedList && selectedList.length) }">{{
              label
            }}</span>
          </div>
        </ng-template>
        <ng-template let-item let-i="index" pTemplate="item">
          <span class="item">{{ item?.label | translate }}</span>
        </ng-template>
      </p-multiSelect>
      <label>{{ label | translate }}</label>
    </div>
  </form>
  <ui-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-form-validation-messages>
</span>
