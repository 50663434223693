import { CountryConfigurationEnumModel, CultureAndLanguage, CurrencyEnumModel, EnumsModel, Languages, ReportLanguages } from '@foxeet/domain';
import { FormUtils } from '../to-delete/bucket';

export interface CultureState {
  inUse?: CountryConfigurationEnumModel;
  loaded: CountryConfigurationEnumModel[];
  setted: CultureSetted;
  currencies: CurrencyEnumModel[];
  countryISO: string;
  languages: EnumsModel[];
  reportLanguages: EnumsModel[];
}

export interface CultureSetted extends CultureAndLanguage {
  currency: string;
  reportCultureCode: string;
  reportCurrency: string;
  reportCurrencyPerSurfaceUnit: string;
  surfaceUnit: string;
  unitLength: string;
  currencyPerSurfaceUnit: string;
}

export const initialState: CultureState = {
  loaded: [],
  setted: {
    currency: '',
    reportCultureCode: '',
    reportCurrency: '',
    surfaceUnit: '',
    unitLength: '',
    currencyPerSurfaceUnit: '',
    reportCurrencyPerSurfaceUnit: '',
    cultureCodeCurrency: 'es-ES',
    cultureCodeLanguage: 'es-ES',
  },
  currencies: [],
  countryISO: 'ESP',
  languages: FormUtils.fromEnumToKeyValueArray(Languages, 'Languages'),
  reportLanguages: FormUtils.fromEnumToKeyValueArray(ReportLanguages, 'Languages'),
};
