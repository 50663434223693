import { AlertController, Platform } from '@ionic/angular';

export const contactUsPhone = async (phone: string, phoneDisplay: string, platform: Platform, alertController: AlertController) => {
  if (!platform.is('desktop') && (platform.is('ios') || platform.is('android'))) {
    window.location.href = `tel:${phone}`;
  } else {
    const alert = alertController.create({ message: phoneDisplay });
    (await alert).present();
  }
};
