import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { CacheParams, CacheService } from './cache.service';
import { removeNullsAndUndefined } from '../utils/data-access.utils';

export type HttpConfig = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'response' | 'events' | 'body';
  reportProgress?: boolean;
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
};

export class BaseService {
  constructor(protected http: HttpClient, protected cache?: CacheService) {}

  get<T = unknown>(url: string, config?: HttpConfig, cache?: CacheParams) {
    return this.simpleCallRequest<T>('GET', url, config, cache);
  }

  post<A = unknown, B = unknown>(url: string, body?: A, config?: HttpConfig, cache?: CacheParams) {
    return this.settingCallRequest<A, B>('POST', url, body, config, cache);
  }

  put<A = unknown, B = unknown>(url: string, body?: A, config?: HttpConfig, cache?: CacheParams) {
    return this.settingCallRequest<A, B>('PUT', url, body, config, cache);
  }

  patch<A = unknown, B = unknown>(url: string, body: A, config?: HttpConfig, cache?: CacheParams) {
    return this.settingCallRequest<A, B>('PATCH', url, body, config, cache);
  }

  delete<T = unknown>(url: string, config?: HttpConfig, cache?: CacheParams) {
    return this.simpleCallRequest<T>('DELETE', url, config, cache);
  }

  simpleCallRequest<T>(method: 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS', url: string, config?: HttpConfig | any, cache?: CacheParams) {
    const req = new HttpRequest(method, url, config);
    return this.sendRequest<void, T>(req, config?.observe, cache);
  }

  settingCallRequest<A, B>(method: 'POST' | 'PUT' | 'PATCH', url: string, body?: A, config?: HttpConfig | any, cache?: CacheParams) {
    const bodyWithoutNullValues = removeNullsAndUndefined(body) as A;
    const req = new HttpRequest<typeof body>(method, url, bodyWithoutNullValues, config);
    return this.sendRequest<typeof body, B>(req, config?.observe, cache);
  }

  sendRequest<A, B>(req: HttpRequest<A>, observe: HttpConfig['observe'] = 'body', cache?: CacheParams) {
    const cacheKey = `${req.method}-${req.urlWithParams}`;
    const resCache = this.cache?.getCacheData<B>(cacheKey);

    return resCache
      ? of(resCache)
      : this.http.request<B>(req).pipe(
          filter((res) => res instanceof HttpResponse),
          map((res) => (res as HttpResponse<B>).body as B),
          tap((data) => {
            if (cache) {
              this.cache?.setCacheData(cacheKey, { ...cache, data });
            }
          }),
          take(1),
        );
  }
}
