import { Component, Input } from '@angular/core';
import { UnsuscriptionHandler } from '@foxeet/utils/components';

// import { applyMixins } from '@foxeet/utils/functions';

@Component({ template: '' })
export abstract class BaseComponent {
  @Input() componentId?: string;
}

/**
 * BaseWithUnsubComponent MIXIN
 * @see https://www.typescriptlang.org/docs/handbook/mixins.html
 */
@Component({ template: '' })
export abstract class BaseWithUnsubComponent extends UnsuscriptionHandler {
  @Input() componentId?: string;
}

/*
export interface BaseWithUnsubComponent extends BaseComponent, UnsuscriptionHandler {}

applyMixins(BaseWithUnsubComponent, [BaseComponent, UnsuscriptionHandler]);
*/
