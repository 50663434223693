<span class="md-inputfield input-container p-float-label" [formGroup]="form">
  <!-- Mask combinations
    a - Alpha character (defaut: A-Z,a-z)
    9 - Numeric character (0-9)
    * - Alpha numberic character (A-Z,a-z,0-9)
   -->
  <p-inputMask
    [ngClass]="{ 'p-disabled': control?.disabled || disabled }"
    [id]="componentId"
    [mask]="mask"
    [slotChar]="slotChar"
    [formControlName]="controlName"
    [autoClear]="shouldAutoClear"
  ></p-inputMask>

  <label>{{ label | translate }}</label>
  <ui-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-form-validation-messages>
</span>
