import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { dynamicInjector } from '@foxeet/utils/functions';
import { DynamicConfigLoaderService, I18nModule, PipesModule } from '@foxeet/utils-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { ComponentsModule } from './components';
import { enLang, esLang, ptLang } from './i18n';
import { MaterialModule } from './material/material.module';
import { AuthModule, AuthTokenInterceptor, LicenseInterceptor, PlatformInterceptor, VersionInterceptor } from '@foxeet/feature/auth';
import { Environment } from '@foxeet/domain';
import { StarsRatingModule } from '@foxeet/ui';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    I18nModule.forChild(),
    ToastrModule.forRoot(),
    MaterialModule,
    AuthModule,
    PipesModule,
    ComponentsModule,
    StarsRatingModule,
  ],
  exports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MaterialModule, FontAwesomeModule, AuthModule, ComponentsModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LicenseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: MAT_DIALOG_DATA,
      multi: true,
    },
  ],
})
export class CoreModule {
  static injector: Injector;
  static environment: Environment;

  constructor(private injector: Injector, private _multiModuleService: DynamicConfigLoaderService, private _translateService: TranslateService) {
    CoreModule.environment = this._multiModuleService.Options.environment;
    CoreModule.injector = this.injector;
    dynamicInjector(injector);
    if (!this._translateService.getLangs()?.length) {
      const moduleLanguages = [enLang, esLang, ptLang];
      moduleLanguages.forEach((lang) => this._translateService.setTranslation(lang.languageKey, lang.translates, true));
    }
  }
}
