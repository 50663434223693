export const FilterInputDatepickerTypes = {
  FROM: 'FROM',
  TO: 'TO',
};

// export const FilterInputTypes = {
//   COMBO: 'COMBO',
//   COMBO_FILTER: 'COMBO_FILTER',
//   TEXT: 'TEXT',
//   DATE_PICKER: 'DATE_PICKER',
//   MULTISELECT: 'MULTISELECT',
//   SEARCHER_MULTISELECT: 'SEARCHER_MULTISELECT',
//   SEARCHER_SELECT: 'SEARCHER_SELECT',
// };
