import { NgModule } from '@angular/core';
import { MobileFormInputDropdownModule } from './form-dropdown';
import { MobileFormInputCalendarModule } from './form-input-calendar';
import { MobileFormInputCheckboxModule } from './form-input-checkbox';
import { MobileFormInputFileModule } from './form-input-file';
import { MobileFormInputNumberModule } from './form-input-number';
import { MobileFormInputPasswordModule } from './form-input-password';
import { MobileFormInputRadioListModule } from './form-input-radio-list';
import { MobileFormInputSearcherModule } from './form-input-searcher';
import { MobileFormInputTextModule } from './form-input-text';
import { MobileFormInputTextareaModule } from './form-input-text-area';
import { MobileFormInputTripleSwitchModule } from './form-input-triple-switch';
import { MobileFormInputProposedDatesModule } from './form-proposed-dates';
import { MobileFormInputRangeModule } from './form-range';
import { MobileFormInputToggleModule } from './form-toggle';
import { MobileFormInputValidationMessagesModule } from './form-validation-messages';

const Modules = [
  MobileFormInputDropdownModule,
  MobileFormInputDropdownModule,
  MobileFormInputCalendarModule,
  MobileFormInputCheckboxModule,
  MobileFormInputFileModule,
  MobileFormInputNumberModule,
  MobileFormInputPasswordModule,
  MobileFormInputRadioListModule,
  MobileFormInputSearcherModule,
  MobileFormInputTextModule,
  MobileFormInputTextareaModule,
  MobileFormInputTripleSwitchModule,
  MobileFormInputProposedDatesModule,
  MobileFormInputRangeModule,
  MobileFormInputToggleModule,
  MobileFormInputValidationMessagesModule,
];

@NgModule({
  imports: Modules,
  exports: Modules,
})
export class FormInputsMobileModule {}
