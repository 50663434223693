import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegistryRequestModel } from '@foxeet/domain';
import { CacheService } from '../services/cache.service';
import { removeNullsAndUndefined } from '../utils/data-access.utils';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class SimpleNotesService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'RegistryRequests', cache);
  }

  setToSentToRegister(id: number): Observable<RegistryRequestModel> {
    return this.put<null, RegistryRequestModel>(`${id}/SetAsSentToRegister`, null);
  }

  setAsSuccessReceivedFromRegister(id: number): Observable<RegistryRequestModel> {
    return this.put<null, RegistryRequestModel>(`${id}/SetAsSuccessReceivedFromRegister`, null);
  }

  setAsFailedReceivedFromRegister(id: number, errorType: number, errorMessage: string): Observable<RegistryRequestModel> {
    const body = removeNullsAndUndefined({ id, errorType, errorMessage });
    return this.put<typeof body, RegistryRequestModel>(`${id}/SetAsFailedReceivedFromRegister`, body);
  }

  setToDeliveredToClient(id: number): Observable<RegistryRequestModel> {
    return this.put<null, RegistryRequestModel>(`${id}/SetAsDeliveredToClient`, null);
  }

  addRegistryFile(id: number, file: Blob): Observable<HttpEvent<RegistryRequestModel>> {
    const formData = new FormData();
    formData.append('document', file);
    // return this._APIBaseService.putMultipartFile(`${this.getUrlTarget()}/${id}/Documents`, formData);
    return this.http.put<RegistryRequestModel>(`${this.apiBranch}/${id}/Documents`, formData, {
      observe: 'response',
      responseType: 'json',
    });
  }

  removeRegistryFile(id: number) {
    return this.delete<RegistryRequestModel>(`${id}/Documents`);
  }
}
