import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@foxeet/utils-modules';

import { FormValidationMessagesModule } from '../../form-validation-messages/web/form-validation-messages.module';
import { FormInputDropdownComponent } from './form-input-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [FormInputDropdownComponent],
  imports: [CommonModule, DropdownModule, ReactiveFormsModule, I18nModule.forChild(), FormValidationMessagesModule],
  exports: [FormInputDropdownComponent],
})
export class FormDropdownModule {}
