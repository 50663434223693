import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BaseWithUnsubComponent } from './base.component';
import { OrNull } from '@foxeet/domain';

@Component({ template: '' })
export abstract class BaseFormComponent<T = unknown> extends BaseWithUnsubComponent implements OnInit, OnChanges {
  @Input() placeholder?: string;
  @Input() label?: string;

  /**
   * Use form or control, only one of them.
   */
  @Input() form!: FormGroup | AbstractControl;
  @Input() control!: FormControl | AbstractControl;

  /**
   * control name. Not necesary if control is passed.
   */
  @Input() controlName!: string;

  @Input() readonly = false;

  @Input() disabled = false;

  @Output() valueChanges = new EventEmitter<OrNull<T>>();

  get showValidationMessages(): boolean {
    return this.errorKeys?.length && !!this.control?.errors;
  }

  public value!: OrNull<T>;
  public errors: ValidationErrors | null = null;
  public errorKeys: ValidationErrors = {};

  protected _loaded = false;

  ngOnInit(): void {
    this._loaded = true;
    this.setControl();

    this.control?.valueChanges.pipe(takeUntil(this._componentDestroyed), debounceTime(500), distinctUntilChanged()).subscribe((el: T) => {
      this.value = el;
      this.errors = this.control.errors;
      this.errorKeys = Object.keys(this.control.errors ?? {});
      this.valueChanges.emit(el);
      /** Disabled should be checked after each valueChange */
      this.checkDisable();
    });

    this.checkDisable();
    if (!this.componentId) this.componentId = 'AT_' + this.controlName;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.disabled = !!changes.disabled.currentValue;
      if (this._loaded) {
        this.checkDisable();
      }
    }
  }

  setControl(): void {
    if (this.form && this.controlName && !this.control) {
      this.control = this.form.get(this.controlName) as FormControl;
    }
    if (!this.control) {
      console.error(`Not found control: ${this.controlName}`);
    }
  }

  checkDisable() {
    /**
     * Quizá esta función sólo debería deshabilitar
     */
    if (this.disabled) {
      this.control?.disable({ emitEvent: false });
    }
  }
}
