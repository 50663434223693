import { createReducer, on } from '@ngrx/store';
import { initialStateType, TypologiesState } from './typology.state';
import { setSubTypologiesApi, setTypologiesApi } from './typology.actions';

export const TypologyReducer = createReducer<TypologiesState>(
  initialStateType,
  on(setTypologiesApi, (state, action) => {
    return {
      ...state,
      use: {
        ...state.use,
        [action.use]: action.typologies,
      },
    };
  }),
  on(setSubTypologiesApi, (state, action) => {
    return {
      ...state,
      typologies: {
        ...state.typologies,
        [action.typology]: action.subTypologies,
      },
    };
  }),
);
