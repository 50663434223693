<ion-card>
  <ion-card-header>
    <ion-toolbar>
      <ng-content select="[header]" *ngIf="!title"></ng-content>
      <ion-card-title *ngIf="title" color="primary">{{ title | translate : internationalizationType }}</ion-card-title>
      <ion-buttons slot="end" *ngIf="isEditable">
        <ion-button
          *ngFor="let action of actions"
          [id]="action.actionId + '_' + index"
          (click)="execCallback(action, item)"
          class="button-icon-clear-rounded"
          [ngClass]="{ action: action.type === actionButtonTypes.default, 'delete-action': action.type !== actionButtonTypes.default }"
        >
          <ion-icon [name]="action.icon"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-card-header>

  <ion-card-content>
    <ng-content select="[content]"></ng-content>
  </ion-card-content>
</ion-card>
