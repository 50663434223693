<ion-row>
  <ion-col>
    <ion-card (click)="cardClicked.next(cardItem?.data)" [color]="cardItem?.color">
      <ion-row
        class="chips-wrapper"
        [ngClass]="{
          'ion-justify-content-between': cardItem?.leftChip && cardItem?.rightChip,
          'ion-justify-content-end': !cardItem?.leftChip && cardItem?.rightChip
        }"
      >
        <ion-col *ngIf="cardItem?.leftChip" size="auto">
          <ui-mobile-chip [chip]="cardItem?.leftChip"></ui-mobile-chip>
        </ion-col>
        <ion-col size="auto" *ngIf="cardItem?.rightChip">
          <ui-mobile-chip [chip]="cardItem?.rightChip"></ui-mobile-chip>
        </ion-col>
      </ion-row>

      <div class="img-wrapper" *ngIf="cardItem?.image">
        <img *ngIf="cardItem?.image" [ngStyle]="{ width: onerror ? 'auto' : '100%' }" [src]="cardItem?.image" (error)="getOnErrorImgPath()" />
      </div>

      <ion-row *ngIf="cardItem?.title" class="ion-align-items-center title-wrapper" [ngClass]="{ 'has-image': cardItem?.image }">
        <ion-col class="no-padding">
          <h3 class="text-default-bold text-darked" id="{{ componentId }}-title">{{ cardItem?.title | uppercase }}</h3>
        </ion-col>

        <ion-col size="auto" *ngIf="cardItem?.rightChip" class="no-padding">
          <ui-mobile-chip [chip]="cardItem?.rightBottomChip"></ui-mobile-chip>
        </ion-col>
      </ion-row>

      <ion-card-content [ngClass]="{ 'has-title': cardItem?.title }">
        <ng-content></ng-content>

        <ion-row *ngIf="!!cardItem?.dates?.length">
          <ion-col *ngFor="let date of cardItem?.dates; count as length; let i = index" [sizeXs]="6" [sizeSm]="6" [sizeMd]="length > 2 ? 4 : 6">
            <mcore-date-display id="{{ componentId }}-date-{{ i }}" [dateDisplay]="date"></mcore-date-display>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="cardItem?.actions" class="ion-justify-content-end">
          <ion-col
            [sizeXs]="action?.size?.xs"
            [sizeSm]="action?.size?.sm"
            [sizeMd]="action?.size?.md"
            [sizeLg]="action?.size?.lg"
            *ngFor="let action of cardItem?.actions"
            (click)="action?.callback(cardItem?.data); $event.stopPropagation()"
          >
            <ion-button expand="block" class="{{ action?.type }}">
              <ion-icon [name]="action?.icon"></ion-icon>
              {{ action?.label | translate }}
            </ion-button>
          </ion-col>
        </ion-row>

        <ng-content select="[#bottom-content]"></ng-content>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
