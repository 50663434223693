import { Component, Input, OnInit } from '@angular/core';
import { isNil } from '@foxeet/utils/functions';
import { IonCheckbox, PopoverController } from '@ionic/angular';
import { MobileDropdownItem } from '../../../form-inputs/form-dropdown-custom/form-dropdown-custom.types';
import { SearchableListModel } from '../../../../../../domain/src/lib/interfaces/searchable-list.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'ui-mobile-searchable-list',
  templateUrl: './searchable-list.component.html',
  styleUrls: ['./searchable-list.component.scss'],
})
export class SearchableListComponent implements OnInit {
  public selectedItems: MobileDropdownItem[] = [];
  public filteredItems: MobileDropdownItem[] = [];
  public itemsCopy: MobileDropdownItem[] = [];
  @Input() items!: MobileDropdownItem[];
  @Input() config!: SearchableListModel;
  @Input() controlValue: any | any[];

  constructor(private _popoverController: PopoverController) {}

  ngOnInit() {
    this.itemsCopy = cloneDeep(this.items);
    if (!isNil(this.controlValue)) {
      const selectedItemsValue = Array.isArray(this.controlValue) ? this.controlValue : [this.controlValue];
      this.itemsCopy.forEach((el) => {
        el.isChecked = selectedItemsValue.includes(el[this.config.value]);
        if (el.isChecked) {
          this.selectedItems.push(el);
        }
      });
    }
    this.filteredItems = this.itemsCopy;
  }

  public onChangeSearchBar({ detail: { value } }: CustomEvent<IonCheckbox>) {
    this.filteredItems = this.itemsCopy.filter((item) => {
      return item[this.config.filterBy].toLowerCase().includes(value.toLowerCase());
    });
  }

  public onChangeItemSelected({ detail: { value, checked } }: CustomEvent<IonCheckbox>) {
    if (!this.config.allowMultipleSelection) {
      /**
       * Al usar virtual scroll cada vez que el elemento seleccionado entra o sale del DOM se lanza el evento onChange asociado el checkbox
       * Con esto controlamos que no se cierre el modal
       */
      const selectedValue = this.selectedItems.length > 0 ? `${this.selectedItems[0][this.config.value]}` : null;
      if ((!checked && value !== selectedValue) || (checked && value === selectedValue)) return;

      const itemSelected = this.itemsCopy.find((item) => checked && [value, +value].includes(item[this.config.value]));
      if (itemSelected) {
        this.selectedItems = [itemSelected];
      }
      if (
        ((isNil(this.controlValue) && this.selectedItems.length === 1) || (this.selectedItems.length === 1 && this.selectedItems[0].key !== this.controlValue)) &&
        this.controlValue !== value
      ) {
        this._popoverController.dismiss(this.selectedItems[0]);
      }
    } else {
      const itemSelected = this.itemsCopy.find((item) => [value, +value].includes(item[this.config.value]));
      if (itemSelected) itemSelected.isChecked = checked;
      this.selectedItems = this.itemsCopy.filter((el) => el.isChecked);
    }
  }

  public confirm() {
    this._popoverController.dismiss(this.selectedItems);
  }

  public closeModal() {
    this._popoverController.dismiss();
  }

  public trackByMethod(index: number, el: MobileDropdownItem) {
    return el;
  }
}
