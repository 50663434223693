<div class="wrapper">
  <span class="md-inputfield p-float-label my-4" [ngClass]="{ 'p-input-icon-right': !!tooltip, readonly: readonly }" *ngIf="control">
    <!-- TODO: review always should display as block -->
    <!-- [ngStyle]="{ display: !!tooltip ? 'block' : 'inherit' }" -->
    <input *ngIf="!readonly" id="{{ componentId }}" [type]="type" pInputText [formControl]="control" [value]="type === 'email' ? (control.value | lowercase) : control.value" />
    <p *ngIf="readonly" class="value" [ngClass]="{ 'has-value': control?.value }">{{ control?.value }}</p>
    <label *ngIf="label" for="{{ componentId }}">{{ label }}</label>
    <ui-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-form-validation-messages>
    <ng-content></ng-content>
    <i *ngIf="!!tooltip" class="pi pi-info-circle" [escape]="true" [pTooltip]="tooltip" tooltipPosition="top"></i>
  </span>
  <button mat-icon-button *ngIf="showButton" [disabled]="!control.value" (click)="callback(control.value)">
    <mat-icon [style]="'color:' + buttonColor">{{ buttonIcon }}</mat-icon>
  </button>
</div>
