import { createAction, props } from '@ngrx/store';
import { AssetTypologies, AssetUses, EnumsModel } from '@foxeet/domain';
import { FEATURE_NAME } from '../domain/Typology-chest.config';

const featureTITLE = `[${FEATURE_NAME}] `;

// funciones usadas en el effect para llamar a back
export const setTypologies = createAction(featureTITLE + 'setTypologies', props<{ use: AssetUses }>());
export const setSubTypologies = createAction(featureTITLE + 'setSubTypologies', props<{ typology: AssetTypologies }>());

// La info que se va a setear en el state para cada uso
export const setTypologiesApi = createAction(featureTITLE + 'setTypologiesAPI', props<{ use: AssetUses; typologies: EnumsModel[] }>());
export const setSubTypologiesApi = createAction(featureTITLE + 'setSubTypologiesAPI', props<{ typology: AssetTypologies; subTypologies: EnumsModel[] }>());
