import { AssetUses, EnumsModel } from '@foxeet/domain';

export interface TypologiesState {
  use: Record<AssetUses, EnumsModel[]> | null;
  typologies: any;
  subTypologies: any;
}

export const initialStateType: TypologiesState = {
  use: null,
  typologies: null,
  subTypologies: [],
};
