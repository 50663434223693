import { WorkerRoles, WorkflowItemWorkerIndexModel } from '@foxeet/domain';

export class WorkersUtils {
  static getWorkerDataByType(workers: WorkflowItemWorkerIndexModel[], userType: WorkerRoles) {
    return workers && workers.find((w) => w.workerRoleValue === userType)?.user;
  }

  static getWorkerFullName(workers: WorkflowItemWorkerIndexModel[], userType: WorkerRoles): string | undefined {
    const worker = this.getWorkerDataByType(workers, userType);
    return worker && worker.fullName;
  }

  static getMapWorkersIdAndRoles(workersIds: number[], roles: WorkerRoles[]): { [key: number]: number[] } {
    return (
      workersIds &&
      roles.reduce(
        (acc: { [key: number]: number[] }, curr) => ({
          ...acc,
          [curr]: workersIds,
        }),
        {},
      )
    );
  }
}
