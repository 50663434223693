import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CultureSelectors } from '@foxeet/chests/culture';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'toDatePipeByLocale',
})
export class ToDatePipeByLocale extends UnsuscriptionHandler implements PipeTransform {
  datePipe!: DatePipe;
  langCode!: string;

  constructor(private store: Store) {
    super();
    this.store
      .select(CultureSelectors.CultureCode)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((culture) => (this.datePipe = new DatePipe(culture)));
    this.store
      .select(CultureSelectors.LanguageCode)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((lang) => (this.langCode = lang));
  }

  transform(value: number | string | Date | undefined | null, pipeFormat = 'dd/MM/yyyy HH:mm', nullChar = ''): any {
    return this.datePipe.transform(value, pipeFormat, undefined, this.langCode) || nullChar;
  }
}
