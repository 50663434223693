import { Injectable } from '@angular/core';

export type CacheParams = {
  expires: Date;
  force?: boolean;
};

export type CacheData<T = unknown> = CacheParams & {
  data: T | null;
};

@Injectable()
export class CacheService {
  private cacheMap = new Map<string, CacheData>();

  public getCacheData<T>(key: string): T | null {
    const cacheData: CacheData<T> | null = (this.cacheMap.get(key) as CacheData<T>) ?? null;
    let data: T | null = null;
    if (cacheData) {
      if (this.isExpired(cacheData.expires)) {
        this.deleteCache(key);
      }
      data = this.isExpired(cacheData.expires) ? null : cacheData.data;
    }

    return data;
  }

  public setCacheData<T = unknown>(key: string, data: CacheData<T>): void {
    this.cacheMap.set(key, data);
  }

  public clearCache(): void {
    this.cacheMap.clear();
  }

  private deleteCache(key: string): boolean {
    return this.cacheMap.delete(key);
  }

  private isExpired(date: Date): boolean {
    const now = new Date();

    return date.getTime() < now.getTime();
  }
}
