import { NgModule } from '@angular/core';
import { SearchableListComponent } from './searchable-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { I18nModule } from '@foxeet/utils-modules';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [SearchableListComponent],
  imports: [CommonModule, ScrollingModule, I18nModule, IonicModule],
  exports: [SearchableListComponent],
})
export class SearchableListModule {}
