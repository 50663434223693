import { FloorTypes, StreetTypes } from '../enums';

export interface CountryModel {
  name: string;
  englishName?: string;
  nativeName?: string;
  threeLetterIso: string;
}

export interface ProvinceModel {
  name: string;
  ineCod: number;
}

export interface LocalityModel {
  localityId?: number;
  name: string;
  ineCod: number;
  ineCodMun: number;
  ineCodLocality: number;
}

export interface StreetModel {
  viaId: number;
  dgcCodVia: number;
  name: string;
}

export class SelectionTypes {
  public static LOCATION_BY_USER = 0;
  public static LOCATION_BY_GOOGLE_MAPS = 1;
  public static LOCATION_BY_CADASTRAL_STREET = 2;
}

export interface LocationModel {
  addressGeneratedName: string;
  block: string;
  censusSectionName: string;
  country: string;
  countryThreeLetterISORegionName: string;
  dgcCodVia: number;
  floorType: FloorTypes;
  fullAddressGeneratedName: string;
  gate: string;
  ineCodLocality: number;
  ineCodLocalityStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodVia: string;
  locality: string;
  latitude: number | null;
  longitude: number | null;
  portal: string;
  postalCode: string;
  province: string;
  stairs: string;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  streetNotExists: boolean;
}
