import { BehaviorSubject } from 'rxjs';
import { Step } from './steps.model';

export const FORM_CLUSTER_STEPS_KEY = {
  clusterDetails: 'detail',
  clusterValuations: 'valuations',
  clusterValuationTerrain: 'calculations',
};

export const STEPS_CLUSTER_FORM: Step[] = [
  {
    id: FORM_CLUSTER_STEPS_KEY.clusterDetails,
    isActive: false,
    link: FORM_CLUSTER_STEPS_KEY.clusterDetails,
    name: 'ASSETS_assets',
    tabId: null,
    order: 1,
    isVisible: new BehaviorSubject(true),
    isDisabled: new BehaviorSubject(false),
    isComplete: null,
    completedPercentage: null,
    isRequiredComplete: null,
    requiredCompletedPercentage: null,
  },
  {
    id: FORM_CLUSTER_STEPS_KEY.clusterValuations,
    isActive: false,
    link: FORM_CLUSTER_STEPS_KEY.clusterValuations,
    name: 'CLUSTER.valuations',
    tabId: null,
    order: 2,
    isVisible: new BehaviorSubject(true),
    isDisabled: new BehaviorSubject(false),
    isComplete: null,
    completedPercentage: null,
    isRequiredComplete: null,
    requiredCompletedPercentage: null,
  },
];
