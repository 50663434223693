import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';
import { FormDropdownComponent } from './form-dropdown.component';

@NgModule({
  declarations: [FormDropdownComponent],
  imports: [CommonModule, ReactiveFormsModule, IonicModule, FormValidationMessagesModule, I18nModule.forChild(), PipesModule],
  exports: [FormDropdownComponent],
})
export class FormDropdownModule {}
