import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BaseWithUnsubComponent } from '../../../base-components';
import { FormInputFileResponseModel } from '../form-input-file.types';
import { ToastService } from '@foxeet/utils/services/toast.service';
import { BehaviorSubject, takeUntil } from 'rxjs';

@Component({
  selector: 'ui-mobile-form-input-file',
  templateUrl: './form-input-file.component.html',
  styleUrls: ['./form-input-file.component.scss'],
})
export class FormInputFileComponent extends BaseWithUnsubComponent implements OnInit {
  @Input() icon = 'document-outline';
  @Input() multiple = false;
  @Input() readonly = false;
  @Input() accept?: string;
  @Input() resetFiles$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() filesEmitter: EventEmitter<FormInputFileResponseModel> = new EventEmitter();

  protected _processedFile: FormInputFileResponseModel | null = null;

  constructor(private readonly _toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.resetFiles$.pipe(takeUntil(this._componentDestroyed)).subscribe((reset) => {
      if (reset && this.componentId && document.getElementById(this.componentId)) {
        console.log(document.getElementById(this.componentId));
      }
    });
  }

  public onFileChange($event: InputEvent): void {
    const files: FileList | null = ($event.target as HTMLInputElement).files;
    if (files && this._areAllFilesHasAValidType(files)) {
      this._processFiles(files);
    } else {
      this._toastService.error('INPUT_FILE.errorTitle', 'INPUT_FILE.errorMessage');
    }
  }

  private _processFiles(filesList: FileList): void {
    const files: File[] = Array.from(filesList);

    files.forEach((uploadedFile, index) => {
      /**
       * @todo log
       */
      console.log(uploadedFile);

      const reader = new FileReader();
      reader.onload = () => (this._processedFile = { uploadedFile });
      this.filesEmitter.emit({ uploadedFile });

      reader.readAsDataURL(files[index]);
    });
  }

  private _areAllFilesHasAValidType(files: FileList): boolean {
    let areAllFilesHasAValidType = true;
    const allowedTypes: string[] | undefined = this.accept?.split(',');

    if (allowedTypes?.length) {
      for (let index = 0; index < files.length; index++) {
        console.log(allowedTypes);
        console.log(files.item(index)?.type);
        console.log(allowedTypes.indexOf(files.item(index)?.type ?? 'noneType'));
        if (allowedTypes.indexOf(files.item(index)?.type ?? 'noneType') <= -1) {
          areAllFilesHasAValidType = false;
        }
      }
    }
    return areAllFilesHasAValidType;
  }
}
