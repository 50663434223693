import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckCancelModel, LicenseeRelationshipConnectionRequestModel, LicenseeUserConnectionRequestEditModel } from '@foxeet/domain';

import { Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class ConnectionsLicenseeService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'LicenseeRelationshipConnectionRequest', cache);
  }

  acceptConnectionRequest(connectionId: number): Observable<LicenseeRelationshipConnectionRequestModel> {
    return this.put<null, LicenseeRelationshipConnectionRequestModel>(`${connectionId}/Accept`, null);
  }

  cancelConnectionRequest(connectionId: number): Observable<LicenseeRelationshipConnectionRequestModel> {
    return this.put<null, LicenseeRelationshipConnectionRequestModel>(`${connectionId}/Cancel`, null);
  }

  rejectConnectionRequest(connection: LicenseeUserConnectionRequestEditModel): Observable<LicenseeRelationshipConnectionRequestModel> {
    return this.put<LicenseeUserConnectionRequestEditModel, LicenseeRelationshipConnectionRequestModel>(`${connection.id}/Reject`, connection);
  }

  resendConnectionRequest(connectionId: number): Observable<any> {
    return this.put<null, any>(`${connectionId}/Resend`, null);
  }

  checkCancel(id: number) {
    return this.get<CheckCancelModel>(`${id}/checkCancel`);
  }
}
