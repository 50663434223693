import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';
import { Observable } from 'rxjs';

@Injectable()
export class ExternalContractsService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'ExternalContracts', cache);
  }

  /* SIVASA */

  sivasaWaitingForPayment(orderRequestId: number): Observable<void> {
    const params = new HttpParams().append('orderRequestId', orderRequestId);

    return this.get('Sivasa/SendIncidentF1', {
      observe: 'response',
      params,
    });
  }

  sivasaCancelledNonPayment(orderRequestId: number): Observable<void> {
    const params = new HttpParams().append('orderRequestId', orderRequestId);

    return this.get('Sivasa/SendIncidentC8', {
      observe: 'response',
      params,
    });
  }

  /****/
}
