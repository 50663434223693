import {
  AppraisalExternalCommunicationProcessStates,
  DefaultFilterItem,
  ExternalCommunicationStates,
  ExternalCommunicationStatus,
  WorkflowItemExternalCommunicationProcessFilterModel,
} from '@foxeet/domain';
import { FormGroup } from '@angular/forms';
import { WebFilter } from '../classes';

export enum CommunicationsFilterBarItems {
  ALL,
  REQUIRED_ACTION,
  IN_PROCESS,
  FINISHED_REVIEWED,
}

export const filterDataBySelectedFilterBar = new Map<CommunicationsFilterBarItems, Partial<WorkflowItemExternalCommunicationProcessFilterModel>>([
  [
    CommunicationsFilterBarItems.ALL,
    {
      statesExcluded: null,
      appraisalExternalCommunicationProcessState: null,
    },
  ],
  [
    CommunicationsFilterBarItems.REQUIRED_ACTION,
    {
      statesExcluded: [ExternalCommunicationStates.ProcessedOk],
      appraisalExternalCommunicationProcessState: AppraisalExternalCommunicationProcessStates.UserActionRequired,
    },
  ],
  [
    CommunicationsFilterBarItems.IN_PROCESS,
    {
      statesExcluded: [ExternalCommunicationStates.ProcessedOk],
      appraisalExternalCommunicationProcessState: AppraisalExternalCommunicationProcessStates.InProcess,
    },
  ],
  [
    CommunicationsFilterBarItems.FINISHED_REVIEWED,
    {
      statesExcluded: null,
      appraisalExternalCommunicationProcessState: AppraisalExternalCommunicationProcessStates.FinishedOrReviewed,
    },
  ],
]);

export const communicationsDefaultFilterLabels = [
  'all',
  'COMMUNICATIONS_FILTER_BAR_needToDoSomething',
  'COMMUNICATIONS_FILTER_BAR_inProcess',
  'COMMUNICATIONS_FILTER_BAR_finishedAndReviewed',
];
export const communicationsDefaultFilterBarItems: DefaultFilterItem[] = communicationsDefaultFilterLabels.map((label, idx) => ({
  label,
  value: idx,
  selected: !idx,
}));

export const updateCommunicationsFilterValues = (selectedFilterTab: number, filterForm: FormGroup, filterClass: WebFilter, filterStatus?) => {
  const currentFilter = filterClass.getCurrentFilter();
  const newData = filterDataBySelectedFilterBar.get(selectedFilterTab);

  // Cuando cambiamos de filtro predefinido se resetea el formulario con los valores por defecto del filtro seleccionado
  if (filterStatus === communicationsDefaultFilterLabels[selectedFilterTab]) {
    currentFilter.data = { ...currentFilter.data, ...newData };
  } else {
    filterForm.reset(newData);
    currentFilter.data = newData;
  }

  currentFilter.activeFilterBarTab = selectedFilterTab;
  filterClass.changeFilter(currentFilter);
};

export const getCommunicationStatus = (el: { isFinished: boolean; state: ExternalCommunicationStates }) => {
  return !el.isFinished
    ? ExternalCommunicationStatus.IN_PROCESS
    : el.state === ExternalCommunicationStates.ProcessedOk
    ? ExternalCommunicationStatus.SUCCESS
    : ExternalCommunicationStatus.ERROR;
};
