<ion-refresher slot="fixed" (ionRefresh)="reloadData($event)" [pullMin]="100">
  <ion-refresher-content pullingIcon="chevron-down-circle-outline" refreshingSpinner="circles"></ion-refresher-content>
</ion-refresher>

<ng-content *ngIf="(dataSource.entitySubject | async).length; else noContent"></ng-content>

<ng-template #noContent>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ui-mobile-basic-container>
          <mcore-label-with-icon iconName="alert-circle-outline" iconColor="warning" [label]="noContentMessage"></mcore-label-with-icon>
        </ui-mobile-basic-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>

<ion-infinite-scroll [threshold]="threshold" [disabled]="infiniteScrollDisabled" (ionInfinite)="loadData($event)">
  <ion-infinite-scroll-content loadingSpinner="dots" loadingText="{{ 'loading' | translate }}"></ion-infinite-scroll-content>
</ion-infinite-scroll>
