import { createReducer, on } from '@ngrx/store';
import { initialState, UtilsState } from './utils.state';
import { setPropertyRegisterAPI } from './utils.actions';

export const UtilsReducer = createReducer<UtilsState>(
  initialState,
  on(setPropertyRegisterAPI, (state, action) => ({
    ...state,
    propertyRegister: action.propertyRegister,
  })),
);
