<ion-item
  [ngClass]="{
    error: control?.errors,
    'non-opacity': readonly,
    'item-has-value': control?.value !== null && control?.value !== undefined && control?.value !== '',
    suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === ''),
    disabled: control?.disabled
  }"
  [lines]="readonly ? 'none' : 'full'"
>
  <ion-label
    *ngIf="label && enableFormat"
    [ngClass]="{
      textInput: (control?.value || focused) && enableFormat,
      numberInput: (control?.value || focused) && !enableFormat
    }"
    [position]="labelPosition"
    >{{ label | translate : internationalizationType | capitalize }}</ion-label
  >

  <input
    #inputNumber
    *ngIf="enableFormat; else disableFormat"
    id="{{ componentId }}"
    [class]="cssClass"
    type="text"
    [formControl]="control"
    [readonly]="readonly"
    [suffix]="suffix"
    [suffixType]="suffixType"
    (blur)="canEmitOnBlur ? emitBlur() : $event.stopPropagation()"
    [digitsInfo]="digitsInfo"
    (focus)="focused = true"
    (focusout)="focused = false"
    [min]="min"
    [max]="max"
    utilsNumberFormatterDirective
  />
  <ng-template #disableFormat>
    <div class="input-with-suffix">
      <ion-input
        label="{{ label | translate : internationalizationType | capitalize }}"
        labelPlacement="floating"
        [id]="componentId"
        type="number"
        [class]="cssClass"
        [formControl]="control"
        [readonly]="readonly"
        [max]="max"
        [min]="min"
        [step]="step"
        (ionBlur)="canEmitOnBlur ? emitBlur($event) : $event.stopPropagation()"
        (focus)="focused = true"
        (focusout)="focused = false"
      ></ion-input>
      <span *ngIf="suffix">{{ suffix }}</span>
    </div>
  </ng-template>
</ion-item>
<p *ngIf="subLabel" class="form-hint">{{ subLabel | translate }}</p>
<ui-mobile-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-mobile-form-validation-messages>
