import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BACK_CONFIG, BackConfig } from '../injectors';
import { TranslationModel } from '@foxeet/domain';

@Injectable()
export class LangJsonTranslatesService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject(BACK_CONFIG) private backConfig: BackConfig) {
    this.baseUrl = backConfig.baseUrl.value;
  }

  public getTranslations(lang: string): Observable<TranslationModel> {
    return this.http.get<TranslationModel>(`${this.baseUrl}/${lang}/enums/LangJsonFilesForAngular`, { observe: 'body' });
  }
}
