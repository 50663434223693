import {
  AssetStates,
  AssetSubTypologies,
  AssetSurfaceIds,
  AssetTypologies,
  AssetUses,
  BuildingQualityTypes,
  BuildingTypes,
  ComparableSources,
  ConservationStateTypes,
  CrawledSources,
  FastTrackProximities,
  FloorTypes,
  HomogeneousCoefficientConcept,
  HomogeneousCoefficientType,
  IdealistaBuiltType,
  OfferTypes,
  PropertyStates,
  SituationsWithinArea,
  SocioEconomicLevels,
  SourceTypes,
  StreetTypes,
} from '../enums';
import { OrNull } from '../interfaces';
import { FoxeetKeyValueModel } from './extra-information.model';
import { AppraisalReportMarketStudyItemModel } from './market-study.model';

export interface WorkflowItemAssetValorationComparableIndexModel {
  ValorationId: number;
  ComparableId: number;
  FinalValue: number;
  Weight: number;
  Percentage: number;
  DiscountPercentage: number;
  OfferTotalPrice: number;
  OfferInitialUnitPrice: number;
  ConsiderateValue: number;
  TotalAdoptedSurface: number;
  TotalPlotSurface: number;
  HomogeneousCoefficient: number;
}

export interface WorkflowItemAssetValorationComparableCreateModel {
  AssetId: number;
  // OfferUnitPrice: number;
  Weight: number;
  DiscountPercentage: number;
  OfferTotalPrice: number;
  TotalAdoptedSurface: number;
  TotalPlotSurface: number;
  FinalValue: number;
  OfferFinalUnitPrice: number;
  ConsideratedValue: number;
  HomogeneousCoefficient: number;
  // ComparableId: number;
}

////////////////////////////////////////////////////////////////////////////////////////////
// Nuevos modelos

export interface ValorationComparisonMethodModel {
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number | null;
  assetSurfaceId: AssetSurfaceIds | null;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  buildeable: number;
  numberOfElementsToBuild: number;
  marketStudy: AppraisalReportMarketStudyItemModel;
  comparables: ValorationComparableModel[];
  homogeneizedUnitValue: number;
  homogeneizedUnitDepreciatedValue: number;
  coefficientKe: number;
  coefficientKh: number;
  keUnitValue: number;
  khUnitValue: number;
  keTotalValue: number;
  khTotalValue: number;
  isFutureValoration: boolean;
  homogeneousCoefficientType: HomogeneousCoefficientType;
  homogeneousCoefficientObservations: string;
  use: AssetUses;
  typology: AssetTypologies;
  marketMortgageValueDifferences: string;
  isEditableMarketMortgageValueDifferences: boolean;
  currentPricSaleRental: string;
  isEditableCurrentPricSaleRental: boolean;
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}

export interface ValorationComparableModel {
  id: number;
  valorationId: number;
  comparableId: number;
  workflowItemAssetLatitude: number;
  workflowItemAssetLongitude: number;
  workflowItemAssetValorationValue: number;
  workflowItemAssetValorationTotalAdoptedSurface: number;
  workflowItemAssetValorationUnitValue: number;
  discountPercent: number;
  marketPrice: number;
  marketPriceUnitValue: number;
  homogeneousCoefficient: number;
  adoptedUnitValue: number;
  weight: number;
  weightedAveragePercent: OrNull<number>;
  comparable: ComparableIndexModel;
  homogeneousCoefficientDistributions: HomogeneousCoefficientDistributionModel[];
  address: string;
  typologyAndSubtypology: string;
}

export class HomogeneousCoefficientDistributionModel {
  concept?: HomogeneousCoefficientConcept;
  value?: number;
}

export interface ComparableEditObservationsModel {
  id: number;
  observations: string;
}

export interface ComparableMarketStudies {
  appraisalId: number;
  recordId: string;
  marketStudyId: number;
  reportId: number;
  reportVersion: number;
  marketStudyName: string;
  assetId: number;
  reportCommonAssetId: number;
}

export interface ComparableIndexModel {
  id: number;
  crawledDateTime: string;
  source: ComparableSources;
  countryConfigurationId: number;
  valorationValue: number;
  valorationTotalAdoptedSurface: number;
  valorationUnitValue: number;
  plotTotalSurface: number;
  discountPercent: number;
  marketPrice: number;
  marketPriceUnitValue: number;
  distance: number;
  state: AssetStates;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  reformLastYear: number;
  constructionYear: number;
  conservationState: ConservationStateTypes;
  qualityOfBuilding: BuildingQualityTypes;
  latitude: number;
  longitude: number;
  streetTypeEnum: StreetTypes;
  streetType: string;
  streetName: string;
  streetNumber: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  block: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  countryThreeLetterISORegionName: string;
  locationObservations: string;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodLocality: number;
  ineCodLocalityStr: string;
  dgcCodVia: number;
  ineCodVia: string;
  censusSectionName: string;
  addressObservations: string;
  address: string;
  fullAddress: string;
  fullAddressGeneratedName: string;
  offerType: OfferTypes;
  sourceType: SourceTypes;
  crawledSource: CrawledSources;
  providerUrl: string;
  contactInfo: string;
  hasPool: boolean;
  hasGreenArea: boolean;
  hasSportsZone: boolean;
  hasAirConditioning: boolean;
  hasHeating: boolean;
  bathroomCount: number;
  bedroomCount: number;
  cadastralReference: string;
  totalCadastralSurface: number;
  facade: number;
  depth: number;
  coefTableBackground: number;
  streetNotExists: boolean;
  propertyState: PropertyStates;
  floorsCount: number;
  elevatorsCount: number;
  lastUpdatedDateTime: string;
  cultureCode: string;
  situationWithinArea: SituationsWithinArea;
  socioEconomicLevel: SocioEconomicLevels;
  fastTrackProximity: FastTrackProximities;
  buildingType: BuildingTypes;
  typologyAndSubtypology: string;
  isForBuildingElement: boolean;
  addedDateTime: string;
  addedUserName: string;
  observations: string;
}

export interface ComparableEditModel {
  source: ComparableSources;
  locationObservations: string;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodLocalityStr: string;
  dgcCodVia: number;
  ineCodVia: string;
  censusSectionName: string;
  addressObservations: string;
  offerType: OfferTypes;
  sourceType: SourceTypes;
  crawledSource: CrawledSources;
  countryConfigurationId: number;
  providerUrl: string;
  contactInfo: string;
  hasPool: boolean;
  hasGreenArea: boolean;
  hasSportsZone: boolean;
  hasAirConditioning: boolean;
  hasHeating: boolean;
  bathroomCount: number;
  bedroomCount: number;
  countryThreeLetterISORegionName: string;
  cadastralReference: string;
  country: string;
  province: string;
  valorationValue: number;
  valorationTotalAdoptedSurface: number;
  valorationUnitValue: number;
  plotTotalSurface: number;
  discountPercent: number;
  state: AssetStates;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  reformLastYear: number;
  constructionYear: number;
  conservationState: ConservationStateTypes;
  qualityOfBuilding: BuildingQualityTypes;
  latitude: number;
  longitude: number;
  streetName: string;
  streetNumber: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  block: string;
  locality: string;
  postalCode: string;
  totalCadastralSurface: number;
  streetType: StreetTypes;
  streetNotExists: boolean;
  assetId: number;
  mainUse: number;
  mainTypology: number;
  mainSubTypology: number;
  hasPaving: boolean;
  hasPublicLighting: boolean;
  hasWaterSupply: boolean;
  hasSanitationNetwork: boolean;
  hasTelephoneNetwork: boolean;
  hasPipedGasNetwork: boolean;
  observations: string;
  cultureCode: string;
  propetyState: PropertyStates;
  floorsCount: number;
  elevatorsCount: number;

  situationWithinArea: SituationsWithinArea;
  socioEconomicLevel: SocioEconomicLevels;
  fastTrackProximity: FastTrackProximities;
  buildingType: BuildingTypes;
  totalUsefulSurface: number;
}

export interface ValorationComparableCalculationModel {
  homogeneousCoefficientType: HomogeneousCoefficientType;
  comparables: ValorationComparableModel[];
}
