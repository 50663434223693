import { Component, Input } from '@angular/core';
import { InternationalizationTypes } from '@foxeet/domain';
import { dynamicInjector, isNil } from '@foxeet/utils/functions';
import { ToastService } from '@foxeet/utils/services/toast.service';

@Component({
  selector: 'mcore-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() location: { latitude: number; longitude: number };
  @Input() internationalizationType: InternationalizationTypes = null;

  public openLocation() {
    if (!isNil(this.location.latitude) && !isNil(this.location.longitude)) {
      window.open('https://www.google.com/maps/dir//' + this.location?.latitude + ',' + this.location?.longitude, '_blank');
    } else {
      const toast = dynamicInjector().get(ToastService);
      toast.error('Error', 'locationError');
    }
  }
}
