import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { SanitizerTypes } from '../pipes.types';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: string, type: SanitizerTypes | string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case SanitizerTypes.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SanitizerTypes.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SanitizerTypes.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SanitizerTypes.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SanitizerTypes.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
