import { dynamicInjector } from '@foxeet/utils/functions/dynamic-injector';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dynamicPipe' })
export class DynamicPipe implements PipeTransform {
  transform<T = unknown>(value: T, pipeToken: Pipe, pipeArgs: unknown[] = [], dynamicArgs: unknown[] = []): T {
    pipeArgs = pipeArgs ?? [];
    dynamicArgs = dynamicArgs ?? [];
    const args = [...pipeArgs, ...dynamicArgs];

    return pipeToken
      ? dynamicInjector()
          .get(pipeToken)
          .transform(value, ...args)
      : value;
  }
}
