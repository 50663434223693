import { Component, Input } from '@angular/core';
import { formUtilhasRequiredErrorButIsDisabled } from '@foxeet/utils/forms';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss'],
})
export class FormInputTextComponent extends BaseFormComponent<string> {
  @Input() type = 'text';
  @Input() showButton: boolean;
  @Input() buttonIcon: string;
  @Input() buttonColor: string;
  @Input() tooltip?: string;
  @Input() callback: (arg) => void;
}
