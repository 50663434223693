export const demoUsers = ['lmaldonado@iv.solutions'];
export const e2eUsers = [
  'user.master@iv.force',
  'process.manager.master@iv.force',
  'tech.master@iv.force',
  'collaborator.request@iv.force',
  'commercial.request@iv.force',
  'user.basic.requestvaloration@iv.force',
  'user.advanced.requestvaloration@iv.force',
  'professional.master@iv.force',
  'owner.master@iv.force',
  'manager.master@tasafy.com',
  'complete.report@iv.force',
  'method.comparison@tasafy.com',
  'manager.flow1_10@gmail.com',
  'professional.flow1_10@gmail.com',
  'usuario.tasafy@gmail.com',
  'gestor.flow1_10@iv.force',
  'professional.flow1_10@iv.force',
  'validator.flow1_10@iv.force',
];
export const e2eUsersPattern = 'test-e2e';
