export const M_PATHS = {
  INDEX: '/',
  LOGIN: 'login',
  RESTORE: 'restore',
  APPRAISER: 'user',
  APPRAISALS: 'orders',
  ABSENCES: 'absences',
  EXCHANGE: 'opportunities',
  REQUESTS: 'collaborations',
  HOW_IT_WORKS: 'how-it-works',
  DOCUMENTS: 'documents',
  INVOICES: 'invoices',
  SCHEDULE: 'schedule',
  DEBUG: 'debug',
  CHANGE_TEMPORAL_PASSWORD: 'change-temporal-password',
  MY_WORK: 'my-work',
  DASHBOARD: 'dashboard',
  CONNECTIONS_NETWORK: 'connections-network',
  ACTIVATION: 'activation',
  COMPARABLES: 'comparables',
  MARKET_STUDY: 'market-study',
};

export const M_SUB_PATHS = {
  COMMON_ASSETS: 'commonAssets',
  MY_WORK: 'my-work',
  MY_ASSIGNMENTS: 'my-assignments',
};

export const APPRAISAL_PATHS = {
  SEARCH_RESULT: 'search-result',
  LAST_ORDERS: 'last-orders',
  VISIT_PENDING: 'visit-pending',
  SENT_PENDING: 'sent-pending',
  REJECTED: 'rejected',
  INCIDENTS: 'incidents',
  SENT_TO_REVISION: 'sent-to-revision',
  FINISHED: 'finished',
  DETAIL: 'detail',
  REPORTS: 'reports',
  BOARD: 'board',
  INFO: 'info',
  VISIT: 'visit',
  DOCUMENTS_LIST: 'documents',
};

export const APPRAISAL_REPORT_PATHS = {
  REQUEST: 'request',
  ASSETS: 'assets',
  CLUSTERS: 'clusters',
  ADD_ASSET: 'add-asset',
  TERRAIN_CLUSTERS: 'terrains',
  MULTIPLE_EDITION: 'multiple-edition',
};

export const APPRAISAL_REPORT_ASSET_PATHS = {
  LOCATION: 'location',
  IDENTIFICATION: 'identification',
  TYPOLOGY: 'typology',
  VISIT_TYPOLOGY: 'visit-typology',
  VISIT_SURFACE_FACILITIES_STAYS: 'visit-surface-facilities-stays',
  VISIT_BUILDING_INFO: 'visit-building-info',
  VISIT_ENVIRONMENT: 'visit-environment',
  VISIT_TERRAIN_SURFACE: 'visit-terrain-surface',
  VISIT_TERRAIN_FEATURES: 'visit-terrain-features',
  SURFACES: 'surfaces',
  LOCALITYENVIRONMENT: 'locality-environment',
  TERRAINFEATURES: 'terrainFeatures',
  BUILDINGTERRAIN: 'building-terrain',
  BUILDINGFEATURES: 'building-features',
  PROPERTYFEATURES: 'property-features',
  URBANISTICSITUATION: 'urbanistic-situation',
  URBANISTICSITUATION_TERRAIN: 'urbanistic-situation-terrain',
  HOLDING: 'holding',
  CHECKS: 'checks',
  VALUATIONS_LIST: 'valuation-list',
  VALUATIONS: 'valuations',
  CONDITIONINGFACTORS: 'conditioningFactors',
  PHOTOS: 'photos',
  DOCUMENTATION: 'documentation',
  POSSESSION: 'possession',
  BUILDING_STATE: 'buildingState',
  EXPLOITATION_DESCRIPTION: 'exploitation-description',
  RUSTIC_ESTATE_FEATURES: 'rustic-estate-features',
  MARKET_STUDIES: 'market-studies',
};

export const APPRAISAL_REPORT_CLUSTER_PATHS = {
  DETAIL: 'detail',
  VALUATIONS: 'valuations',
};

export const WITNESS_PATHS = {
  TASAFY: 'IVForceComparable',
  IDEALISTA: 'IdealistaComparable',
};
