<div class="custom-container" [ngClass]="{ 'mat-elevation-z1': withMatElevation }">
  <div class="top-container" *ngIf="title || tableConfig?.topButtonActions?.length">
    <h3 class="title" *ngIf="title">{{ title }}</h3>
    <mat-icon *ngIf="extraInfoMessageWithTooltip && !showCustomTooltip" class="title-extre-info-tootltip" [matTooltip]="extraInfoMessageWithTooltip">info</mat-icon>
    <div *ngIf="extraInfoMessageWithTooltip && showCustomTooltip" class="asset-info-container" class="col-2">
      <mat-icon> info </mat-icon>
      <div class="asset-info-floating-container" [innerHTML]="extraInfoMessageWithTooltip"></div>
    </div>
    <div class="top-buttons-container">
      <div *ngFor="let buttonConfig of tableConfig?.topButtonActions" class="pr-3">
        <ui-async-button
          *ngIf="buttonConfig.isVisible | async"
          [componentId]="'AT_' + buttonConfig.id"
          [type]="'submit'"
          [asyncButton]="buttonConfig?.inputState"
          [inputState]="buttonConfig?.inputState"
          [buttonClassByType]="'primary'"
          [size]="'md'"
          [label]="buttonConfig.label | translate"
          [loadingLabel]="buttonConfig.label | translate"
          [icon]="buttonConfig.icon"
          [disabled]="buttonConfig.disabled"
          (clickEvent)="buttonConfig.action()"
        ></ui-async-button>
      </div>

      <ui-download-file-button *ngIf="tableConfig.downloadExcelEnabled" (click)="downloadExcel()"></ui-download-file-button>

      <ng-content select="#specialButtons"></ng-content>

      <ui-expandable-filters-button *ngIf="showFilters" [expandablePanel]="expandablePanel"></ui-expandable-filters-button>
    </div>
  </div>

  <ui-expandable-filters-panel [expandablePanel]="expandablePanel">
    <form>
      <ui-dynamic-form *ngIf="!hasTabFilters" [modelFormGroup]="filterForm" [model]="filterConfig" (triggSubmit)="onUpdate()"></ui-dynamic-form>

      <ng-content *ngIf="hasTabFilters" select="[filterTab]"></ng-content>

      <div class="material-drawer-actions">
        <div>
          <ui-async-button
            [componentId]="'AT_reset_form'"
            [icon]="faTimes"
            [asyncButton]="false"
            [buttonClassByType]="'secondary'"
            [size]="'md'"
            [label]="'restart' | translate"
            [loadingLabel]="'restarting' | translate"
            (clickEvent)="onClear()"
          ></ui-async-button>
        </div>
        <div>
          <ui-async-button
            [componentId]="'AT_search_form'"
            [type]="'submit'"
            [icon]="faSearch"
            [asyncButton]="false"
            [buttonClassByType]="'primary'"
            [size]="'md'"
            [label]="'filter' | translate"
            [loadingLabel]="'filtering' | translate"
            (clickEvent)="onUpdate()"
          ></ui-async-button>
        </div>
      </div>
    </form>
  </ui-expandable-filters-panel>

  <p *ngIf="listDataSource && listDataSource.data.length && showSimpleList" class="text-info-table-list">{{ 'APPRAISALS_antecedentsInfoAssetCommon' | translate }}</p>
  <div *ngIf="listDataSource && listDataSource.data.length && showSimpleList" class="table-list">
    <core-generic-table
      [tableId]="'AT_antecedent_table'"
      [noContentLabel]="noContentLabel"
      [tableConfig]="tableConfig"
      [isPaginated]="false"
      [showSelectAll]="showSelectAll"
      [dataSource]="listDataSource"
      [filter]="filter"
      [multiple]="multiple"
      (selectionChange)="selectionChange.emit($event)"
      (rowClickEvent)="rowClickEvent.emit($event)"
    ></core-generic-table>
  </div>

  <core-generic-table
    [tableId]="'AT_table_comparable_list'"
    [noContentLabel]="noContentLabel"
    [tableConfig]="tableConfig"
    [isPaginated]="isPaginated"
    [showSelectAll]="showSelectAll"
    [dataSource]="dataSource"
    [filter]="filter"
    [multiple]="multiple"
    (selectionChange)="selectionChange.emit($event)"
    (rowClickEvent)="rowClickEvent.emit($event)"
  ></core-generic-table>
</div>
