<p *ngIf="label && !range.showdualKnobs">
  {{ label | translate | uppercase }}
  <span *ngIf="control.value" class="subtitle">| {{ control.value | toDistance }}</span>
</p>

<p *ngIf="label && range.showdualKnobs" class="title">
  {{ label | translate | uppercase }}
</p>

<ion-item
  class="input-range"
  [ngClass]="{
    error: showValidationMessages && control,
    'non-opacity': readonly,
    suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === '')
  }"
>
  <ion-range
    [dualKnobs]="range.showdualKnobs"
    [formControl]="control"
    [min]="range.minInformation.limit"
    [max]="range.maxInformation.limit"
    [step]="range.stepValue"
    [pin]="range.showValue"
    [ticks]="range.showTricks"
    [snaps]="range.showSnaps"
    (ionBlur)="control.updateValueAndValidity()"
    color="primary"
  >
    <div slot="start">
      <ion-text>{{ range.minInformation.label }}</ion-text>
      <ion-icon *ngIf="range?.minInformation?.icon" size="small" [name]="range.minInformation.icon"></ion-icon>
    </div>

    <div slot="end">
      <ion-icon *ngIf="range?.maxInformation?.icon" [name]="range.maxInformation.icon"></ion-icon>
      <ion-text>{{ range.maxInformation.label }}</ion-text>
    </div>
  </ion-range>
</ion-item>

<ui-mobile-form-validation-messages *ngIf="showValidationMessages" [control]="control"></ui-mobile-form-validation-messages>
