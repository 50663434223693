import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';

/**
 * Wrapper for web toast service
 */
@Injectable()
export class ToastWrapperService {
  constructor(protected _toastService: ToastService) {}

  success(message = 'the_action_was_successful', title = 'successful', _?: unknown) {
    return this._toastService.success(message);
  }

  error(error: any, title = 'error', _?: unknown) {
    return this._toastService.error(title, error);
  }

  info(message = 'the_action_was_successful', title = 'successful', _?: unknown) {
    return this._toastService.info(message);
  }

  successHandler = () => {
    this.success();
  };

  errorHandler = ({ error }: { error: { title: string; detail: string; errors: any } }) => {
    if (error instanceof Blob) {
      this.parseErrorBlob(error);
    } else {
      if (error?.detail) {
        this.error(error.detail, error.title);
      } else if (error?.errors) {
        this.parseErrorsObject(error.errors);
      } else if (error?.title) {
        this.error(error.title);
      } else {
        this.error('Error is not correct. No present message.');
      }
    }
  };

  /**
   * Angular transforms error 500 response into blob when the responseType configuration is setted to 'Blob'.
   * This error should be interpreted has file.
   */
  parseErrorBlob(blobError: Blob): void {
    const reader: FileReader = new FileReader();

    reader.onloadend = (e) => {
      const errorObject = JSON.parse(reader.result as any);
      this.error(errorObject.detail, errorObject.title);
    };

    reader.readAsText(blobError);
  }

  /**
   * NET CORE validation type errors
   */
  parseErrorsObject(errors: any) {
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key])) {
        (errors[key] as string[]).forEach((e) => this.error(e));
      } else {
        this.error(errors[key]);
      }
    });
  }
}
