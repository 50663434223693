import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { OrNull } from '@foxeet/domain';
import { BACK_CONFIG, BackConfig } from '../injectors';
import { defaultBaseUrlFactory } from '../utils/data-access.utils';
import { catchError } from 'rxjs/operators';
import { BackUrlService } from '../services/back-url.service';

export function BackUrlInterceptorServiceFactory(backConfig: BackConfig, _backUrlService: BackUrlService) {
  return new BackUrlInterceptorService(backConfig, _backUrlService);
}

@Injectable()
export class BackUrlInterceptorService implements HttpInterceptor, OnDestroy {
  private formedUrl: OrNull<string> = null;
  private subs$ = new Subscription();

  constructor(@Inject(BACK_CONFIG) private config: BackConfig, private _backUrlService: BackUrlService) {
    this.subs$.add(
      defaultBaseUrlFactory(config.baseUrl, config.lang).subscribe((formedUrl) => {
        this.formedUrl = formedUrl;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(
        req.url.startsWith('./') || req.url.startsWith('http')
          ? req
          : req.clone({
              url: `${this.formedUrl}${req.url}`,
              setHeaders: { 'X-LoginPlatform': `${this.config.platform}` },
            }),
      )
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // TODO: change second part
          if (e.status === 500 && e.error?.detail?.includes('Update the app')) {
            this._backUrlService.showAppVersionAlert$.next(true);
          }
          return throwError(e);
        }),
      );
  }
}
