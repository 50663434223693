import { FormGroup } from '@angular/forms';
import { MassiveValorationsBaseModel, MultipleEditionItemModel, ValuationMassiveStates } from '@foxeet/domain';

export const getParentFormGroup = (params): FormGroup => {
  const { context, node } = params;
  return <FormGroup>context.formArray.controls[node?.id];
};

export const getChildFormGroup = (params): FormGroup => {
  const { context, node, rowNode } = params;
  const parentFormGroup = <FormGroup>context.formArray.controls[node?.id || +rowNode?.id];
  return parentFormGroup.get('valorationData') as FormGroup;
};

export const createValoration = (params, callback: (data: any, formGroup: FormGroup, hasChanges: boolean) => void) => {
  const parentFormGroup = getParentFormGroup(params);
  const formGroup = getChildFormGroup(params);

  formGroup.enable();
  callback(params.data, formGroup, parentFormGroup.get('hasChanges')?.value);
};

export const isValuationMethodEnable = (params) => {
  if (params && params.data && params.data.el) {
    const { massiveState } = params.data?.el as MassiveValorationsBaseModel<any>;
    const parentFormGroup = getParentFormGroup(params);
    const errors: string[] = params.data.el.valorationInitialValues.errors;

    return (massiveState === ValuationMassiveStates.Editable && !errors.length) || (massiveState === ValuationMassiveStates.Creatable && parentFormGroup.get('hasChanges')?.value);
  }
};

export const isValuationDisableByMassiveStateAndErrors = (valuation: MultipleEditionItemModel<MassiveValorationsBaseModel<any>, any>, hasChanges: boolean) => {
  const { massiveState } = valuation.el;
  return (
    massiveState === ValuationMassiveStates.NoActionAllowed ||
    (massiveState === ValuationMassiveStates.Creatable && !hasChanges) ||
    (valuation.el.valorationInitialValues && valuation.el.valorationInitialValues?.errors && valuation.el.valorationInitialValues?.errors.length)
  );
};
