import { PatternsErrorKeys } from '@foxeet/domain';
import { Pattern } from '../interfaces';

export const VALIDATOR_ONLY_DECIMALS: Pattern = {
  pattern: /^\d+$/,
  errorKey: PatternsErrorKeys.PATTERN,
};

export const VALIDATOR_DOUBLE: Pattern = {
  pattern: /^((\d)+([,.]+(\d)+)*)*$/,
  errorKey: PatternsErrorKeys.PATTERN,
};

export const CUSTOM_EMAIL: Pattern = {
  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  errorKey: PatternsErrorKeys.EMAIL_VALIDATION_ERROR,
};

export const CUSTOM_EMAIL_ALT: Pattern = {
  pattern: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{1,36}$/,
  errorKey: PatternsErrorKeys.EMAIL_VALIDATION_ERROR_ALT,
};

export const BUDGET_NUMBER_MASK: Pattern = {
  pattern: /^(\d{6}-\d{6}|)$/,
  errorKey: PatternsErrorKeys.BUDGET_NUMBER_MASK_ERROR,
};

export const VALIDATOR_IDUFIR: Pattern = {
  pattern: /^[0-9]{0,14}$/,
  errorKey: PatternsErrorKeys.PATTERN,
};

export const VALIDATOR_POSTALCODE: Pattern = {
  pattern: /^\d{5}$/,
  errorKey: PatternsErrorKeys.PATTERN,
};

export const VALIDATOR_PHONE: Pattern = {
  pattern: /^\d{9}$/,
  errorKey: PatternsErrorKeys.PATTERN,
};

export const VALIDATOR_REQUEST_ID: Pattern = {
  pattern: /^[+]?[1-9]\d*$/,
  errorKey: PatternsErrorKeys.PATTERN,
};

export const ALPHANUMERIC: Pattern = {
  pattern: /^[0-9a-zA-Z]+$/,
  errorKey: PatternsErrorKeys.ALPHANUMERIC,
};

export const PASSWORD: Pattern = {
  // pattern: /^(?=.*\d+)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*?[=?<>()'"\/&\t]).{8,}$/, // old one not permit ?<>()'"/&\t
  pattern: /^(?=.*\d+)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
  errorKey: PatternsErrorKeys.FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH,
};
