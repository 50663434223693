import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DetailMenuImageService {
  constructor(private http: HttpClient) {}

  uploadImage(url: string, Id: number, image: Blob, profileImageWidth?: number, profileImageHeight?: number): unknown {
    if (profileImageHeight == null && profileImageHeight == null) {
      profileImageHeight = 150;
      profileImageWidth = 150;
    }

    const formData = new FormData();
    formData.append('Id', `${Id}`);
    formData.append('ProfileImageFile', image);
    formData.append('ProfileImageWidth', `${profileImageWidth}`);
    formData.append('ProfileImageHeight', `${profileImageHeight}`);

    return this.http.put(url + '/ImageProfile', formData, { responseType: 'text' });
  }
}
