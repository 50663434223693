import { Component, Input, OnInit } from '@angular/core';
import { ConnectionRequestStates } from '@foxeet/domain';
import { isNil } from '@foxeet/utils/functions';

export interface ConfirmOrCancelModel {
  title?: string;
  subtitle?: string;
  showButtons: boolean;
  confirmCallback: (data?) => any;
  cancelCallback: (data?) => any;
}

interface StateConfig {
  title: string;
  titleClass?: string;
  subtitle: string;
  subtitleClass?: string;
  requestState?: ConnectionRequestStates;
}

@Component({
  selector: 'core-title-and-subtitle',
  templateUrl: './title-and-subtitle.component.html',
  styleUrls: ['./title-and-subtitle.component.scss'],
})
export class TitleAndSubtitleComponent implements OnInit {
  @Input() data: any;

  public config: StateConfig;
  public className = '';

  ngOnInit() {
    this.config = this.data?.titleAndSubtitleConfig;

    if (!isNil(this.config?.requestState)) {
      const statesMap = new Map([
        [ConnectionRequestStates.PendingToAccept, 'pending'],
        [ConnectionRequestStates.Accepted, 'accepted'],
        [ConnectionRequestStates.Rejected, 'rejected'],
        [ConnectionRequestStates.Cancelled, 'cancelled'],
      ]);
      this.className = statesMap.get(this.config.requestState);
    }
  }
}
