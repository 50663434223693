<div class="input-file-wrapper">
  <ion-icon [name]="icon"></ion-icon>
  <input
    [id]="componentId"
    [title]="'upload' | translate"
    [accept]="accept"
    [multiple]="multiple"
    class="input-file"
    type="file"
    [readonly]="readonly"
    (change)="onFileChange($event)"
  />
</div>
