import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { ApiBaseService2 } from '../services/api-base.service';

@Injectable()
export class ValidationAppraisalRegisteredPropertiesService extends ApiBaseService2 {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'ValidationAppraisalRegisteredProperties', cache);
  }

  finishRegisteredProperties(id: number, validation: any) {
    return this.put(`${id}/EditAndFinish`, validation);
  }
}
