import { Validators } from '@angular/forms';
import { CUSTOM_EMAIL } from '@foxeet/utils/forms';

export const loginControls = {
  user: 'username',
  password: 'password',
};

export const loginForm = [
  {
    name: loginControls.user,
    validators: [Validators.required, Validators.pattern(CUSTOM_EMAIL.pattern)],
    asyncValidatorFn: [],
  },
  {
    name: loginControls.password,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
];

export const recoverPaswordForm = [
  {
    name: loginControls.user,
    validators: [Validators.required, Validators.pattern(CUSTOM_EMAIL.pattern)],
    asyncValidatorFn: [],
  },
];
