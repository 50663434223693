import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { I18nModule } from '@foxeet/utils-modules';
import { FormInputTimeComponent } from './form-input-time.component';

@NgModule({
  declarations: [FormInputTimeComponent],
  imports: [CommonModule, CalendarModule, FormsModule, I18nModule.forChild()],
  exports: [FormInputTimeComponent],
})
export class FormInputTimeModule {}
