import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule, I18nModule } from '@foxeet/utils-modules';
import { InputTextModule } from 'primeng/inputtext';
import { FormValidationMessagesModule } from '../../form-validation-messages/web/form-validation-messages.module';
import { FormInputNumberComponent } from './form-input-number.component';

@NgModule({
  declarations: [FormInputNumberComponent],
  imports: [CommonModule, FormValidationMessagesModule, I18nModule.forChild(), InputTextModule, DirectivesModule, ReactiveFormsModule],
  exports: [FormInputNumberComponent],
})
export class FormInputNumberModule {}
