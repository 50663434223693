import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { Observable } from 'rxjs';
import {
  AppraisalAssetEnvironmentInformationModel,
  AssetDataSourceTypes,
  AssetFileIndexModel,
  AssetIdentificationDataModel,
  AssetLocalizationInformationModel,
  AssetStateInformation,
  AssetStateInformationModel,
  AssetStayAndFacilityEditModel,
  AssetStayAndFacilityInformationModel,
  AssetStayInformationModel,
  AssetSurfacesEditModel,
  AssetSurfacesInformationModel,
  AssetTabModel,
  AssetTenureInformationModel,
  AssetTerrainInformationDTO,
  AssetTypologyAndStateInformationDto,
  AssetTypologyAndStateInformationEditModelBase,
  AssetUrbanisticSituationInformationEditModel,
  AssetUrbanisticSituationModel,
  BuildingInformation,
} from '@foxeet/domain';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class AssetsService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Assets', cache);
  }

  getInitialDetails<T>(params: { [key: string]: number }): Observable<T> {
    return this.get<T>('InitialDetails', { params: this.setParams(params) });
  }

  public getLogItemsPagedFunction() {
    return (page: number, pagesize: number, filter: any, endpoint = 'ListPagedLog') => {
      return this.post<any, any[]>(`${endpoint}`, filter, {
        params: this.setParams({
          page,
          pagesize,
        }),
      });
    };
  }

  getDetailsAssetIdentification(assetId: number): Observable<AssetTabModel<AssetIdentificationDataModel>> {
    return this.get<AssetTabModel<AssetIdentificationDataModel>>(`${assetId}/Identification`);
  }

  editAssetIdentification(id: number, assetIdentificationModel: AssetTabModel<AssetIdentificationDataModel>): Observable<any> {
    const body = { ...assetIdentificationModel };
    return this.put<typeof body, any>(`${id}/Identification`, body);
  }

  getDetailsAssetLocation(assetId: number): Observable<AssetTabModel<AssetLocalizationInformationModel>> {
    return this.get<AssetTabModel<AssetLocalizationInformationModel>>(`${assetId}/Localization`);
  }

  editAssetLocalization(
    assetId: number,
    assetLocalizationInformationModel: AssetTabModel<AssetLocalizationInformationModel>,
  ): Observable<AssetTabModel<AssetLocalizationInformationModel>> {
    const body = { ...assetLocalizationInformationModel };
    return this.put<typeof body, AssetTabModel<AssetLocalizationInformationModel>>(`${assetId}/Localization`, body);
  }

  getPropertyCharacteristicsDetail(assetId: number): Observable<AssetTabModel<AssetStayAndFacilityInformationModel>> {
    return this.get<AssetTabModel<AssetStayAndFacilityInformationModel>>(`${assetId}/StayAndFacilities`);
  }

  editPropertyCharacteristicsDetail(assetId: number, assetStayAndFacility: AssetStayAndFacilityEditModel): Observable<HttpResponse<any>> {
    const body = { ...assetStayAndFacility };
    return this.post<typeof body, any>(`${assetId}/StayAndFacilities`, body);
  }

  getDetailsAssetTypology(assetId: number): Observable<AssetTabModel<AssetTypologyAndStateInformationDto>> {
    return this.get<AssetTabModel<AssetTypologyAndStateInformationDto>>(`${assetId}/Typologies`);
  }

  editAssetTypology(assetId: number, assetTypologyInformationModel: AssetTabModel<AssetTypologyAndStateInformationEditModelBase>) {
    const body = { ...assetTypologyInformationModel };
    return this.put<typeof body, AssetTabModel<AssetTypologyAndStateInformationEditModelBase>>(`${assetId}/Typologies`, body);
  }

  getDetailsAssetSurfaces(assetId: number): Observable<AssetSurfacesInformationModel> {
    return this.get<AssetSurfacesInformationModel>(`${assetId}/Surface`);
  }

  editAssetSurfaces(assetId: number, assetSurfacesInformation: AssetSurfacesEditModel): Observable<void> {
    const body = { ...assetSurfacesInformation };
    return this.put<typeof body, void>(`${assetId}/Surface`, body);
  }

  getDetailsAssetState(assetId: number): Observable<AssetStateInformation> {
    return this.get<AssetStateInformation>(`DetailsAssetState/${assetId}`);
  }

  editAssetState(assetStateInformationModel: AssetStateInformationModel): Observable<any> {
    const body = { ...assetStateInformationModel };
    return this.post<typeof body, any>('EditAssetState', body);
  }

  getDetailsBuildingInformation(assetId: number): Observable<AssetTabModel<BuildingInformation>> {
    return this.get<AssetTabModel<BuildingInformation>>(`${assetId}/BuildingInformation`);
  }

  editAssetBuildingInformationModel(assetId: number, assetBuildingInformationModel: AssetTabModel<BuildingInformation>): Observable<AssetTabModel<BuildingInformation>> {
    const body = { ...assetBuildingInformationModel };
    return this.put<typeof body, AssetTabModel<BuildingInformation>>(`${assetId}/BuildingInformation`, body);
  }

  getDetailsAssetUrbanisticSituation(assetId: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<AssetUrbanisticSituationModel>> {
    return this.get<AssetTabModel<AssetUrbanisticSituationModel>>(`${assetId}/UrbanisticSituation` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  editUrbanisticSituation(
    assetId: number,
    body: AssetTabModel<AssetUrbanisticSituationInformationEditModel>,
  ): Observable<AssetTabModel<AssetUrbanisticSituationInformationEditModel>> {
    return this.put<typeof body, AssetTabModel<AssetUrbanisticSituationInformationEditModel>>(`${assetId}/UrbanisticSituation`, body);
  }

  getDetailsAssetTenure(assetId: number): Observable<AssetTenureInformationModel> {
    return this.get<AssetTenureInformationModel>(`DetailsAssetTenureInformation/${assetId}`);
  }

  getDistribution(assetStayInformationModel: AssetStayInformationModel): Observable<string> {
    return this.post<typeof assetStayInformationModel, string>('GetDistribution', assetStayInformationModel);
  }

  editTenures(assetTenureInformationModel: AssetTenureInformationModel): Observable<any> {
    const body = { ...assetTenureInformationModel };
    return this.post<typeof body, any>('EditTenures', body);
  }

  getDetailsAssetFiles(assetId: number): Observable<AssetFileIndexModel[]> {
    return this.get<AssetFileIndexModel[]>(`DetailsAssetFiles/${assetId}`);
  }

  getDetailsEnvironmentInformation(assetId: number): Observable<string> {
    return this.get<string>('DetailsEnvironmentInformation', { params: { id: assetId } });
  }

  editEnvironmentInformation(
    appraisalAssetId: number,
    assetEnvironmentInformation: AssetTabModel<AppraisalAssetEnvironmentInformationModel>,
  ): Observable<AssetTabModel<AppraisalAssetEnvironmentInformationModel>> {
    return this.put<typeof assetEnvironmentInformation, AssetTabModel<AppraisalAssetEnvironmentInformationModel>>(
      `${appraisalAssetId}/EnvironmentAndMarket`,
      assetEnvironmentInformation,
    );
  }

  getDetailsEnvironmentAndMarketInformation(appraisalAssetId: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<AppraisalAssetEnvironmentInformationModel>> {
    return this.get<AssetTabModel<AppraisalAssetEnvironmentInformationModel>>(
      `${appraisalAssetId}/EnvironmentAndMarket` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''),
    );
  }

  getDetailsTerrainInformation(id: number, dataSource?: AssetDataSourceTypes) {
    return this.get<AssetTabModel<AssetTerrainInformationDTO>>(`${id}/TerrainInformation` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  editTerrainInformation(body: AssetTabModel<AssetTerrainInformationDTO>): Observable<AssetTabModel<AssetTerrainInformationDTO>> {
    return this.put<typeof body, AssetTabModel<AssetTerrainInformationDTO>>(`${body.id}/TerrainInformation`, body);
  }

  editLandDescription(body: any): Observable<void> {
    return this.post<typeof body, void>('EditBuildingTerrainDescription', body);
  }

  getLandDescription(id: any): Observable<any> {
    return this.get<any>(`DetailsBuildingTerrainDescription/${id}`);
  }
}
