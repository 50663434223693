import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { LicenseeTypes, LicenseeUserRoles, WorkerRoles } from '@foxeet/domain';
import { ExpandableFilterService, ExpandablePanel, WebDownloadFileButtonComponent, WebExpandableFiltersPanelComponent } from '@foxeet/ui';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { ArrayUtils, downloadExcel, EntitiesUtils, isNil, LicenseeUsersUtils, WorkersUtils } from '@foxeet/utils/functions';
import { finalize, takeUntil } from 'rxjs/operators';
import { NDataSource, WebFilter } from '@foxeet/utils/classes';
import { GenericTableComponent } from '../generic-table/generic-table.component';
import { UrlDataService } from '@foxeet/n-core';
import { WorkflowService } from '@foxeet/data-access';
import { GenericMap } from '@foxeet/utils/interfaces/mappers.interfaces';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'core-filtered-table',
  templateUrl: './filtered-table.component.html',
  styleUrls: ['./filtered-table.component.scss'],
})
export class FilteredTableComponent extends UnsuscriptionHandler implements OnInit {
  public filterForm: FormGroup;
  public faSearch = faSearch;
  public faTimes = faTimes;
  public expandablePanel: ExpandablePanel;

  @Input() title: string;
  @Input() extraInfoMessageWithTooltip: string;
  @Input() noContentLabel: string;
  @Input() filter: WebFilter;
  @Input() filterPanelHeight = 150;
  @Input() tableConfig!: any;
  @Input() filterConfig!: any[];
  @Input() dataSource: NDataSource<any, WorkflowService, GenericMap, WebFilter>;
  @Input() hasTabFilters = false;
  @Input() overrideFilterForm: FormGroup;
  @Input() tabs = false;
  @Input() showFilters = true;
  @Input() showSelectAll = true;
  @Input() isPaginated = true;
  @Input() withMatElevation = true;
  @Input() multiple = true;
  @Input() showCustomTooltip = false;
  @Input() showSimpleList = false;
  @Input() listDataSource: MatTableDataSource<any>;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() onClearEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild(WebExpandableFiltersPanelComponent, { static: true }) _expandableFiltersPanelComponent: WebExpandableFiltersPanelComponent;
  @ViewChild(WebDownloadFileButtonComponent) _downloadFileButtonComponent: WebDownloadFileButtonComponent;
  @ViewChild(GenericTableComponent, { static: true }) _genericTableComponent: GenericTableComponent;

  constructor(private _urlDataService: UrlDataService, private _toastrWrapperService: ToastrWrapperService, private _expandableFilterService: ExpandableFilterService) {
    super();
  }

  ngOnInit() {
    // Checks if filterMapFuncion is defined derived from table Mapper file. If not defined, filters shouldn't appears.
    this.expandablePanel = this._expandableFilterService.registerPanelAndGetPanel();

    if (this.filterConfig && !this.overrideFilterForm) {
      this.filterForm = this.filter.createFilterform(this.filterConfig);
    } else {
      // preparar datos para tabs
      this.filterForm = this.overrideFilterForm;
    }
    this.lookForURLFilters();
  }

  private lookForURLFilters() {
    const filterFromURL = this._urlDataService.getDataByQueryParamId(this.filter.filterName);
    const filterData = filterFromURL && filterFromURL.data;
    if (filterData) {
      // Convert datetime string to object
      Object.keys(filterData).forEach((key) => {
        const filterConfig = ArrayUtils.recursiveSearcher('id', key, this.filterConfig);

        if (!isNil(filterConfig?.customMappingToForm)) {
          filterData[key] = filterConfig.customMappingToForm(filterData[key]);
        } else {
          if (key.includes('Date') && filterData[key]) {
            filterData[key] = new Date(filterData[key]);
          }
        }
      });

      this.filterForm.patchValue(filterData);
    }
  }

  onUpdate() {
    const filterValue = this.filter.getCurrentFilter();
    const filterValues: any = {};

    const rawValue = this.filterForm.getRawValue();

    Object.keys(rawValue).forEach((key) => {
      const filterConfig = ArrayUtils.recursiveSearcher('id', key, this.filterConfig || []);

      if (!isNil(rawValue[key])) {
        if (!isNil(filterConfig?.customMappingToBack)) {
          filterValues[key] = filterConfig.customMappingToBack(rawValue[key]);
        } else {
          filterValues[key] = rawValue[key];
        }
      }
    });

    /**
     * @todo change to get propery (customerIds, requesterCompanyIds, supplierCompanyIds) from filter config obj
     */
    if (filterValues.customerIds || filterValues.requesterCompanyIds || filterValues.supplierCompanyIds) {
      filterValues['workflowItemLicenseeTypeAndLicenseeIds'] = EntitiesUtils.mergeLicensees(
        EntitiesUtils.getMapEntitiesIdAndRoles(filterValues.customerIds, [LicenseeTypes.Customer]),
        EntitiesUtils.getMapEntitiesIdAndRoles(filterValues.requesterCompanyIds, [LicenseeTypes.RequesterCompany]),
        EntitiesUtils.getMapEntitiesIdAndRoles(filterValues.supplierCompanyIds, [LicenseeTypes.SupplierCompany]),
      );
    }

    /**
     * @todo change to get propery (customerIds, requesterCompanyIds, supplierCompanyIds) from filter config obj
     */
    if (
      filterValues.appraiserUserIds ||
      filterValues.processManagerUserIds ||
      filterValues.technicalManagerUserIds ||
      filterValues.technicalUserIds ||
      filterValues.externalManagerUserIds
    ) {
      filterValues['workerRoleAndUserIds'] = EntitiesUtils.mergeLicensees(
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.appraiserUserIds, [WorkerRoles.Appraiser]),
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.processManagerUserIds, [WorkerRoles.ProcessManager]),
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.technicalManagerUserIds, [WorkerRoles.TechnicalManager]),
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.technicalUserIds, [WorkerRoles.Technical]),
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.externalManagerUserIds, [WorkerRoles.TechnicalManager]),
      );
    }

    /**
     * @todo change to get propery (customerIds, requesterCompanyIds, supplierCompanyIds) from filter config obj
     */
    if (filterValues.relatedItemLicenseeIdAndWorkflowItemLicenseeType_prescriberIds || filterValues.relatedItemLicenseeIdAndWorkflowItemLicenseeType_customerIds) {
      filterValues['relatedItemLicenseeIdAndWorkflowItemLicenseeType'] = EntitiesUtils.mergeLicensees(
        EntitiesUtils.getMapEntitiesIdAndRoles(filterValues.relatedItemLicenseeIdAndWorkflowItemLicenseeType_prescriberIds, [LicenseeTypes.RequesterCompany]),
        EntitiesUtils.getMapEntitiesIdAndRoles(filterValues.relatedItemLicenseeIdAndWorkflowItemLicenseeType_customerIds, [LicenseeTypes.Customer]),
      );
    }

    if (filterValues.relatedItemWorkerRoleAndUserIds_technical || filterValues.relatedItemWorkerRoleAndUserIds_external) {
      filterValues['relatedItemWorkerRoleAndUserIds'] = LicenseeUsersUtils.mergeLicensees(
        LicenseeUsersUtils.getMapLicenseeUserRoles(filterValues.relatedItemWorkerRoleAndUserIds_technical, [LicenseeUserRoles.TechnicalManager]),
        LicenseeUsersUtils.getMapLicenseeUserRoles(filterValues.relatedItemWorkerRoleAndUserIds_external, [LicenseeUserRoles.TechnicalManager]),
      );
    }

    if (
      filterValues.relatedItemWorkerRoleAndUserIds_appraiserUserIds ||
      filterValues.relatedItemWorkerRoleAndUserIds_processManagerUserIds ||
      filterValues.relatedItemWorkerRoleAndUserIds_technicalManagerUserIds ||
      filterValues.relatedItemWorkerRoleAndUserIds_technicalUserIds ||
      filterValues.relatedItemWorkerRoleAndUserIds_externalManagerUserIds
    ) {
      filterValues['relatedItemWorkerRoleAndUserIds'] = EntitiesUtils.mergeLicensees(
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.relatedItemWorkerRoleAndUserIds_appraiserUserIds, [WorkerRoles.Appraiser]),
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.relatedItemWorkerRoleAndUserIds_processManagerUserIds, [WorkerRoles.ProcessManager]),
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.relatedItemWorkerRoleAndUserIds_technicalManagerUserIds, [WorkerRoles.TechnicalManager]),
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.relatedItemWorkerRoleAndUserIds_technicalUserIds, [WorkerRoles.Technical]),
        WorkersUtils.getMapWorkersIdAndRoles(filterValues.relatedItemWorkerRoleAndUserIds_externalManagerUserIds, [WorkerRoles.TechnicalManager]),
      );
    }

    filterValue.data = { ...filterValue.data, ...filterValues };
    filterValue.page = 1;
    this.filter.setCurrentFilter(filterValue);
    this.dataSource.loadData();
    this._expandableFiltersPanelComponent.toggleFilters();
  }

  onClear() {
    this.filterForm.reset();

    this.filter.setCurrentFilter(this.filter.getDefaultFilter());
    this.dataSource.loadData();
    this._expandableFiltersPanelComponent.toggleFilters();
    this.filterForm.patchValue(this.filter.getDefaultFilter().data);

    this.onClearEmitter.emit(true);
  }

  downloadExcel() {
    this._downloadFileButtonComponent.startDownload();
    this.dataSource
      .downloadExcel(this.filter.getCurrentFilterData())
      .pipe(
        takeUntil(this._componentDestroyed),
        finalize(() => this._downloadFileButtonComponent.finishDownload()),
      )
      .subscribe((res) => {
        downloadExcel(res.body, this.title);
      }, this._toastrWrapperService.errorHandler);
  }

  updateSelection(selection: number[]) {
    this._genericTableComponent.updateSelection(selection);
  }
}
