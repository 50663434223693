import { BehaviorSubject, Observable } from 'rxjs';
import { InternationalizationTypes } from '../enums/frontend.enums';
import { FilterInputTypesEnum } from '../enums';
import { TooltipPosition } from '@angular/material/tooltip';
import { ValidatorFn } from '@angular/forms';
import { SelectItem } from 'primeng/api';

export interface ViewportSizes {
  xl?: number;
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
  auto?: boolean;
}

export interface IMatTooltipFormDataModel {
  label: string;
  position?: TooltipPosition;
  color?: 'primary' | 'secondary';
  callback?: (...args) => any;
}

/**
 * @todo Hay que meter mano muy fuerte
 */
export interface IFormDataModel {
  id?: string;
  controlName?: string;
  backendId?: string;
  labelCode?: string;
  loadingLabel?: string;
  internationalizationType?: InternationalizationTypes;
  type?: FilterInputTypesEnum;
  isHidden?: boolean;
  isDisabled?: boolean;
  indexBy?: string[] | string;
  tooltip?: string;
  validators?: ValidatorFn[];
  customMappingToForm?: any;
  customMappingToBack?: any;
  outItemInfoIcon?: IMatTooltipFormDataModel;
  callback?: any;
  label$?: any;

  size: ViewportSizes;

  lg?: number;

  // TODO: REVIEW
  defaultValue?: any;

  // TODO: REVIEW. CAN NOT BE A CUSTOMmAPPING?
  mapToDictionary?: any;

  // TODO: review if can be string instead of string array
  optionLabel?: string | string[];
  optionValue?: string | string[];

  // indexBy?: string;
  placeholder?: string;
  label?: string;
  subLabel?: string;
  options?: BehaviorSubject<SelectItem[]>;
  datePickerTarget?: string;
  subLabelCode?: string;
  isTitleSection?: boolean;
  isSubTitleSection?: boolean;
  appendToBody?: boolean;
  activeFilter?: boolean;
  labelKeys?: any;
  activeVirtualScroll?: boolean;
  button?: any;
  maxWidth?: string;
  valueProp?: string;
  arrayFiltered?: BehaviorSubject<any[]>;
  labelProp?: string;
  keys?: string[];
  array?: BehaviorSubject<any[]>;
  autoResize?: boolean;
  suffix?: string;
  suffixType?: InternationalizationTypes;
  mask?: string;
  slotChar?: string;
  digitsInfo?: string;
  enableFormat?: boolean;
  min?: number;
  minimumCharsToEmitEvent?: number;
  showImage?: boolean;
  listSubject?: (filter: unknown) => Observable<unknown>;
  filterName?: string;
  valueKey?: string;
  maxSelection?: number;
  showClear?: boolean;
  setTimeToEndOfDay?: boolean;
  showLabel?: boolean;
  canReset?: boolean;
  // Authority route to check if can access to show expert badge
  authorityRoute?: string;
  showPasswordStrength?: boolean;
  showPasswordBtn?: boolean;
  checkIfIsEqualToField?: string;
  dataType?: string;
  filterBy?: string;
  showInputButton?: boolean;
  buttonIcon?: string;
  buttonColor?: string;
  extraClasses?: string;

  //Info button
  isInfoButton?: boolean;
  infoButtonMessage?: string;
}

export interface DynamicFormModel {
  formModel?: any;
  formControlsNames: any;
  formValue?: any;
  formMatchControls?: DynamicFormMatchModel;
  formView: DynamicFormControlView[];
  hasSuggestedItems?: boolean;
  suggestedControls?: string[];
}

export interface DynamicFormControlView {
  componentId: string;
  section?: string;
  label?: string;
  internationalizationType?: InternationalizationTypes;
  subLabel?: string;
  control?: string;
  size?: any;
  type?: FormInputTypes;
  hide?: boolean;
  readonly?: boolean;
  clickable?: boolean;
  suffix?: string;
  suffixType?: InternationalizationTypes;
  digitsInfo?: string;
  enableFormat?: boolean;
  autoGrow?: boolean;
  pickerFormat?: 'date' | 'date-tieme' | 'month' | 'month-year' | 'time' | 'time-date' | 'year';
  displayFormat?: 'dd/MM/yyyy' | 'HH:mm' | 'dd/MM/yyyy HH:mm' | 'YYYY';

  options?: any;
  optionsEnum?: any;
  optionsEnumName?: string;
  maxDate?: string;
  minDate?: string;
  isMultipleSelection?: boolean;
  rangeConfig?: any;
  icon?: string;
  multiple?: boolean;
  assignFileNameTo?: string;
  radioList?: any[]; // Debería ser RadioModel pero sale dependencia circular
  config?: any; // Deberia ser SearchableListModel pero sale dependencia circular

  suggested?: boolean;

  min?: number;
  max?: number;
  step?: number;

  showFilterBar?: boolean;
  extraClasses?: string;
  canEmitOnBlur?: boolean;
  isTitleSection?: boolean;
  isInfoButton?: boolean;
  infoButtonMessage?: string;
  showInputButton?: boolean;
  buttonIcon?: string;
  buttonColor?: string;
  callback?: (...args: any) => void;
}

export interface DynamicFormMatchModel {
  control: string;
  matchControl: string;
}

export interface DynamicFormControlDistribution {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

export enum FormInputTypes {
  CHECKBOX = 0,
  DROPDOWN = 1,
  PASSWORD = 2,
  SEARCHER = 3,
  TEXT = 4,
  RANGE = 5,
  TOGGLE = 6,
  CALENDAR = 7,
  TRIPLE_SWITCH = 8,
  FILE = 9,
  RADIOLIST = 10,
  PROPOSED_DATES = 11,
  NUMBER = 12,
  TEXTAREA = 13,
  DROPDOWN_CUSTOM = 14,
}
