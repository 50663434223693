import { Component, ComponentFactoryResolver, Directive, Inject, Input, OnInit, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[utilsDinamicallyComponent]' })
export class DinamicallyComponentsDirective implements OnInit {
  @Input() entryComponent!: Component;
  @Input() data: any = {};
  @Input() contentType: any;
  @Input() header: any = {};

  constructor(@Inject(ComponentFactoryResolver) private factoryResolver, public viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.addDynamicComponent(this.entryComponent);
  }

  addDynamicComponent(component: Component) {
    const factory = this.factoryResolver.resolveComponentFactory(component);
    const aux = factory.create(this.viewContainerRef.injector);
    aux.instance.data = this.data;
    aux.instance.contentType = this.contentType;
    aux.instance.header = this.header;
    this.viewContainerRef.insert(aux.hostView);
  }
}
