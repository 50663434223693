import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { DynamicConfigLoaderService } from '../../config-loader';

@Injectable()
export class ErrorDialogService {
  private opened = false;

  public enabled = false;

  constructor(private dialog: MatDialog, private _dynamicConfigLoaderService: DynamicConfigLoaderService) {
    const isProd = this._dynamicConfigLoaderService.Options.environment.production;
    const localStorageItem = localStorage.getItem('checkEnableModalError') ?? 'true';
    this.enabled = !isProd && localStorageItem ? JSON.parse(localStorageItem) : false;
  }

  openDialog(message: string, status?: number): void {
    if (this.enabled && !this.opened) {
      this.opened = true;
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: { message, status },
        maxHeight: '100%',
        width: '540px',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
      });
    }
  }
}
