import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PATHS, SUB_PATHS } from '@foxeet/n-core';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';
import { ModalController, NavController } from '@ionic/angular';
import { filter, takeUntil } from 'rxjs/operators';
import { MenuItem, MenuItemType } from '../../domain';
import { DebugModalComponent } from '../../modals/debug-modal/debug-modal.component';

@Component({
  selector: 'mcore-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends UnsuscriptionHandler implements OnInit {
  public selectedPath = '';

  @Input() menu: MenuItem[] = [];

  constructor(
    private _router: Router,
    private _navController: NavController,
    private _modalController: ModalController,
    private _dynamicConfigLoaderService: DynamicConfigLoaderService,
  ) {
    super();
    this._router.events
      .pipe(
        takeUntil(this._componentDestroyed),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.selectedPath = event.url.substr(1).split('?')[0];
        if (!this.selectedPath.length) this.selectedPath = `${PATHS.APPRAISALS}/${SUB_PATHS.LIST}`;
      });
  }

  ngOnInit() {
    if (!this._dynamicConfigLoaderService.Options.environment.production) {
      this.menu.push({
        id: 'debug',
        title: 'MENU_debug',
        icon: 'bug-outline',
        type: MenuItemType.default,
        callback: () => this.onClickDebug(),
      });
    }
  }

  async onClickDebug() {
    const modal = await this._modalController.create({
      component: DebugModalComponent,
    });
    return await modal.present();
  }

  onClickItemWithRoute(item: MenuItem) {
    this._navController.navigateRoot([item.url]);
  }
}
