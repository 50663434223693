import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class SupplierCompaniesService extends WorkflowService {
  constructor(http: HttpClient, cache?: CacheService) {
    super(http, 'SupplierCompanies', cache);
  }

  usersListPagedBySupplierCompanyId<T>(customerId: string, page: number, pagesize: number, body: T | undefined): Observable<T> {
    return this.post<T, T>(`${customerId}/Users/ListPaged`, body, {
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }
}
