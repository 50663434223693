import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-basic-container',
  templateUrl: './basic-container.component.html',
  styleUrls: ['./basic-container.component.scss'],
})
export class BasicContainerComponent {
  @Input() title = '';
  @Input() titleParams: any | null = null;
  @Input() subTitle = '';
  @Input() icon = '';
  @Input() titleColor = '';
  @Input() noPadding = false;
  @Input() contentPadding?: string;

  @Input() radius = '0px';

  @Input() hideTitle = false;
  @Input() noTitlePadding = false;

  @Input() fullHeight = false;
  @Input() vAlignCenter = false;

  @Input() backgroundColor?: string;
  @Input() backgroundErrorColor = false;
  @Input() backgroundWarningColor = false;

  @Input() elevation = true;
}
