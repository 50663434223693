<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button id="AT_back-button" text=""></ion-back-button>
    </ion-buttons>
    <ion-title> {{ today.Day }} {{ today.MonthName | translate }} {{ today.Year }}</ion-title>
    <ion-buttons slot="end">
      <ion-button icon-left fill="clear" color="forground" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="visitScheduled.length">
    <ion-row class="order-title-section">
      <h3>
        <span><ion-icon name="calendar"></ion-icon></span> {{ 'AGENDA_visitDates' | translate | capitalize }}
      </h3>
    </ion-row>

    <app-appraisal-card *ngFor="let appraisal of visitScheduled" [order]="appraisal" (click)="dismiss()" [routerLink]="['/appraisal', appraisal.Id]"> </app-appraisal-card>
  </div>

  <div *ngIf="deadlines.length">
    <ion-row class="order-title-section order-title-section--warning">
      <h3>
        <span><ion-icon name="time"></ion-icon></span> {{ 'AGENDA_limitDates' | translate | capitalize }}
      </h3>
    </ion-row>

    <app-appraisal-card *ngFor="let appraisal of deadlines" [order]="appraisal" (click)="dismiss()" [routerLink]="['/appraisal', appraisal.Id]"> </app-appraisal-card>
  </div>
</ion-content>
