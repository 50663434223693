import { OrNullOrUndef } from '@foxeet/domain';

/**
 * @todo BORRAR CUANDO UTILS BUILDEABLE
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ObjectTS {
  // eslint-disable-next-line @typescript-eslint/ban-types
  export const keys = <T = {}>(v: T): Array<keyof typeof v> => Object.keys(v) as Array<keyof typeof v>;
  export const values = <T>(v: { [s: string]: T } | ArrayLike<T>) => Object.values<T>(v);
  export const getProperty = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];
  export const setProperty = <T, K extends keyof T>(obj: T, key: K, value: T[K]): void => {
    obj[key] = value;
  };
}

export const isNil = (value?: any): value is null | undefined => [null, undefined].includes(value);

export const isEmptyString = (value: string): boolean => !value.length;

export const isNilOrEmptyString = (value: OrNullOrUndef<string>): boolean => isNil(value) || value === '';
