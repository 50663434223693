<div class="wrapper">
  <div class="d-flex">
    <div *ngFor="let item of config">
      <button *ngIf="item.icon" mat-icon-button [matTooltip]="item.tooltip | translate" (click)="item.callback(item.data)">
        <mat-icon class="{{ item.class }}">{{ item.icon }}</mat-icon>
      </button>
      <button *ngIf="!item.icon" [class]="item.btnClass" mat-button (click)="item.callback(item.data)">{{ item.label | translate }}</button>
    </div>
  </div>
</div>
