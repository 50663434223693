<ion-header>
  <ion-toolbar>
    <ion-title> DEBUG MENU </ion-title>
    <ion-buttons slot="end">
      <ion-button icon-left fill="clear" color="forground" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <h4>{{ 'DEBUG.enpointAdress' | translate }}</h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-label> {{ environmentURL }} </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <h4>{{ 'DEBUG.setCustomEndpoint' | translate }}</h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input
            label="Endpoint URL"
            labelPlacement="floating"
            placeholder="Ejemplo: http://192.168.XX.XX:XXXX"
            type="text"
            [value]="endpointInput"
            (ionChange)="valueChanges($event.detail.value)"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button (click)="setCustomEndpoint()"> {{ 'DEBUG.setEndpoint' | translate }} </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <h4>{{ 'DEBUG.setPreset' | translate }}</h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button (click)="setDevEndpoint()"> {{ 'DEBUG.setDev' | translate }} </ion-button>
      </ion-col>
      <ion-col>
        <ion-button (click)="setQAEndpoint()"> {{ 'DEBUG.setQA' | translate }} </ion-button>
      </ion-col>
      <ion-col>
        <ion-button (click)="setProdEndpoint()"> {{ 'DEBUG.setPROD' | translate }} </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons>
      <ion-button expand="block" fill="outline" color="primary" (click)="closeModal()">
        {{ 'DEBUG.cancel' | translate }}
      </ion-button>
      <ion-button expand="block" fill="outline" color="primary" (click)="confirm()">
        {{ 'DEBUG.confirm' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
