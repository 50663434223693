import { Validators } from '@angular/forms';
import { FormModel } from '../form.model';

export enum AssetExternSourceFormProps {
  USE = 'use',
  TYPOLOGY = 'typology',
  SUBTYPOLOGY = 'subTypology',
  OPERATION = 'operation',
  VALORATIONADOPTEDTOTALSURFACE = 'valorationAdoptedTotalSurface',
  MINVALORATIONTOTALADOPTEDSURFACE = 'minValorationTotalAdoptedSurface',
  MAXVALORATIONTOTALADOPTEDSURFACE = 'maxValorationTotalAdoptedSurface',
  AGEUNTIL = 'ageUntil',
  AGESINCE = 'ageSince',
  DISTANCE = 'distance',
  BUILTTYPE = 'builtType',
  ROOMNUMBERFROM = 'roomNumberFrom',
  ROOMNUMBERTO = 'roomNumberTo',
  BATHNUMBERFROM = 'bathNumberFrom',
  BATHNUMBERTO = 'bathNumberTo',
  ISINTOPFLOOR = 'isInTopFloor',
  ISINMIDDLEFLOOR = 'isInMiddleFloor',
  ISINGROUNDFLOOR = 'isIngroundFloor',
  HASLIFT = 'haslift',
  HASPARKINGSPACE = 'hasparkingspace',
  HASSTORAGEROOM = 'HasStorageRoom',
  HASPOOL = 'HasPool',
  HASGREENAREA = 'HasGreenArea',
  HASTERRACE = 'hasterrace',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  HASACCEPTEDCONDITIONS = 'hasAcceptedConditions',
  APPRAISALID = 'appraisalId',
}

export const assetInfoByExternSourceForm: FormModel[] = [
  {
    name: AssetExternSourceFormProps.OPERATION,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.VALORATIONADOPTEDTOTALSURFACE,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.USE,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.TYPOLOGY,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.SUBTYPOLOGY,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.DISTANCE,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.AGEUNTIL,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.AGESINCE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.ROOMNUMBERFROM,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.ROOMNUMBERTO,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.BATHNUMBERFROM,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.BATHNUMBERTO,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.HASPOOL,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.HASLIFT,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.HASPARKINGSPACE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.HASSTORAGEROOM,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.HASGREENAREA,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.HASTERRACE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.BUILTTYPE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.LATITUDE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.LONGITUDE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.ISINTOPFLOOR,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.ISINMIDDLEFLOOR,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.ISINGROUNDFLOOR,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.HASACCEPTEDCONDITIONS,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: AssetExternSourceFormProps.APPRAISALID,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
];
