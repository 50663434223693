import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TableCellType, TableConfig } from '../domain/table.model';
import { PrimengDatasource } from '@foxeet/utils/classes';

@Component({
  selector: 'core-primeng-tree-table',
  templateUrl: 'primeng-tree-table.component.html',
  styleUrls: ['primeng-tree-table.component.scss'],
})
export class PrimengTreeTableComponent {
  faCheck = faCheck;
  faTimes = faTimes;

  public tableCellType = TableCellType;
  public selectedNodes: any[];
  @Input() selectionMode: 'single' | 'multiple' | 'checkbox' | null = null;
  @Input() tableConfig: TableConfig;
  @Input() dataSource: PrimengDatasource<any>;
  // Property to identify rows (like an ID)
  @Input() dataKey: string;
  @Input() canCollapse = true;
  @Output() selectedNodesChange = new EventEmitter<any[]>();

  nodeSelect(node) {
    this.selectedNodesChange.emit(this.selectedNodes);
  }
  nodeUnselect(node) {
    this.selectedNodesChange.emit(this.selectedNodes);
  }
  selectAllNodes(node) {
    this.selectedNodesChange.emit(this.selectedNodes);
  }
}
