export { IGoogleAnalyticsRoutingSettings } from './lib/interfaces/i-google-analytics-routing-settings';

export { GtagFn } from './lib/types/gtag.type';

export { GoogleAnalyticsService } from './lib/services/google-analytics.service';

export { NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN } from './lib/tokens/ngx-google-analytics-settings-token';

export * from './lib/directives/ga-event-category.directive';
export * from './lib/directives/ga-event-form-input.directive';
export * from './lib/directives/ga-event.directive';
export * from './lib/ngx-google-analytics-router/ngx-google-analytics-router.module';
export * from './lib/ngx-google-analytics.module';
export * from './lib/interfaces/i-google-analytics-settings';
export { GoogleAnalyticsInitializer } from './lib/initializers/google-analytics.initializer';
export { GoogleAnalyticsRouterInitializer } from './lib/initializers/google-analytics-router.initializer';
export { NGX_GTAG_FN, getGtagFn } from './lib/tokens/ngx-gtag-token';
export { CustomWindow } from './lib/types/custom-window.type';
