import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseFormMobileComponent } from '../../../base-components';

@Component({
  selector: 'ui-mobile-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrls: ['./form-toggle.component.scss'],
})
export class FormToggleComponent extends BaseFormMobileComponent implements OnInit {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    super.ngOnInit();
    this.control.valueChanges.pipe(takeUntil(this._componentDestroyed)).subscribe((el) => {
      this.valueChange.next(el);
    });
  }
}
