import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  FormInputsModule,
  StarsRatingModule,
  WebAsyncButtonModule,
  WebBasicContainerModule,
  WebDetailMenuImageModule,
  WebDetailMenuSectionsModule,
  WebDownloadFileButtonModule,
  WebDynamicFormModule,
  WebExpandableFiltersModule,
  WebExpertOnlyContainerModule,
} from '@foxeet/ui';
import { DirectivesModule, I18nModule, PipesModule } from '@foxeet/utils-modules';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { MaterialModule } from '../material';
import {
  ActionsComponent,
  BadgeTextComponent,
  FilteredTableComponent,
  GenericTableComponent,
  ImageContainerComponent,
  PrimengTreeTableComponent,
  StatusBadgesComponent,
  SimpleTableComponent,
  TitleAndSubtitleComponent,
  StartsRaitingCellComponent,
} from './generic-tables';

const UI_COMPONENTS = [
  FormInputsModule,
  WebExpertOnlyContainerModule,
  WebBasicContainerModule,
  WebAsyncButtonModule,
  WebDynamicFormModule,
  WebDetailMenuImageModule,
  WebDetailMenuSectionsModule,
  WebExpandableFiltersModule,
  WebDownloadFileButtonModule,
];
const UTILS_MODULES = [DirectivesModule, I18nModule.forChild(), PipesModule];

const components = [
  GenericTableComponent,
  SimpleTableComponent,
  FilteredTableComponent,
  StatusBadgesComponent,
  BadgeTextComponent,
  TitleAndSubtitleComponent,
  ActionsComponent,
  ImageContainerComponent,
  PrimengTreeTableComponent,
  StartsRaitingCellComponent,
];

const primeNgModules = [
  BreadcrumbModule,
  CalendarModule,
  CardModule,
  CheckboxModule,
  DropdownModule,
  FileUploadModule,
  InputMaskModule,
  InputSwitchModule,
  TooltipModule,
  InputTextModule,
  InputTextareaModule,
  MultiSelectModule,
  PaginatorModule,
  SelectButtonModule,
  SliderModule,
  TableModule,
  TreeModule,
  InputNumberModule,
  ChartModule,
  PanelModule,
  PasswordModule,
  OverlayPanelModule,
  TreeTableModule,
  StarsRatingModule,
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, UTILS_MODULES, FontAwesomeModule, BreadcrumbModule, ...primeNgModules, MaterialModule, UI_COMPONENTS],
  exports: [...components, ...primeNgModules, UI_COMPONENTS, UTILS_MODULES],
  declarations: [...components],
})
export class ComponentsModule {}
