import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountCanLoad, ChangeTemporalPasswordGuardService } from '@foxeet/feature/auth';
import { ChangeTemporalPasswordComponent, LoginComponent } from '@foxeet/mcore';
import { M_PATHS as PATHS } from '@foxeet/n-core';

const routes: Routes = [
  {
    path: `${PATHS.LOGIN}`,
    component: LoginComponent,
  },
  {
    path: `${PATHS.LOGIN}/${PATHS.RESTORE}`,
    component: LoginComponent,
  },
  {
    path: `${PATHS.CHANGE_TEMPORAL_PASSWORD}`,
    component: ChangeTemporalPasswordComponent,
    canActivate: [ChangeTemporalPasswordGuardService],
  },
  {
    path: '',
    loadChildren: () => import('./modules/modules.module').then((m) => m.ModulesPageModule),
    canLoad: [AccountCanLoad],
    data: { platform: 'ivforce' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
