import { Component, EventEmitter, Output } from '@angular/core';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-checkbox',
  templateUrl: './form-input-checkbox.component.html',
  styleUrls: ['./form-input-checkbox.component.scss'],
})
export class FormInputCheckboxComponent extends BaseFormComponent<boolean> {
  @Output() changeEvent = new EventEmitter();

  onChangeEvent(event: Record<string, unknown>): void {
    this.changeEvent.emit(event);
  }
}
