import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { FormInputPassWordComponent } from './form-input-password.component';
import { FormValidationMessagesModule } from '../../form-validation-messages/web/form-validation-messages.module';

@NgModule({
  declarations: [FormInputPassWordComponent],
  imports: [CommonModule, PasswordModule, ReactiveFormsModule, FormValidationMessagesModule],
  exports: [FormInputPassWordComponent],
})
export class FormInputPasswordModule {}
