<div class="wrapper">
  <ion-item
    [ngClass]="{
      error: showValidationMessages,
      'non-opacity': readonly
    }"
  >
    <ion-input
      label="{{ label ? (label | capitalize) : '' }}"
      labelPlacement="floating"
      [id]="componentId"
      [type]="type"
      [formControl]="control"
      [placeholder]="placeholder ?? undefined"
    ></ion-input>
  </ion-item>
  <ion-button slot="end" class="button-icon-clear-rounded" (click)="togglePassword()">
    <ion-icon [name]="icon"></ion-icon>
  </ion-button>
</div>

<ui-mobile-form-validation-messages *ngIf="showValidationMessages" [control]="control"></ui-mobile-form-validation-messages>
