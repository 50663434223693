import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserEditPasswordModel } from '@foxeet/domain';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { PASSWORD } from '@foxeet/utils/forms';
import { checkIfFieldsAreEqual, customRegexValidator } from '@foxeet/utils/validators';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { ASYNC_BUTTON_STATE, DebugModalComponent } from '@foxeet/ui';
import { UsersService } from '@foxeet/data-access';

@Component({
  selector: 'feature-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent extends UnsuscriptionHandler implements OnInit {
  public formGroup!: FormGroup;

  public hide = true;
  public userId: number;

  public stateButton: BehaviorSubject<ASYNC_BUTTON_STATE> = new BehaviorSubject<ASYNC_BUTTON_STATE>(ASYNC_BUTTON_STATE.IDLE);

  constructor(
    public dialogRef: MatDialogRef<DebugModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toastrWrapperService: ToastrWrapperService,
    private _usersService: UsersService,
  ) {
    super();
    this.userId = data.userId;
  }

  ngOnInit() {
    this.formGroup = new FormGroup(
      {
        password: new FormControl(null, [Validators.required, customRegexValidator(PASSWORD.pattern, PASSWORD.errorKey)]),
        confirmPassword: new FormControl(null, Validators.required),
      },
      checkIfFieldsAreEqual('password', 'confirmPassword'),
    );
  }

  closeEventTrigger() {
    this.dialogRef.close();
  }

  confirmEventTrigger() {
    const body: UserEditPasswordModel = { ...this.formGroup.getRawValue(), userId: this.userId };
    this.stateButton.next(ASYNC_BUTTON_STATE.SENDING);
    this._usersService
      .sendNewPassword(body)
      .pipe(
        takeUntil(this._componentDestroyed),
        finalize(() => this.stateButton.next(ASYNC_BUTTON_STATE.IDLE)),
      )
      .subscribe(() => {
        this._toastrWrapperService.success();
        this.dialogRef.close();
      }, this._toastrWrapperService.errorHandler);
  }
}
