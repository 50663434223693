import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastrWrapperService {
  private _config: Partial<IndividualConfig> = {
    timeOut: 3000,
    closeButton: true,
    enableHtml: true,
    extendedTimeOut: 0,
    tapToDismiss: false,
  };

  constructor(private _toaster: ToastrService, private _translateService: TranslateService) {}

  success(message = this._translateService.instant('the_action_was_successful'), title = this._translateService.instant('successful'), config = this._config): ActiveToast<any> {
    return this._toaster.success(this._translateService.instant(message), this._translateService.instant(title), config);
  }

  error(error = this._translateService.instant('error'), title = this._translateService.instant('error'), config = this._config): ActiveToast<any> {
    return this._toaster.error(this._translateService.instant(error), this._translateService.instant(title), config);
  }

  info(message = this._translateService.instant('the_action_was_successful'), title = this._translateService.instant('successful'), config = this._config): ActiveToast<any> {
    return this._toaster.info(this._translateService.instant(message), this._translateService.instant(title), config);
  }

  successHandler = () => {
    this.success();
  };

  errorHandler = ({ error }: { error: { title: string; detail: string; errors: any } }, callback?: (detail: string, title: string) => void) => {
    if (error instanceof Blob) {
      this.parseErrorBlob(error, callback);
    } else {
      if (error?.detail) {
        this.error(error.detail, error.title);
      } else if (error?.errors) {
        this.parseErrorsObject(error.errors);
      } else if (error?.title) {
        this.error(error.title);
      } else {
        this.error('Error is not correct. No present message.');
      }
    }
  };

  /**
   * Angular transforms error 500 response into blob when the responseType configuration is setted to 'Blob'.
   * This error should be interpreted has file.
   */
  parseErrorBlob(blobError: Blob, callback?: (detail: string, title: string) => void): void {
    const reader: FileReader = new FileReader();

    reader.onloadend = (e) => {
      const errorObject = JSON.parse(reader.result as any);
      this.error(errorObject.detail, errorObject.title);

      if (callback) {
        callback(errorObject.detail, errorObject.title);
      }
    };

    reader.readAsText(blobError);
  }

  /**
   * NET CORE validation type errors
   */
  parseErrorsObject(errors: any) {
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key])) {
        (errors[key] as string[]).forEach((e) => this.error(e));
      } else {
        this.error(errors[key]);
      }
    });
  }
}
