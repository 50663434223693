import { Component, SecurityContext } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BackUrlService } from '@foxeet/data-access';
import { DynamicInfoModalComponent, InfoAlignment, InfoTypes } from '@foxeet/ui';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mforce-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends UnsuscriptionHandler {
  private _appVersionAlertShowed = false;

  constructor(
    private platform: Platform,
    private _translateService: TranslateService,
    private _popoverController: PopoverController,
    private _domSanitizer: DomSanitizer,
    private _backUrlService: BackUrlService,
    private _platform: Platform,
  ) {
    super();
    this.initializeApp();
    this._checkIfShowAppVersionAlert();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('android') && !this.platform.platforms().includes('mobileweb')) StatusBar.setOverlaysWebView({ overlay: false });
      SplashScreen.hide();
    });
  }

  private async _showAppVersionAlert() {
    const isDevice = this._platform.platforms().includes('android') || this._platform.platforms().includes('ios');
    const popover = await this._popoverController.create({
      component: DynamicInfoModalComponent,
      backdropDismiss: false,
      componentProps: {
        title: this._translateService.instant('web_reload_app_title'),
        message: this._domSanitizer.sanitize(
          SecurityContext.HTML,
          this._translateService.instant(
            isDevice ? (this._platform.platforms().includes('android') ? 'mobile_android_reload_app_message' : 'mobile_ios_reload_app_message') : 'mobile_web_reload_app_message',
          ),
        ),
        infoType: InfoTypes.innerHTML,
        alignment: InfoAlignment.center,
      },
    });

    popover.present();
  }

  private _checkIfShowAppVersionAlert() {
    this._backUrlService.showAppVersionAlert$.pipe(takeUntil(this._componentDestroyed)).subscribe((showAlert) => {
      if (showAlert && !this._appVersionAlertShowed) {
        this._appVersionAlertShowed = true;
        this._showAppVersionAlert();
      }
    });
  }
}
