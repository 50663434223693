import { Validators } from '@angular/forms';
import { FormModel } from '../form.model';

export const assetByCadastralReference: FormModel[] = [
  {
    name: 'CadastralReference',
    validators: [Validators.required, Validators.maxLength(20)],
    asyncValidatorFn: [],
  },
];

export const assetByLocationConfig: FormModel[] = [
  {
    name: 'ineCodProv',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'ineCodLocality',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'ineCodMun',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'ineCodVia',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'number',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'polygon',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'plotCode',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'latitude',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'longitude',
    validators: [],
    asyncValidatorFn: [],
  },
];

export const LOCATION_FORM_MODEL_DEFINITION: FormModel[] = [
  { name: 'portal', validators: [], asyncValidatorFn: [] },
  {
    name: 'postalCode',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'streetNumber',
    validators: [Validators.required, Validators.maxLength(8)],
    asyncValidatorFn: [],
  },
  {
    name: 'province',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'ineCodLocality',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'ineCodProv',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'ineCodMun',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'dgcCodVia',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'ineCodVia',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'locality',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'streetName',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'block',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'stairs',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'paper',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'floorType',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'gate',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'streetNotExists',
    validators: [],
    asyncValidatorFn: [],
    defaultValue: false,
  },
  {
    name: 'use',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'typology',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'surface',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'constructionYear',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'cadastralReference',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'registeredPropertyNumber', // finca
    validators: [Validators.maxLength(32)],
    asyncValidatorFn: [],
  },
  {
    name: 'idufir',
    validators: [Validators.minLength(14), Validators.maxLength(14)],
    asyncValidatorFn: [],
  },
  {
    name: 'volume',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'book',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'inscription',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'countryThreeLetterISORegionName',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'propertyRegisterGeoSmartaId',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'polygonCode',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'plotCode',
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: 'placeName',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'regionName',
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: 'streetType',
    validators: [],
    asyncValidatorFn: [],
  },
  { name: 'latitude', validators: [], asyncValidatorFn: [] },
  { name: 'longitude', validators: [], asyncValidatorFn: [] },
  { name: 'totalUsefulSurface', validators: [], asyncValidatorFn: [] },
];
