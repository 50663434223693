import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ASYNC_BUTTON_STATE } from '../../../async-button';
import { SeveralConfirmButtonsModalComponentI, ButtonI } from '../../domain/several-options-modal.types';

@Component({
  selector: 'ui-several-confirm-buttons-modal',
  templateUrl: './several-confirm-buttons-modal.component.html',
})
export class SeveralConfirmButtonsModalComponent implements OnInit {
  public title?: string;
  public message?: string;
  public buttonList: ButtonI[] = [];

  constructor(
    @Optional() public dialogRef: MatDialogRef<SeveralConfirmButtonsModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SeveralConfirmButtonsModalComponentI,
    private _dialogRef: MatDialogRef<SeveralConfirmButtonsModalComponent>,
  ) {
    this.dialogRef.disableClose = !!data.disableClose;
  }

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;

    // init button state
    this.data.buttonList.forEach((button) => {
      const state = new BehaviorSubject<ASYNC_BUTTON_STATE>(ASYNC_BUTTON_STATE.IDLE);
      this.buttonList.push({ ...button, state });
    });
  }

  public selectedOption(button: ButtonI): void {
    if (button.showState) {
      button.state?.next(ASYNC_BUTTON_STATE.SENDING);
      if (button.callback) button.callback(this.data.extraData, button.state);
    } else {
      if (button.callback) button.callback(this.data.extraData);
    }
  }

  public closeDialog(res?: any): void {
    this.dialogRef.close(res);
  }
}
