import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AssetSurfaceSelectorModel,
  AssetTypologies,
  AssetUses,
  EnumsModel,
  FoxeetKeyValueModel,
  HomogeneousCoefficientConcept,
  InsuranceValorationTypes,
  OrNull,
  ValorationCahsflowCacheDto,
  ValorationChecksModel,
  ValorationComparableCalculationModel,
  ValorationComparisonMethodModel,
  ValorationContractCashflowMethodModel,
  ValorationCostMethodModel,
  ValorationCostMethodSumModel,
  ValorationData,
  ValorationDiscountCashflowMethodModel,
  ValorationDynamicResidualMethodModel,
  ValorationEconomicExploitationMethodModel,
  ValorationLegalMaximumMethodModel,
  ValorationLegalMaximumMethodParentAssetData,
  ValorationMethods,
  ValorationModalModel,
  ValorationOtherMethodModel,
  ValorationRusticEstateMethodModel,
  ValorationStaticResidualMethodModel,
  ValuationInitialValuesModel,
  WorkflowItemReportAssetCheckIfReplaceCompModel,
} from '@foxeet/domain';
import { CacheService } from '../services/cache.service';
import { isNil, ObjectTS } from '../to-delete/bucket';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class AppraisalAssetValuationService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalReportAssetValorations', cache);
  }

  getAvailableUses(): Observable<EnumsModel[]> {
    return this.get<EnumsModel[]>('ResidualMethods/BuilgindElements/AvailableUses');
  }

  getAvailableSurfaces(): Observable<EnumsModel[]> {
    return this.get<EnumsModel[]>('ResidualMethods/BuilgindElements/AvailableSurfaces');
  }

  public setInsuranceValorationType(assetId: OrNull<number> = null, clusterId: OrNull<number> = null, insuranceValorationType: InsuranceValorationTypes) {
    const body: { assetId?: number; clusterId?: number; insuranceValorationType: InsuranceValorationTypes } = { insuranceValorationType };
    if (assetId) body['assetId'] = assetId;
    if (clusterId) body['clusterId'] = clusterId;
    return this.put<typeof body, ValorationData>('InsuranceValorationSelector', body);
  }

  public getValorationData(assetId: OrNull<number>, clusterId: OrNull<number> = null): Observable<ValorationData> {
    return this.get<ValorationData>('ValorationData', { params: this.setParams({ assetId, clusterId }) });
  }

  // TODO: move to a new file
  public valorationCostMethodCalculate(data: ValorationCostMethodModel): Observable<ValorationCostMethodModel> {
    return this.post<ValorationCostMethodModel, ValorationCostMethodModel>('ValorationCostMethodCalculate', data);
  }

  public valorationCostMethodCreate(data: ValorationCostMethodModel): Observable<ValorationCostMethodModel> {
    return this.post<ValorationCostMethodModel, ValorationCostMethodModel>('ValorationCostMethodCreate', data);
  }

  public valorationCostMethodEdit(data: ValorationCostMethodModel): Observable<ValorationCostMethodModel> {
    return this.post<ValorationCostMethodModel, ValorationCostMethodModel>('ValorationCostMethodEdit', data);
  }

  public getCostMethodData(isFutureValoration: boolean, assetId: number, clusterId: OrNull<number> = null): Observable<ValorationCostMethodModel> {
    return this.get<ValorationCostMethodModel>('CostMethod', {
      params: this.setParams({
        assetId,
        clusterId,
        isFutureValoration,
      }),
    });
  }

  public valorationCostMethodGetRestSum(assetId: number, valorationId: number, isFutureValoration: boolean): Observable<ValorationCostMethodSumModel> {
    return this.get<ValorationCostMethodSumModel>('ValorationCostMethodGetRestSum', {
      params: this.setParams({
        assetId,
        valorationId,
        isFutureValoration,
      }),
    });
  }

  public staticAbriddedResidualDefaultValues(assetId: number, clusterId: number, buildingElementId: OrNull<number> = null): Observable<ValorationCostMethodSumModel> {
    return this.get<ValorationCostMethodSumModel>('StaticAbriddedResidualDefaultValues', {
      params: this.setParams({
        assetId,
        clusterId,
        buildingElementId,
      }),
    });
  }

  // TODO: move to a new file
  public valorationComparableMethodCalculate(data: ValorationComparisonMethodModel): Observable<ValorationComparisonMethodModel> {
    return this.post<ValorationComparisonMethodModel, ValorationComparisonMethodModel>('ValorationComparisonMethodCalculate', data);
  }

  public createValorationComparableMethod(data: ValorationComparisonMethodModel): Observable<ValorationComparisonMethodModel> {
    return this.post<ValorationComparisonMethodModel, ValorationComparisonMethodModel>('ValorationComparisonMethodCreate', data);
  }

  public editValorationComparableMethod(data: ValorationComparisonMethodModel): Observable<ValorationComparisonMethodModel> {
    return this.post<ValorationComparisonMethodModel, ValorationComparisonMethodModel>('ValorationComparisonMethodEdit', data);
  }

  public getDepreciationFunctionalAdoptedTotalValue(
    isFutureValoration: boolean,
    assetId: OrNull<number>,
    clusterId: OrNull<number>,
    buildingElementId: number,
  ): Observable<string | number> {
    return this.get('DepreciationFunctionalAdoptedTotalValue', {
      params: this.setParams({
        assetId,
        clusterId,
        buildingElementId,
        isFutureValoration,
      }),
    });
  }

  // public getAutomaticValorationComparables(assetId: number, clusterId: OrNull<number> = null) {
  //   return this.get<>(`AutomaticValorationComparisonMethod?assetId=${assetId}&clusterId=${clusterId}`);
  // }

  // TODO: move to a new file
  public valorationDiscountCashflowMethodCalculate(data: ValorationDiscountCashflowMethodModel): Observable<ValorationDiscountCashflowMethodModel> {
    return this.post<ValorationDiscountCashflowMethodModel, ValorationDiscountCashflowMethodModel>('ValorationDiscountCashflowMethodCalculate', data);
  }

  // Falta revisar
  public createValorationDiscountCashflowMethod(data: ValorationDiscountCashflowMethodModel) {
    return this.post<typeof data, ValorationDiscountCashflowMethodModel>('ValorationDiscountCashflowMethodCreate', data);
  }

  public editValorationDiscountCashflowMethod(data: ValorationDiscountCashflowMethodModel) {
    return this.post<typeof data, ValorationDiscountCashflowMethodModel>('ValorationDiscountCashflowMethodEdit', data);
  }

  // TODO: move to a new file
  public valorationOtherMethodCalculate(data: ValorationOtherMethodModel): Observable<ValorationOtherMethodModel> {
    return this.post<ValorationOtherMethodModel, ValorationOtherMethodModel>('ValorationOtherMethodCalculate', data);
  }

  // TODO: move to a new file
  public valorationOtherMethodCreate(data: ValorationOtherMethodModel, file: Blob) {
    const body = new FormData();
    ObjectTS.keys(data).forEach((key) => {
      if (!isNil(data[key])) {
        body.append(key, `${data[key]}`);
      }
    });
    body.append('File', file);
    return this.postMultipartFile<ValorationOtherMethodModel>('ValorationOtherMethodCreate', body);
  }

  public editValorationOthersMethod(data: ValorationOtherMethodModel, file: Blob) {
    const body = new FormData();
    ObjectTS.keys(data).forEach((key) => {
      if (!isNil(data[key])) {
        body.append(key, `${data[key]}`);
      }
    });
    body.append('File', file);
    return this.postMultipartFile<ValorationOtherMethodModel>('ValorationOtherMethodEdit', body);
  }

  public getGroundRepercussionUnitValue(
    isFutureValoration: boolean,
    assetId: number,
    clusterId: OrNull<number> = null,
    buildingElementId: OrNull<number> = null,
  ): Observable<number> {
    return this.get('GroundRepercussionUnitValue', {
      params: this.setParams({
        assetId,
        clusterId,
        buildingElementId,
        isFutureValoration,
      }),
    });
  }

  public getSurfaceSelectorData(
    valorationMethod: ValorationMethods,
    assetId: number,
    clusterId: OrNull<number> = null,
    buildingElementId: OrNull<number> = null,
  ): Observable<AssetSurfaceSelectorModel> {
    return this.get<AssetSurfaceSelectorModel>('SurfaceSelector', {
      params: this.setParams({
        valorationMethod: `${valorationMethod}`,
        assetId,
        clusterId,
        buildingElementId,
      }),
    });
  }

  getValorationContractCashflowMethodGetRelatedValorationValues(contractCashflowData: ValorationContractCashflowMethodModel): Observable<ValorationContractCashflowMethodModel> {
    return this.post<ValorationContractCashflowMethodModel, ValorationContractCashflowMethodModel>(
      'ValorationContractCashflowMethodGetRelatedValorationValues',
      contractCashflowData,
    );
  }

  // TODO: move to a new file
  public createValorationContractCashFlowMethod(data: ValorationContractCashflowMethodModel): Observable<ValorationContractCashflowMethodModel> {
    return this.post<ValorationContractCashflowMethodModel, ValorationContractCashflowMethodModel>('ValorationContractCashflowMethodCreate', data);
  }

  public editValorationContractCashFlowMethod(data: ValorationContractCashflowMethodModel): Observable<ValorationContractCashflowMethodModel> {
    return this.post<ValorationContractCashflowMethodModel, ValorationContractCashflowMethodModel>('ValorationContractCashflowMethodEdit', data);
  }

  public valorationContractCashflowMethodCalculate(data: ValorationContractCashflowMethodModel): Observable<ValorationContractCashflowMethodModel> {
    return this.post<ValorationContractCashflowMethodModel, ValorationContractCashflowMethodModel>('ValorationContractCashflowMethodCalculate', data);
  }

  // TODO: move to a new file
  public createValorationMaximumLegalMethod(data: ValorationLegalMaximumMethodModel) {
    return this.post<typeof data, ValorationLegalMaximumMethodModel>('ValorationLegalMaximumMethodCreate', data);
  }

  public editValorationMaximumLegalMethod(data: ValorationLegalMaximumMethodModel) {
    return this.post<typeof data, ValorationLegalMaximumMethodModel>('ValorationLegalMaximumMethodEdit', data);
  }

  public valorationLegalMaximumMethodCalculate(data: ValorationLegalMaximumMethodModel) {
    return this.post<typeof data, ValorationLegalMaximumMethodModel>('ValorationLegalMaximumMethodCalculate', data);
  }

  public getLegalMaximumData(isFutureValoration: boolean, assetId: number, clusterId: OrNull<number> = null) {
    return this.get<ValorationLegalMaximumMethodModel>('LegalMaximumMethod', {
      params: {
        assetId,
        clusterId: `${clusterId}`,
        isFutureValoration,
      },
    });
  }

  public getParentsValorationLegalMaximumMethod(assetId: number): Observable<ValorationLegalMaximumMethodParentAssetData> {
    return this.get<ValorationLegalMaximumMethodParentAssetData>('ParentsValorationLegalMaximumMethod', { params: this.setParams({ assetId }) });
  }

  public valorationDynamicResidualMethodFromWorkPlan(assetId: number, clusterId: number) {
    return this.get<ValorationDynamicResidualMethodModel>('ValorationDynamicResidualMethodFromWorkPlan', {
      params: this.setParams({
        assetId,
        clusterId,
      }),
    });
  }

  public valorationDynamicResidualAddCashflow(body: ValorationCahsflowCacheDto) {
    return this.post<ValorationCahsflowCacheDto, ValorationCahsflowCacheDto>('AddCashflow', body);
  }

  public valorationDynamicResidualMethodCalculate(body: ValorationDynamicResidualMethodModel) {
    return this.post<ValorationDynamicResidualMethodModel, ValorationDynamicResidualMethodModel>('ValorationDynamicResidualMethodCalculate', body);
  }

  public valorationStaticResidualMethodCalculate(body: ValorationStaticResidualMethodModel) {
    return this.post<ValorationStaticResidualMethodModel, ValorationStaticResidualMethodModel>('ValorationStaticResidualMethodCalculate', body);
  }

  public valorationDynamicResidualMethodEdit(model: ValorationDynamicResidualMethodModel, otherMethodFiles: Map<string, Blob>) {
    return this.valorationResidualMethods<ValorationDynamicResidualMethodModel>('Dynamic', 'Edit', model, otherMethodFiles);
  }

  public valorationDynamicResidualMethodCreate(model: ValorationDynamicResidualMethodModel, otherMethodFiles: Map<string, Blob>) {
    return this.valorationResidualMethods<ValorationDynamicResidualMethodModel>('Dynamic', 'Create', model, otherMethodFiles);
  }

  public valorationStaticResidualMethodEdit(model: ValorationStaticResidualMethodModel, otherMethodFiles: Map<string, Blob>) {
    return this.valorationResidualMethods<ValorationStaticResidualMethodModel>('Static', 'Edit', model, otherMethodFiles);
  }

  public valorationStaticResidualMethodCreate(model: ValorationStaticResidualMethodModel, otherMethodFiles: Map<string, Blob>): Observable<ValorationStaticResidualMethodModel> {
    return this.valorationResidualMethods<ValorationStaticResidualMethodModel>('Static', 'Create', model, otherMethodFiles);
  }

  private valorationResidualMethods<T>(
    residual: 'Static' | 'Dynamic',
    method: 'Create' | 'Edit',
    model: ValorationStaticResidualMethodModel | ValorationDynamicResidualMethodModel,
    otherMethodFiles: Map<string, Blob>,
  ) {
    const body = new FormData();
    switch (residual) {
      case 'Dynamic':
        body.append(`ValorationDynamicResidualMethodModelJson`, JSON.stringify(model));
        break;
      case 'Static':
        body.append(`ValorationStaticResidualMethodModelJson`, JSON.stringify(model));
        break;
      default:
        throw new Error('Type residual not found');
    }
    this.valorationResidualFiles(body, otherMethodFiles);
    const url = `Valoration${residual}ResidualMethod${method}`;
    return this.postMultipartFile<T>(url, body);
  }

  private valorationResidualFiles(body: FormData, otherMethodFiles: Map<string, Blob>): FormData {
    let i = 0;
    otherMethodFiles.forEach((value: Blob, key: string, map) => {
      body.append(`FileModels[${i}].BuildingElementTempId`, `${key}`);
      body.append(`FileModels[${i}].File`, value);
      i++;
    });
    return body;
  }

  public checkValorationsRecalculation(
    assetId: number,
    isFutureValoration: boolean,
    clusterId: OrNull<number> = null,
    buildingElementId: OrNull<number> = null,
  ): Observable<ValorationChecksModel> {
    return this.get<ValorationChecksModel>('CheckValorationsRecalculation', {
      params: this.setParams({
        assetId,
        clusterId,
        isFutureValoration,
        buildingElementId,
      }),
    });
  }

  public checkIfReplaceComparables(workflowItemAssetId: number, clusterId: OrNull<number> = null): Observable<WorkflowItemReportAssetCheckIfReplaceCompModel> {
    return this.get<WorkflowItemReportAssetCheckIfReplaceCompModel>('CheckIfReplaceComparables', {
      params: this.setParams({
        workflowItemAssetId,
        clusterId,
      }),
    });
  }

  public recalculateValorationMethods(assetId: number, isFutureValoration: boolean, clusterId: OrNull<number> = null): Observable<void> {
    return this.get<void>('RecalculateValorationMethods', {
      params: this.setParams({
        assetId,
        clusterId,
        isFutureValoration,
      }),
    });
  }

  public getWorkPlanAssignedValue(assetId: number, isFutureValoration: boolean): Observable<number> {
    return this.get<number>('WorkPlanAssignedValue', {
      params: this.setParams({
        assetId,
        isFutureValoration,
      }),
    });
  }

  public getWorkPlanInitialDetails(data: ValorationCostMethodModel): Observable<ValorationCostMethodModel> {
    return this.post<ValorationCostMethodModel, ValorationCostMethodModel>('UnfinishedInitialValues', data);
  }

  public getListOfConcepts(use: AssetUses, typology: AssetTypologies) {
    return this.get<HomogeneousCoefficientConcept[]>('GetListOfConcepts', {
      params: this.setParams({
        use,
        typology,
      }),
    });
  }

  public calculateHomogeneousCoefficient(valorationComparableCalculation: ValorationComparableCalculationModel) {
    return this.post<ValorationComparableCalculationModel, ValorationComparableCalculationModel>('CalculateHomogeneousCoefficient', valorationComparableCalculation);
  }

  // ECONOMIC EXPLOITATION
  public createValorationEconomicExploitationMethod(body: ValorationEconomicExploitationMethodModel) {
    return this.post<ValorationEconomicExploitationMethodModel, ValorationEconomicExploitationMethodModel>('ValorationEconomicExploitationMethodCreate', body);
  }

  public editValorationEconomicExploitationMethod(body: ValorationEconomicExploitationMethodModel) {
    return this.post<ValorationEconomicExploitationMethodModel, ValorationEconomicExploitationMethodModel>('ValorationEconomicExploitationMethodEdit', body);
  }

  public valorationEconomicExploitationMethodCalculate(body: ValorationEconomicExploitationMethodModel): Observable<ValorationEconomicExploitationMethodModel> {
    return this.post<ValorationEconomicExploitationMethodModel, ValorationEconomicExploitationMethodModel>('ValorationEconomicExploitationMethodCalculate', body);
  }

  // RUSTIC ESTATE EXPLOITATION
  public createValorationRusticEstateExploitationMethod(body: ValorationRusticEstateMethodModel) {
    return this.post<ValorationRusticEstateMethodModel, ValorationRusticEstateMethodModel>('ValorationRusticEstateMethodCreate', body);
  }

  public editValorationRusticEstateExploitationMethod(body: ValorationRusticEstateMethodModel) {
    return this.post<ValorationRusticEstateMethodModel, ValorationRusticEstateMethodModel>('ValorationRusticEstateMethodEdit', body);
  }

  public valorationRusticEstateExploitationMethodCalculate(body: ValorationRusticEstateMethodModel): Observable<ValorationRusticEstateMethodModel> {
    return this.post<ValorationRusticEstateMethodModel, ValorationRusticEstateMethodModel>('ValorationRusticEstateMethodCalculate', body);
  }

  public checkCanCreateValorationMethod(
    valorationMethod: ValorationMethods,
    isFutureValoration: boolean,
    assetId: number,
    clusterId: OrNull<number> = null,
  ): Observable<ValorationModalModel> {
    return this.get<ValorationModalModel>('CheckCanCreateValorationMethod', {
      params: this.setParams({
        valorationMethod,
        isFutureValoration,
        assetId,
        clusterId,
      }),
    });
  }

  public getValuationMethodInitialValues<T>(valorationMethod: ValorationMethods, isFutureValoration: boolean, assetId: number, clusterId: OrNull<number> = null): Observable<T> {
    return this.get<T>('InitialValues', {
      params: this.setParams({
        valorationMethod,
        isFutureValoration,
        assetId,
        clusterId,
      }),
    });
  }

  public getValuationMethodInitialValuesREFACTOR<T>(valorationMethod: ValorationMethods, isFutureValoration: boolean, assetId: OrNull<number>, clusterId: OrNull<number> = null) {
    return this.get<ValuationInitialValuesModel<T>>('InitialMethodValues', {
      params: this.setParams({
        valorationMethod,
        isFutureValoration,
        assetId,
        clusterId,
      }),
    });
  }

  public keyvaluesList(id: number): Observable<FoxeetKeyValueModel[]> {
    return this.post(`${id}/keyValues/ListAll`);
  }

  public getConstructionCostsPDF(): Observable<string> {
    return this.get('ConstructionCostsPDF', { observe: 'response', responseType: 'text' });
  }
}
